import React from 'react';


const NotificationsStackItem = ({ text, title, footer, mentionReplace, icon, avatarPrefix, id, clickClose, clickOpen }) => {

  return (
    <div className={`notifications-stack-item-wrapper ${id === 'overkill' ? 'notifications-stack-item--overkill' : ''}`}>
      <div className="notifications-stack-item" onClick={(event) => clickOpen(event, id)}>
        {id !== 'overkill' ? <div className="notifications-stack-item__close-icon" onClick={(event) => clickClose(event, id)} >
          <i className="fas fa-times"></i>
        </div> : null}
        <div className="notifications-stack-item__body" >
          <div className="notifications-stack-item__icon">
            {
              icon ? <img className="notifications-stack-item__icon-img" alt="icon" src={icon.includes("http") ? icon : avatarPrefix + "" + icon} /> : null
            }
          </div>
          <div className="notifications-stack-item__text">
            {text.replace(new RegExp(mentionReplace, 'g'), "<mention>$&<mention>").split("<mention>").map((item,index) => {
              if (item.replace(new RegExp(mentionReplace), "") !== item)
                return (<span key={item.replace(new RegExp(mentionReplace), "$2")+index} className="notifications-stack-item__mention" data-id={item.replace(new RegExp(mentionReplace), "$2")}>{item.replace(new RegExp(mentionReplace), "$1")}</span>)
              else
                return item
            })}
          </div>
        </div>
        <div className="notifications-stack-item__footer" title={footer}>
          {footer}
        </div>
      </div>
    </div>)
}

export default NotificationsStackItem;