import React from "react";

import "./custom-button.styles.scss";

const CustomButton = ({children, isGoogleSignIn, inverted,color, ...otherProps}) => 
(
    <button className={` ${inverted ?"inverted":''} ${isGoogleSignIn ?"google-sign-in":''} ${color}  custom-button`} {...otherProps}>
    {children}
    </button>
);

export default CustomButton;
