import React, { useEffect, useState,useRef } from "react";

import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

import { selectUserCurrentAccessCode,selectUserConfigProcessing, selectCurrentAccessCodeConfig, selectCurrentAccessCodeTeamProgress } from '../../redux/user/user.selectors.js'
import { getConfigStart ,sendForumMessageStart} from '../../redux/user/user.actions.js'
import CustomButton from '../../components/forms/custom-button/custom-button.component'
import ModalPopup from '../../components/forms/modal-popup/modal-popup.component'
import CollabNavButtons from '../../components/collab-nav-buttons/collab-nav-buttons.component'
import Chat from '../../components/chat/chat.component'

import "./forum.styles.scss";

const ForumPage = ({ config,processing, accessCodeId,team, getConfig, history,sendMessage, ...otherProps }) => {

  const localStateInit = {
    modalFeed: false,
    feedModalShow: false,
    addingTag: false,
    addingTagText: '',
    selectedTag: 'Welcome',
    addedTags:[]
  };

  const [localState, setLocalState] = useState(localStateInit);

  const {
    feedModalShow,
    modalFeed,
    selectedTag,
    addingTag,
    addingTagText,
    addedTags
  } = localState;


  useEffect(() => {
    // if (config && config.constructor === Object && Object.keys(config).length !== 0 && !config.values.length) {
    //   history.push('/select_values');
    // } else if (config && config.values) {

    // }
  }, [config]);

  

  useEffect(() => {
    if (!config)
      getConfig()
      
      if (otherProps.location.search.includes('?tag')){
        const urlParams = new URLSearchParams(otherProps.location.search);
        const ttag = urlParams.get('tag')
        setTimeout(()=>handleTagClick(ttag),500)
      }
        
  }, []);

  const prepareTags = (forum) => {
    const newAddedTags=addedTags.filter(item => !Object.keys(forum).includes(item))
    return Object.keys(forum).concat(newAddedTags)
  }

  const handleTagClick = (tag) => {
    setLocalState({ ...localState, selectedTag: tag });
  }
  const handleAddTagClick = (tag) => {
    setLocalState({ ...localState, addingTag: true });
  }
  const handleKeyDownAddTag = (e) => {
    if (e.keyCode===13){
      const newaddedTags=[...addedTags,addingTagText]
      setLocalState({ ...localState,addedTags:newaddedTags,addingTagText:"", addingTag: false, selectedTag:addingTagText});
    }
    if (e.keyCode===27){
      setLocalState({ ...localState, addingTag: false,addingTagText:"" });
    }
    
  }
  const handleChangeAddTag = (e) => {
    setLocalState({ ...localState,addingTagText:e.target.value });
  }

  
  const sendMessageHandle = (message)=>{
    // const newAddedTags=addedTags.filter(item => item !== selectedTag)
    // setLocalState({ ...localState,addedTags:newAddedTags});
    sendMessage(message,accessCodeId, selectedTag)
  }
  

  const addInput = useRef(null);

  
  useEffect(() => {
    if (addInput.current)
      addInput.current.focus()
  });

  return (
    <div className="forum-page">
      {/* {config=false} */}
      <CollabNavButtons buttons={[{ 'link': '/dashboard', 'name': (config && config.course) ? config.course.name : 'My Course', 'active': false }, { 'link': '/team', 'name': 'Team Progress', 'active': false }, { 'link': '/collaborativeway', 'name': 'Resources', 'active': false }, { 'link': '', 'name': 'Practice Forum', 'active': true }]} />
      {typeof config !== 'undefined' && config.forum ? (
        <div className="forum-page__container">
          <div className="forum-page__tags">
            {prepareTags(config.forum).map(tag => (
              <div key={tag} onClick={() => handleTagClick(tag)} className={`forum-page__tag ${selectedTag === tag ? "active" : ""}`}>
                {tag}
              </div>
            ))}
            {/* <div onClick={() => handleAddTagClick()} className={`forum-page__tag forum-page__tag--add ${addingTag ? "edit" : ""}`}>
              {addingTag ? <input value={addingTagText} onChange={handleChangeAddTag} onKeyDown={handleKeyDownAddTag} ref={addInput} className="forum-page__add-tag-input" /> : <i className="fas fa-plus"></i>}
            </div> */}
          </div>

          <div className="forum-page__chat">
            <Chat sendTextHandle={sendMessageHandle} users={team} tag={selectedTag} accessCodeId={accessCodeId} title={selectedTag} chat={config.forum[selectedTag]} />
          </div>
        </div>
      )
        :!config||(config.forum===false&&processing) ?
        <div className="forum-page__container">
          <div className="forum-page__tags-fake">
            <div className="linear-background"/>
          </div>
          <div className="forum-page__chat-fake">
            <div className="linear-background"/>
          </div>
        </div>:
        <div className="forum-page__no-access">
          You need to complete the course to gain access to the Practice Forum
        </div>
      }

    </div>
  );
};

const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    config: selectCurrentAccessCodeConfig,
    accessCodeId: selectUserCurrentAccessCode,
    team: selectCurrentAccessCodeTeamProgress,
    processing:selectUserConfigProcessing,
  });

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(getConfigStart()),
  sendMessage:(message,access_code_id,tag) =>dispatch(sendForumMessageStart(message,access_code_id,tag)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForumPage);
