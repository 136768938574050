import UserActionTypes from "./user.types";
import { changeSessionFieldInConfig, changeSessionItemByFieldNameInConfig, changeSessionItemAssessmentFieldInConfig } from '../../utils/users.utils'

const INITIAL_STATE = {
  userData: null,
  config: null,
  showHelpModal: false,
  completeModal: false,
  countries: [],
  notifications: {},
  homePageConfig: {},
  processing: false,
  configProcessing: false,
  isLogged: null,
  redirectUrl: "",
  registrationId: "",
  resetPassword: { 'request': 0, 'confirm': 0, 'validate': 0 },
  error: "",
  jwt: "",
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.GET_CONFIG_START:
      return {
        ...state,
        processing: action.payload === 'undefined' ? true : false,
        configProcessing:true,
        error: "",
      };
    case UserActionTypes.CHECK_TOKEN_AND_LOGGED_IN_START:
    case UserActionTypes.GET_USER_DATA_START:
    case UserActionTypes.SAVE_USER_DATA_START:
    case UserActionTypes.SIGNOUT_USER_START:
    case UserActionTypes.SIGNUP_USER_START:
    case UserActionTypes.LOGIN_USER_START:
    case UserActionTypes.GET_COUNTRIES_START:
    case UserActionTypes.GET_HOMEPAGE_CONFIG_START:
    case UserActionTypes.SAVE_VALUE_START:
    case UserActionTypes.SAVE_VALUE_TRACK_START:
    case UserActionTypes.ADD_ACCESS_CODE_START:
    case UserActionTypes.DELETE_ACCESS_CODE_START:
    case UserActionTypes.DELETE_MY_ACCOUNT_START:
    case UserActionTypes.AVATAR_UPLOAD_START:
    case UserActionTypes.AVATAR_DISCARD_START:
      return {
        ...state,
        processing: true,
        error: "",
      };
    case UserActionTypes.SIGNUP_USER_SUCCESS:
    case UserActionTypes.LOGIN_USER_SUCCESS:
      return {
        ...state,
        jwt: action.payload,
        processing: false,
        isLogged: true,
        error: "",
      };
    case UserActionTypes.GET_USER_DATA_SUCCESS:
    case UserActionTypes.SAVE_USER_DATA_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        processing: false,
      };
    case UserActionTypes.GET_CONFIG_SUCCESS:
      return {
        ...state,
        config: action.payload,
        processing: false,
        configProcessing:false,
      };
    case UserActionTypes.SAVE_VALUE_TRACK_SUCCESS:
    case UserActionTypes.SAVE_VALUE_SUCCESS:
    case UserActionTypes.SAVE_CURRENT_ACCESS_CODE_START:
    case UserActionTypes.SAVE_CURRENT_ACCESS_CODE_SUCCESS:
    case UserActionTypes.ADD_ACCESS_CODE_SUCCESS:
    case UserActionTypes.DELETE_ACCESS_CODE_SUCCESS:
    case UserActionTypes.DELETE_MY_ACCOUNT_SUCCESS:
    case UserActionTypes.GET_NOTIFICATIONS_START:
    case UserActionTypes.READ_NOTIFICATION_START:

      return {
        ...state,
        processing: false,
      };

    case UserActionTypes.SAVE_SESSION_ITEM_PROGRESS_START:
    case UserActionTypes.SAVE_SESSION_ITEM_PROGRESS_ERROR:
      return {
        ...state
      };
    
    case UserActionTypes.SAVE_SESSION_ITEM_PROGRESS_SUCCESS:
      const dataSaveSessionItemProgressSuccess = action.payload;
      let newSaveSessionItemProgressConfig = [...state.config]

      const findSaveSessionItemProgressAccessCode = newSaveSessionItemProgressConfig.find(
        item => item.id === parseInt(dataSaveSessionItemProgressSuccess.access_code_id, 10)
      );

      changeSessionItemByFieldNameInConfig(findSaveSessionItemProgressAccessCode,'percent', dataSaveSessionItemProgressSuccess.session_id, dataSaveSessionItemProgressSuccess.session_item_id, dataSaveSessionItemProgressSuccess.percent)

      return {
        ...state,
        processing: false,
        config: newSaveSessionItemProgressConfig,
      };

    case UserActionTypes.CHECK_TOKEN_AND_LOGGED_IN_ERROR:
    case UserActionTypes.GET_USER_DATA_ERROR:
    case UserActionTypes.SAVE_USER_DATA_ERROR:
    case UserActionTypes.SIGNOUT_USER_ERROR:
    case UserActionTypes.SIGNUP_USER_ERROR:
    case UserActionTypes.LOGIN_USER_ERROR:

      return {
        ...state,
        jwt: "",
        processing: false,
        isLogged: false,
        redirectUrl: !state.redirectUrl || state.redirectUrl === '/' ? window.location.pathname==='/index.html'?"/":window.location.pathname + window.location.search : state.redirectUrl,
        error: action.payload,
      };
    case UserActionTypes.GET_CONFIG_ERROR:
      return {
        ...state,
        processing: false,
        configProcessing:false,
        error: action.payload,
      };
    case UserActionTypes.GET_COUNTRIES_ERROR:
    case UserActionTypes.GET_HOMEPAGE_CONFIG_ERROR:
    case UserActionTypes.SAVE_VALUE_TRACK_ERROR:
    case UserActionTypes.SAVE_VALUE_ERROR:
    case UserActionTypes.SAVE_CURRENT_ACCESS_CODE_ERROR:
    case UserActionTypes.ADD_ACCESS_CODE_ERROR:
    case UserActionTypes.DELETE_ACCESS_CODE_ERROR:
    case UserActionTypes.DELETE_MY_ACCOUNT_ERROR:
    case UserActionTypes.GET_NOTIFICATIONS_ERROR:
    case UserActionTypes.READ_NOTIFICATION_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };
    case UserActionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        processing: false,
        countries: action.payload
      };

    case UserActionTypes.GET_NOTIFICATIONS_SUCCESS:
      const { timestamp, access_code, notifications } = action.payload
      const newNotifications = { ...state.notifications }
      if (!newNotifications[access_code]) {
        newNotifications[access_code] = {}
        newNotifications[access_code]['notifications'] = [...notifications]
        newNotifications[access_code]['timestamp'] = timestamp
      } else {
        newNotifications[access_code]['notifications'] = [...newNotifications[access_code]['notifications'], ...notifications]
        newNotifications[access_code]['timestamp'] = timestamp
      }
      return {
        ...state,
        processing: false,
        notifications: newNotifications
      };

    case UserActionTypes.READ_NOTIFICATION_SUCCESS:
      const { access_code_id: read_access_code_id, notification_id } = action.payload
      const newReadNotifications = { ...state.notifications }
      newReadNotifications[read_access_code_id]['notifications'] = [...newReadNotifications[read_access_code_id]['notifications'].filter(item => item.id !== notification_id)]
      return {
        ...state,
        processing: false,
        notifications: newReadNotifications
      };
    case UserActionTypes.GET_HOMEPAGE_CONFIG_SUCCESS:
      return {
        ...state,
        processing: false,
        homePageConfig: action.payload
      };

    case UserActionTypes.SIGNOUT_USER_SUCCESS:
      return {
        ...state,
        jwt: "",
        processing: false,
        isLogged: false,
        error: "",
      };

    case UserActionTypes.CHECK_TOKEN_AND_LOGGED_IN_SUCCESS:
      return {
        ...state,
        processing: false,
        isLogged: true,
      };

    case UserActionTypes.SHOW_HELP_MODAL:
      return {
        ...state,
        showHelpModal: action.payload,
      };

      case UserActionTypes.SHOW_COMPLETE_MODAL:
      return {
        ...state,
        completeModal: {...state.completeModal, show:action.payload},
      };

      case UserActionTypes.SET_COMPLETE_MODAL:
        return {
          ...state,
          completeModal: {...action.payload, show:1},
        };

      case UserActionTypes.SET_PUSH_REGISTRATION_ID:
        return {
          ...state,
          registrationId: action.payload,
        };

    case UserActionTypes.SET_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload,
      };

    case UserActionTypes.SET_CURRENT_ACCESS_CODE:
      let newUserData = { ...state.userData }
      newUserData['current_access_code'] = action.payload
      return {
        ...state,
        userData: newUserData,
      };


    case UserActionTypes.SEND_SESSION_MESSAGE_START:
    case UserActionTypes.SEND_FORUM_MESSAGE_START:
    case UserActionTypes.TOGGLE_SESSION_BOOKMARK_START:
    case UserActionTypes.GET_SESSION_CHAT_START:
    case UserActionTypes.SAVE_COMPLETED_ITEM_START:
    case UserActionTypes.SAVE_QUESTION_ANSWER_START:
    case UserActionTypes.GET_ASSESSMENT_RESULT_START:
    case UserActionTypes.SAVE_USER_CHOICE_QUESTION_ANSWER_START:
    case UserActionTypes.USER_NOTE_SAVE_START:
    case UserActionTypes.USER_NOTE_FAVORITE_START:
      return {
        ...state,
        // processing: true,
        error: "",
      };

    case UserActionTypes.SEND_FORUM_MESSAGE_SUCCESS:
      const dataForumMessage = action.payload;

      let newForumMessageConfig = [...state.config]

      const findForumMessageAccessCode = newForumMessageConfig.find(
        item => item.id === parseInt(dataForumMessage.access_code_id, 10)
      );

      findForumMessageAccessCode.forum = dataForumMessage.forum

      return {
        ...state,
        processing: false,
        config: newForumMessageConfig,
      };

    case UserActionTypes.SEND_SESSION_MESSAGE_SUCCESS:
    case UserActionTypes.GET_SESSION_CHAT_SUCCESS:
      const data = action.payload;

      let newConfig = [...state.config]

      const findAccessCode = newConfig.find(
        item => item.id === parseInt(data.access_code_id, 10)
      );

      changeSessionFieldInConfig(findAccessCode, data.session_id, 'chat', data.chat)

      return {
        ...state,
        processing: false,
        config: newConfig,
      };

    case UserActionTypes.USER_NOTE_SAVE_SUCCESS:
      const userNoteSaveData = action.payload;

      let newUserNoteSaveConfig = [...state.config]

      const findeUserNoteSaveAccessCode = newUserNoteSaveConfig.find(
        item => item.id === parseInt(userNoteSaveData.access_code_id, 10)
      );

      if (userNoteSaveData.session_id)
        changeSessionFieldInConfig(findeUserNoteSaveAccessCode, userNoteSaveData.session_id, 'note', userNoteSaveData.note)

      findeUserNoteSaveAccessCode.notes = userNoteSaveData.notes

      return {
        ...state,
        processing: false,
        config: newUserNoteSaveConfig,
      };

    case UserActionTypes.USER_NOTE_FAVORITE_SUCCESS:
      const userNoteFavoriteSaveData = action.payload;

      let newUserNoteFavoriteSaveConfig = [...state.config]

      const findUserNoteFavoriteSaveAccessCode = newUserNoteFavoriteSaveConfig.find(
        item => item.id === parseInt(userNoteFavoriteSaveData.access_code_id, 10)
      );

      findUserNoteFavoriteSaveAccessCode.notes = userNoteFavoriteSaveData.notes

      return {
        ...state,
        processing: false,
        config: newUserNoteFavoriteSaveConfig,
      };

    case UserActionTypes.SAVE_COMPLETED_ITEM_SUCCESS:
      const dataItemCompleted = action.payload;
      let newItemCompletedConfig = [...state.config]

      const findItemCompletedAccessCode = newItemCompletedConfig.find(
        item => item.id === parseInt(dataItemCompleted.access_code_id, 10)
      );
      if (dataItemCompleted.related === 'session')
        changeSessionFieldInConfig(findItemCompletedAccessCode, dataItemCompleted.item_id, 'completed', dataItemCompleted.completed)

      return {
        ...state,
        processing: false,
        config: newItemCompletedConfig,
      };

    case UserActionTypes.SAVE_QUESTION_ANSWER_SUCCESS:
      const dataSaveQuestionAnswer = action.payload;
      let newSaveQuestionAnswerConfig = [...state.config]

      const findSaveQuestionAnswerAccessCode = newSaveQuestionAnswerConfig.find(
        item => item.id === parseInt(dataSaveQuestionAnswer.access_code_id, 10)
      );

      changeSessionItemAssessmentFieldInConfig(findSaveQuestionAnswerAccessCode, dataSaveQuestionAnswer.session_id, dataSaveQuestionAnswer.session_item_id, dataSaveQuestionAnswer.assessment)

      return {
        ...state,
        processing: false,
        config: newSaveQuestionAnswerConfig,
      };
    case UserActionTypes.GET_ASSESSMENT_RESULT_SUCCESS:
      {
        const dataSaveQuestionAnswer = action.payload;
        let newSaveQuestionAnswerConfig = [...state.config]

        const findSaveQuestionAnswerAccessCode = newSaveQuestionAnswerConfig.find(
          item => item.id === parseInt(dataSaveQuestionAnswer.access_code_id, 10)
        );

        changeSessionItemAssessmentFieldInConfig(findSaveQuestionAnswerAccessCode, dataSaveQuestionAnswer.session_id, dataSaveQuestionAnswer.session_item_id, dataSaveQuestionAnswer.assessment)

        return {
          ...state,
          processing: false,
          config: newSaveQuestionAnswerConfig,
        };
      }

    case UserActionTypes.SAVE_USER_CHOICE_QUESTION_ANSWER_SUCCESS:
      const dataSaveUserChoiceQuestionAnswer = action.payload;
      let newSaveUserChoiceQuestionAnswerConfig = [...state.config]

      const findSaveUserChoiceQuestionAnswerAccessCode = newSaveUserChoiceQuestionAnswerConfig.find(
        item => item.id === parseInt(dataSaveUserChoiceQuestionAnswer.access_code_id, 10)
      );

      changeSessionItemByFieldNameInConfig(findSaveUserChoiceQuestionAnswerAccessCode, 'user_choice', dataSaveUserChoiceQuestionAnswer.session_id, dataSaveUserChoiceQuestionAnswer.session_item_id, dataSaveUserChoiceQuestionAnswer.user_choice)

      return {
        ...state,
        processing: false,
        config: newSaveUserChoiceQuestionAnswerConfig,
      };

    case UserActionTypes.TOGGLE_SESSION_BOOKMARK_SUCCESS:
      const dataToggleBookmark = action.payload;
      let newToggleBookmarkConfig = [...state.config]

      const findToggleBookmarkAccessCode = newToggleBookmarkConfig.find(
        item => item.id === parseInt(dataToggleBookmark.access_code_id, 10)
      );

      changeSessionFieldInConfig(findToggleBookmarkAccessCode, dataToggleBookmark.session_id, 'bookmarked', dataToggleBookmark.bookmarked)
      changeSessionFieldInConfig(findToggleBookmarkAccessCode, dataToggleBookmark.session_id, 'bookmarks', dataToggleBookmark.bookmarks)

      return {
        ...state,
        processing: false,
        config: newToggleBookmarkConfig,
      };

    case UserActionTypes.SEND_SESSION_MESSAGE_ERROR:
    case UserActionTypes.SEND_FORUM_MESSAGE_ERROR:
    case UserActionTypes.TOGGLE_SESSION_BOOKMARK_ERROR:
    case UserActionTypes.GET_SESSION_CHAT_ERROR:
    case UserActionTypes.SAVE_COMPLETED_ITEM_ERROR:
    case UserActionTypes.AVATAR_UPLOAD_ERROR:
    case UserActionTypes.AVATAR_DISCARD_ERROR:
    case UserActionTypes.SAVE_QUESTION_ANSWER_ERROR:
    case UserActionTypes.GET_ASSESSMENT_RESULT_ERROR:
    case UserActionTypes.SAVE_USER_CHOICE_QUESTION_ANSWER_ERROR:
    case UserActionTypes.USER_NOTE_FAVORITE_ERROR:
    case UserActionTypes.USER_NOTE_SAVE_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload,
      };

    case UserActionTypes.AVATAR_UPLOAD_SUCCESS:
    case UserActionTypes.AVATAR_DISCARD_SUCCESS:
      let newAvatarUserData = { ...state.userData }
      newAvatarUserData['avatar'] = action.payload
      return {
        ...state,
        processing: false,
        userData: newAvatarUserData,
      };

    case UserActionTypes.RESET_PASSWORD_CONFIRM_START:
    case UserActionTypes.RESET_PASSWORD_VALIDATE_START:
    case UserActionTypes.RESET_PASSWORD_REQUEST_START:
      return {
        ...state,
        resetPassword: { 'request': 0, 'confirm': 0, 'validate': 0 },
        processing: true,
        error: "",
      };

    case UserActionTypes.RESET_PASSWORD_CONFIRM_SUCCESS:
    case UserActionTypes.RESET_PASSWORD_VALIDATE_SUCCESS:
    case UserActionTypes.RESET_PASSWORD_REQUEST_SUCCESS:
      //find key
      let resetPasswordKey = 'request'
      if (action.type === UserActionTypes.RESET_PASSWORD_CONFIRM_SUCCESS)
        resetPasswordKey = 'confirm'
      if (action.type === UserActionTypes.RESET_PASSWORD_VALIDATE_SUCCESS)
        resetPasswordKey = 'validate'
      return {
        ...state,
        resetPassword: { ...state.resetPassword, [resetPasswordKey]: 1 },
        processing: false,
        error: "",
      };
    case UserActionTypes.RESET_PASSWORD_CONFIRM_ERROR:
    case UserActionTypes.RESET_PASSWORD_VALIDATE_ERROR:
    case UserActionTypes.RESET_PASSWORD_REQUEST_ERROR:
      //find key
      let resetPasswordKeyS = 'request'
      if (action.type === UserActionTypes.RESET_PASSWORD_CONFIRM_ERROR)
        resetPasswordKeyS = 'confirm'
      if (action.type === UserActionTypes.RESET_PASSWORD_VALIDATE_ERROR)
        resetPasswordKeyS = 'validate'
      return {
        ...state,
        resetPassword: { ...state.resetPassword, [resetPasswordKeyS]: -1 },
        processing: false,
        error: action.payload,
      };

    default:
      return state;
  }
};



export default userReducer;
