import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

import { selectUserCurrentAccessCode, selectCurrentAccessCodeConfig, selectUserData } from '../../redux/user/user.selectors.js'
import { getConfigStart,saveUserNoteStart,saveUserNoteFavoriteStart } from '../../redux/user/user.actions.js'
import CollabNavButtons from '../../components/collab-nav-buttons/collab-nav-buttons.component'
import SectionItemGroup from '../../components/section-item-group/section-item-group.component'
import {getSessionById} from '../../utils/users.utils'
import ToggleButton from '../../components/toggle-button/toggle-button.component'
import ModalPopup from '../../components/forms/modal-popup/modal-popup.component'
import CustomButton from "../../components/forms/custom-button/custom-button.component"

import "./notes.styles.scss";
import { findAllInRenderedTree } from "react-dom/test-utils";

const NotesPage = ({ config, accessCodeId,user,saveUserNote,saveUserNoteFavorite, getConfig, history }) => {

  const localStateInit = {
    toggleSelected: 1,
    noteModalShow: false,
    currentNote:null,
    noteText: "",
    noteTitle: ""
  };

  const [localState, setLocalState] = useState(localStateInit);

  const {
    toggleSelected,
    noteModalShow,
    currentNote,
    noteText,
    noteTitle
  } = localState;

  useEffect(() => {
    getConfig()
  }, []);

  const showHideNoteModal = (show) => {
    setLocalState({ ...localState, noteModalShow: show });
}

const saveNote = () => {
  saveUserNote(accessCodeId,currentNote?currentNote.session:0,currentNote?currentNote.id:0,noteTitle,noteText)
}

const saveNoteFavorite = (id) => {
  saveUserNoteFavorite(accessCodeId, id)
}
 
const noteTextChange = event => {
  const { value, name } = event.target;
  setLocalState({ ...localState, noteText: value });
};
const noteTitleChange = event => {
  const { value, name } = event.target;
  setLocalState({ ...localState, noteTitle: value });
};

  const handleToggleClick = (el) => {
    setLocalState({ ...localState, toggleSelected: el });
  }

  const handleNoteClick = (e,id) => {
    const findItem=config.notes.find(item=>item.id===id)
    setLocalState({ ...localState, noteModalShow: true, currentNote:findItem, noteTitle:findItem.name,noteText:findItem.text });
  }

  const handleAddNewClick = () => {
    setLocalState({ ...localState, noteModalShow: true, currentNote:null, noteTitle:"",noteText:'' });
  }

  const prepareItems = (items) => {
    let prepared_items = items.map(item=>{
      let name = item.name
      if (item.session_name)
        name=item.session_name;
        return {id:item.id, name:name,description:item.text.substring(0,40)+'...',bookmarked:item.favorite?1:0,date_created:item.date_created}
      })

      if (toggleSelected===1)
        prepared_items.sort(function (a, b) {
          return (a.date_created > b.date_created)?-1:(a.date_created === b.date_created)?0:1;
        });
      else
        prepared_items.sort(function (a, b) {
          // console.log('test sort',a)
          return (a.bookmarked > b.bookmarked)?-1:(a.bookmarked === b.bookmarked)?0:1;
        });

    return prepared_items
  }
  
  return (
    <div className="notes-page">
      <CollabNavButtons buttons={[{ 'link': '/dashboard', 'name': (config && config.course) ? config.course.name : 'My Course', 'active': false }, { 'link': '', 'name': 'My Notes', 'active': true }]} />
      <div className="notes-page__container">
        <div className='notes-page__toggle'>
          <ToggleButton selected={toggleSelected} handleClick={handleToggleClick} name1='Newest' name2="Favorited"/>
          <span className='notes-page__new-button custom-button' onClick={handleAddNewClick}>
               Add New
            </span>
        </div>
        {typeof config !== 'undefined' && config.bookmarks ? 
        <SectionItemGroup handleBookmarkClick={saveNoteFavorite} handleItemClick={handleNoteClick} itsbookmark={true} itsnote={true} items={prepareItems(config.notes)}/> 
        : 
        null}

      </div>
      <ModalPopup show={noteModalShow} className='session-item__note-modal' handleOverlayClick={() => showHideNoteModal(false)}>
                <div className="session-item__note-modal-title">My Notes</div>
                <div className="session-item__note-modal-body">
                    {(currentNote===null||(currentNote&&!currentNote.session))?
                    <div className="session-item__note-modal-input-name-container">
                        <input className="session-item__note-modal-input-name" value={noteTitle} onChange={noteTitleChange} />
                    </div>:null}
                    <div className="session-item__note-modal-textarea-container">
                        <textarea className="session-item__note-modal-textarea" value={noteText} onChange={noteTextChange} />
                    </div>
                </div>
                <div className="session-item__note-modal-buttons-container">
                    <CustomButton onClick={() => { saveNote(noteText); showHideNoteModal(false); }}>Save</CustomButton>
                </div>
            </ModalPopup>
    </div>
  );
};

const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    config: selectCurrentAccessCodeConfig,
    accessCodeId: selectUserCurrentAccessCode,
    user:selectUserData,
  });

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(getConfigStart()),
  saveUserNote:(access_code,session,note_id, name, text)=> dispatch(saveUserNoteStart(access_code,session,note_id, name, text)),
  saveUserNoteFavorite:(access_code,note_id)=>dispatch(saveUserNoteFavoriteStart(access_code,note_id))
});
export default connect(mapStateToProps, mapDispatchToProps)(NotesPage);
