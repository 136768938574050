import {getDayCountForDelaySectionNew} from './sessions.utils'
export const getAccessCodeById = (access_codes, id) => {
  const findAccessCode = access_codes.find(
    item => item.id === parseInt(id, 10)
  );
  return findAccessCode;
};

export const getSectionById = (config, id) => {
  const findSection = config.course.sections.find(
    item => item.id === parseInt(id, 10)
  );
  
  return {...findSection,...{'course_id': config.course.id,'course_name': config.course.name}};
};

export const checkSectionAvailability = (items, index) => {
  let available = true;
  let days = 0;
  if (items[index - 1])
    days = getDayCountForDelaySectionNew(items[index].delay);
  
  // console.log('!!session',items[index].name,items[index].delay, days)
  if ((days>0)|| (items[index - 1] && !items[index - 1].completed))
    available = false
  return available
};

export const getDayCountForDelaySection = (date, delay) => {
  const date1 = new Date(date);
  const date2 = new Date();
  // const date2 = d.getUTCDate(); 

  // To calculate the time difference of two dates 
  let Difference_In_Time = date2.getTime() - date1.getTime();

  // To calculate the no. of days between two dates 
  let Difference_In_Days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

  Difference_In_Days = delay - Difference_In_Days
  if (Difference_In_Days <= 0)
    Difference_In_Days = 0;

  return Difference_In_Days
}

export const getSessionById = (config, id) => {
  const sessions =  config.course.sections.reduce(function (acc, section, section_index) {
    const _sessions = section.sessions.map((session,index)=>{
      session['section_id']=section.id
      session['section_name']=section.name
      session['inactive']=(config.course.sections[section_index - 1] && !config.course.sections[section_index - 1].completed)?1:0
      session['prev']=section.sessions[index-1]?section.sessions[index-1].id:0;
      session['next']=section.sessions[index+1]?section.sessions[index+1].id:0;
      session['prev_section']=config.course.sections[section_index-1]?checkSectionAvailability(config.course.sections,section_index-1)?config.course.sections[section_index-1].id:false:false
      session['next_section']=config.course.sections[section_index+1]?checkSectionAvailability(config.course.sections,section_index+1)?config.course.sections[section_index+1].id:false:false
      // if (section.name=="for test")
      // console.log('session avai',section.name,checkSectionAvailability(config.course.sections,section_index+1))
      return session
    })
    return acc.concat(_sessions);
  }, [])
  const findSession = sessions.find(
    item => item.id === parseInt(id, 10)
  );
  return findSession;
};


export const changeSessionFieldInConfig = (config, session_id,field,value) => {
  if (!config)
    return false;
  config.course.sections.forEach(section => {
    section.sessions.forEach(session => {
        if (session.id===session_id){
          session[field]=value;
          return;
        }
    })
  });
  return config;
};

export const changeSessionItemAssessmentFieldInConfig = (config, session_id,session_item_id,value) => {
  if (!config)
    return false;
  config.course.sections.forEach(section => {
    section.sessions.forEach(session => {
        if (session.id===session_id){
          session.items.forEach(session_item => {
            if (session_item.id===session_item_id)
              session_item['assessment']=value
              return;
          })
          return;
        }
    })
  });
  return config;
};

export const changeSessionItemByFieldNameInConfig = (config,field, session_id,session_item_id,value) => {
  if (!config)
    return false;
  config.course.sections.forEach(section => {
    section.sessions.forEach(session => {
        if (session.id===session_id){
          session.items.forEach(session_item => {
            if (session_item.id===session_item_id)
              session_item[field]=value
              return;
          })
          return;
        }
    })
  });
  return config;
};