import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

import { selectUserCurrentAccessCode, selectConfig, selectUserData,selectCurrentAccessCodeConfig } from '../../redux/user/user.selectors.js'
import { userSignOutStart,getConfigStart, avatarUploadStart, addAccessCodeStart, saveUserDataStart, deleteAccessCodeStart,deleteMyAccountStart } from '../../redux/user/user.actions.js'
import CustomButton from '../../components/forms/custom-button/custom-button.component'
import ModalPopup from '../../components/forms/modal-popup/modal-popup.component'
import FormInput from '../../components/forms/form-input/form-input.component'
import FormLabel from '../../components/forms/form-label/form-label.component'
import FormCheckbox from '../../components/forms/form-checkbox/form-checkbox.component'
import CollabNavButtons from '../../components/collab-nav-buttons/collab-nav-buttons.component'
import AvatarModal from '../../components/avatar-modal/avatar-modal.component'

import "./account.styles.scss";

const AccountPage = ({ deleteMyAccount,userSignOut,curConfig,config, addAccessCode, saveUserData, deleteAccessCode, accessCodeId, uploadAvatar, user, getConfig, history }) => {

  const localStateInit = {
    modalShow: false,
    avatarModalShow: false,
    modalDeleteAccountShow: false,
    previewAvatar: null,
    srcAvatar: ''
  };

  const [localState, setLocalState] = useState(localStateInit);

  const {
    modalItem,
    modalShow,
    password,
    password_confirm,
    modalDeleteAccountShow,
    first_name,
    send_push,
    last_name,
    login,
    access_code,
    avatarModalShow,
    previewAvatar,
    srcAvatar

  } = localState;


  useEffect(() => {
    if (user) {
      setLocalState({ ...localState, first_name: user.first_name, last_name: user.last_name, login: user.email, send_push: user.send_push });
    }
  }, [user]);

  useEffect(() => {
    if (!config)
      getConfig()

  }, []);

  const showHideModal = (show) => {
    setLocalState({ ...localState, avatarModalShow: show });
  }

  const showHideDeleteModal = (show, item) => {
    setLocalState({ ...localState, modalShow: show, modalItem: item });
  }
  const showHideDeleteAccountModal = (show) => {
    setLocalState({ ...localState, modalDeleteAccountShow: show });
  }
  const deleteAccountConfirmClickHandle = () => {
    // userSignOut()
    deleteMyAccount();
    showHideDeleteAccountModal(false)
  }


  const deleteButtonClickHandle = () => {
    deleteAccessCode(modalItem.id)
    showHideDeleteModal(false)
  }

  const addNewAccessHandle = () => {
    addAccessCode(access_code);
  }

  const deleteAccountHandle = () => {
    showHideDeleteAccountModal(true)
  }
  const handleChange = event => {
    const { value, name } = event.target;
    console.log(value, name)
    setLocalState({ ...localState, [name]: value });
  };

  const handleCheckbox = (name) => {
    setLocalState({ ...localState, [name]: localState[name]^1 });
  };

  const updateClickHandle = () => {
    saveUserData({
      password,
      password_confirm,
      first_name,
      last_name,
      send_push
    })
    setLocalState({ ...localState, password:"", password_confirm:"" });
  }

  const avatarSaveClick = () => {
    uploadAvatar(previewAvatar)
    showHideModal(false)
  }

  const onCloseAvatar = () => {
    setLocalState({ ...localState, previewAvatar: null });
  }

  const onCropAvatar = (preview) => {
    setLocalState({ ...localState, previewAvatar: preview });
  }

  return user ? (
    <div className="account-page">
      <CollabNavButtons buttons={[{ 'link': '/dashboard', 'name': (curConfig && curConfig.course) ? curConfig.course.name : 'My Course', 'active': false }, { 'link': '', 'name': 'My Account', 'active': true }]} />
      <div className="account-page__container">
        <div className="account-page__avatar-container">
          <div className="account-page__avatar" onClick={() => showHideModal(true)}>
            <img alt='avatar' src={process.env.REACT_APP_URL + "" + user.avatar}></img>
          </div>
          <div  onClick={() => showHideModal(true)} className="account-page__avatar-text">Change avatar</div>
        </div>
        <div className="account-page__fields-container">
          <FormInput
            handleChange={handleChange}
            label="First Name"
            name="first_name"
            type="text"
            value={first_name}
            required
          />
          <FormInput
            handleChange={handleChange}
            label="Last Name"
            name="last_name"
            type="text"
            value={last_name}
            required
          />
          <FormInput
            handleChange={handleChange}
            label="Login"
            name="login"
            disabled={true}
            type="text"
            value={login}
            required
          />
          <FormInput
            handleChange={handleChange}
            label="Update Password"
            name="password"
            type="password"
            value={password}
            required
          />
          <FormInput
            handleChange={handleChange}
            label="Confirm Password"
            name="password_confirm"
            type="password"
            value={password_confirm}
            required
          />
           <FormLabel
            handleClick={()=>handleCheckbox('send_push')}
            label="Use push notifications"
          >
            <FormCheckbox
              handleClick={()=>handleCheckbox('send_push')}
              checked={send_push}
            />
          </FormLabel>
          {/* <div className="checkbox_container">
          <div className="checkbox_label">
            Use Push notifications
          </div>
            
          </div> */}
        </div>
        <div className="account-page__access-codes-buttons">
          <div className="account-page__access-codes-button">
            <CustomButton onClick={updateClickHandle} inverted>Update</CustomButton>
          </div>
        </div>
        <div className="account-page__access-codes-container">
          <div className="account-page__access-codes-title">
            My Teams
          </div>
          <div className="account-page__access-codes-selector">
            {config ? config.map((item, index) => (
              <div key={item.id} className="account-page__access-code">
                <div className="account-page__access-code-title">{item.name}</div>
                <div className="account-page__access-code-delete" onClick={() => showHideDeleteModal(true, item)}><i className="fas fa-times"></i></div>
              </div>
            )) : null}

          </div>
          <div className="account-page__access-codes-add">
            <FormInput
              handleChange={handleChange}
              label="Enter new Access Code"
              name="access_code"
              type="text"
              value={access_code}
              required
            />
          </div>
        </div>
        <div className="account-page__access-codes-buttons">
          <div className="account-page__access-codes-button">
            <CustomButton onClick={addNewAccessHandle} inverted>Add</CustomButton>
          </div>
        </div>
        <br/><br/>
        <div className="account-page__access-codes-buttons">
          <div className="account-page__access-codes-button">
            <CustomButton color='red' onClick={deleteAccountHandle} inverted>Delete My Account</CustomButton>
          </div>
        </div>
      </div>
      {modalItem ? <ModalPopup show={modalShow} handleOverlayClick={() => showHideDeleteModal(false)}>
        <div className="account-page__modal-delete-message">Are you sure you want delete [{modalItem.name}] access?</div>
        <div className="account-page__modal-delete-buttons">
          <CustomButton onClick={deleteButtonClickHandle}>Delete</CustomButton>
          <CustomButton onClick={() => showHideDeleteModal(false)} inverted>Close</CustomButton>
        </div>
      </ModalPopup> : null}
      <ModalPopup show={modalDeleteAccountShow} handleOverlayClick={() => showHideDeleteAccountModal(false)}>
        <div className="account-page__modal-delete-message">Are you sure? Once deleted, there is no way to recover your data</div>
        <div className="account-page__modal-delete-buttons">
          <CustomButton color='red' onClick={deleteAccountConfirmClickHandle}>Delete</CustomButton>
          <CustomButton onClick={() => showHideDeleteAccountModal(false)} inverted>Close</CustomButton>
        </div>
      </ModalPopup>

        
      <AvatarModal show={avatarModalShow} previewAvatar={previewAvatar} srcAvatar={srcAvatar} overlayClick={() => showHideModal(false)} avatarSaveClick={avatarSaveClick} onCloseAvatar={onCloseAvatar} onCropAvatar={onCropAvatar} />
    </div>
  ) : null;
};

const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    config: selectConfig,
    curConfig: selectCurrentAccessCodeConfig,
    accessCodeId: selectUserCurrentAccessCode,
    user: selectUserData,
  });

const mapDispatchToProps = (dispatch) => ({
  userSignOut: () => dispatch(userSignOutStart()),
  getConfig: () => dispatch(getConfigStart()),
  uploadAvatar: (image) => dispatch(avatarUploadStart(image)),
  addAccessCode: (code) => dispatch(addAccessCodeStart(code)),
  saveUserData: (data) => dispatch(saveUserDataStart(data)),
  deleteAccessCode: (id) => dispatch(deleteAccessCodeStart(id)),
  deleteMyAccount: () => dispatch(deleteMyAccountStart()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AccountPage);
