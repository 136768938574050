import React, { useEffect, useState } from "react";

import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import {
  selectUserIsLogged,
  selectUserData,
  selectUserShowHelpModal,
  selectHelpVideo,
  selectCurrentAccessCodeConfig,
  selectUserCurrentAccessCode,
  selectConfig,
} from "../../redux/user/user.selectors";

import { userSignOutStart, showHelpModal, saveCurrentAccessCodeStart, addAccessCodeStart } from '../../redux/user/user.actions';

import moment from "moment";
import Logo from "../../assets/logo.png";
import CustomButton from "../forms/custom-button/custom-button.component";
import ModalPopup from "../forms/modal-popup/modal-popup.component";
import SimpleVideoPlayer from "../simple-video-player/simple-video-player.component";
import FormSelect from '../../components/forms/form-select/form-select.component'
import FormInput from '../../components/forms/form-input/form-input.component'
import Navigation from '../navigation/navigation.component'

import { Link } from "react-router-dom";
import { getDayCountForDelaySectionNew } from "../../utils/sessions.utils.js"

import "./journey.styles.scss";

const Journey = ({ items, handleTaskClick }) => {

  const localStateInit = {
    newAccessModalShow: false,
    accessCode: "",
  };

  const [localState, setLocalState] = useState(localStateInit);

  const {
    newAccessModalShow,
    accessCode
  } = localState;

  const getDayCountForDelaySection = (date, delay) => {
    const date1 = new Date(date);
    const date2 = new Date();
    // const date2 = d.getUTCDate(); 

    // To calculate the time difference of two dates 
    let Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates 
    let Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));

    Difference_In_Days = delay - Difference_In_Days
    if (Difference_In_Days <= 0)
      Difference_In_Days = 0;

    return Difference_In_Days
  }

  const findDaysForNextSection = (date, delay) => {
    const days = getDayCountForDelaySection(date, delay);
    if (days === 1)
      return 'In 1 Day'
    else if (days === 0)
      return false
    else return `In ${days} days`
  }

  const findDaysForNextSectionNew = (delay_date) => {
    const days = getDayCountForDelaySectionNew(delay_date);
    if (days === 1)
      return 'Available in 1 Day'
    else if (days === 0)
      return false
    else if (days > 10) return `Forthcoming`
    else return `Available in ${days} days`
  }

  const formatDate = (_date) => {
    if (!(_date instanceof Date)) {
      _date = new Date(_date)
    }
    var dd = String(_date.getDate()).padStart(2, '0');
    var mm = String(_date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = _date.getFullYear();

    return mm + '/' + dd + '/' + yyyy;
  }

  const formatDateControls = (_date) => {
    return moment.parseZone(_date).format("MMM DD");
  }

  const formatDateControlsEndDate = (_date, add = false) => {
    if (add)
      return moment.parseZone(_date).add(add, "days").format("MMM DD");
    else
      return moment.parseZone(_date).format("MMM DD");
  }

  const formatDaysControls = (_date, _date2, add) => {
    if (add)
      return add;
    else {
      return moment.parseZone(_date2).diff(moment.parseZone(_date), 'days');
    }
  }

  const handleTaskClickLocal = (item, index) => {

    let canRedirect = true;
    let days = 0;

    days = getDayCountForDelaySectionNew(item.delay);

    if ((days > 0) || (items[index - 1] && !items[index - 1].completed))
      canRedirect = false
    handleTaskClick(item, canRedirect)
  }

  return (
    <div className="journey">
      {!items ?
        <ol className="timeline">
          {[1, 2, 3, 4, 5, 6, 7].map((item, index) => (
            <li key={index} className='journey__linear-background'>
              <div className="task__checkmark">
              </div>
              <div className="task__content-container">
                <div className="task__title"></div>
                <div className="task__description">
                </div>

              </div>

            </li>
          ))}
        </ol> :
        <ol className="timeline">
          {items.map((item, index) => (
            <li key={item.id} onClick={() => handleTaskClickLocal(item, index)} className={`task ${item.completed ? 'done' : ''} ${(item.completed || (items[index - 1] && !items[index - 1].completed)) ? 'inactive' : ''}`} >
              <div className="task__top-content">
                <div className="task__checkmark">
                  <svg className="checkmark" width="100%" height="100%" viewBox="0 0 100 100">
                    <circle className="checkmark-disc" cx="50" cy="50" r="45" fill="#eef4f7" stroke="#1d87e8" strokeWidth="4">
                    </circle>
                    <path
                      className="checkmark-check" d="M75 33L40 66 25 50"
                      fill="none"
                      stroke="#1d87e8"
                      strokeWidth="10"
                      strokeLinecap="round">
                    </path>
                  </svg>
                </div>
                <div className="task__content-container">
                  <div className="task__title">{index + 1}.&nbsp;{item.name}</div>
                </div>
                <div className="task__description">
                    {item.completed ?
                      ((getDayCountForDelaySectionNew(item.delay)<=0)?'Completed':'Forthcoming') :
                      (items[index - 1] && (items[index - 2] && !items[index - 2].completed)) ? "Forthcoming" :
                        findDaysForNextSectionNew(item.delay) ? findDaysForNextSectionNew(item.delay) :
                          ((items[index - 1] && items[index - 1].completed) || !items[index - 1]) ? (item.completed_first_session ? 'Continue' : 'Start') : 'Next'
                    }
                  </div>
              </div>
              
              <div className="task__controls">
                <div className="task__controls-dates">
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                  {formatDateControls(item.delay)} - {items[index + 1] && items[index + 1].delay ? formatDateControlsEndDate(items[index + 1].delay) : formatDateControlsEndDate(item.delay, 7)}
                </div>
                <div className="task__controls-days">
                  <i className="fa fa-clock-o" aria-hidden="true"></i>
                  {formatDaysControls(item.delay, items[index + 1] && items[index + 1].delay ? items[index + 1].delay : false, items[index + 1] && items[index + 1].delay ? 0 : 7)} days
                </div>
              </div>
            </li>
          ))}
        </ol>}
    </div>
  )
};

const mapStateToProps = createStructuredSelector({
  isLogged: selectUserIsLogged,
  userData: selectUserData,
  showHelpModalVar: selectUserShowHelpModal,
  helpVideo: selectHelpVideo,
  currentConfig: selectCurrentAccessCodeConfig,
  config: selectConfig,
  accessCodeId: selectUserCurrentAccessCode
});

const mapDispatchToProps = dispatch => ({
  userSignOut: () => dispatch(userSignOutStart()),
  showHelpModalFunc: (show) => dispatch(showHelpModal(show)),
  selectCurrentAccessCode: (id) => dispatch(saveCurrentAccessCodeStart(id)),
  addAccessCode: (code) => dispatch(addAccessCodeStart(code)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Journey);
