import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useParams } from "react-router-dom";

import { createStructuredSelector } from "reselect";

import { selectResetPassword } from '../../redux/user/user.selectors.js'
import { resetPasswordConfirmStart, resetPasswordRequestStart, resetPasswordValidateStart } from '../../redux/user/user.actions.js'
import { notificationAddStart } from '../../redux/notifications/notifications.actions';
import CustomButton from '../../components/forms/custom-button/custom-button.component'
import FormInput from '../../components/forms/form-input/form-input.component'
import { Link } from 'react-router-dom';
import Logo from "../../assets/app_svg_logo.svg";

import "./reset_password.styles.scss";

const ResetPasswordPage = ({ fireNotificaton, resetPasswordData, validate, request, confirm, history }) => {

  const localStateInit = {
    password: '',
    password_confirm: '',
    login: '',
    confirmMode: false
  };
  let { token } = useParams();
  const [localState, setLocalState] = useState(localStateInit);

  const {
    password,
    password_confirm,
    login,
    confirmMode
  } = localState;



  useEffect(() => {
    if (token) {
      setLocalState({ ...localState, confirmMode: true });
      validate(token)
    }

  }, [token]);

  const handleChange = event => {
    const { value, name } = event.target;
    setLocalState({ ...localState, [name]: value });
  };

  const sendClickHandle = () => {
    if (confirmMode) {
      if (resetPasswordData.confirm === 1) {

      } else {
        if (password_confirm !== password) {
          fireNotificaton('Different passwords')
          return;
        }
        confirm(
          token,
          password,
        )
      }
    }
    else
      request(login)

  }


  return (<div className="reset-password-page">
  <br/>
      <img className="collaboration-app-homepage-logo" src={Logo} alt="collaboration-app-homepage" onClick={()=>history.push('/')} />
    <div className="reset-password-page__title">
      Reset Password
    </div>
    <div className="reset-password-page__container">

      <div className="reset-password-page__description">
        {confirmMode ? resetPasswordData.validate === -1 ? <React.Fragment>Wrong Token</React.Fragment> : 
          resetPasswordData.confirm === 1 ? <React.Fragment>Password changed</React.Fragment>:<React.Fragment>Change your password</React.Fragment>
          : resetPasswordData.request === 1 ? <React.Fragment>Email sent to {login}. If you don't see the message, please check your Spam/Junk folder.</React.Fragment>:<React.Fragment>Enter your email address</React.Fragment>
        }
      </div>
      {confirmMode ? resetPasswordData.validate === -1 ? null :
        <div className="reset-password-page__fields-container">
          {resetPasswordData.confirm === 1 ? null : 
          <React.Fragment>
            <FormInput
              handleChange={handleChange}
              label="Update Password"
              name="password"
              type="password"
              value={password}
              required
            />
            <FormInput
              handleChange={handleChange}
              label="Confirm Password"
              name="password_confirm"
              type="password"
              value={password_confirm}
              required
            />

          </React.Fragment>}
        </div>
        :
        <div className="reset-password-page__fields-container">
          {resetPasswordData.request === 1 ? null :
            <FormInput
              handleChange={handleChange}
              label="Email"
              name="login"
              type="text"
              value={login}
              required
            />}
        </div>
      }

      <div className="reset-password-page__buttons">
        <div className="reset-password-page__button">
          {confirmMode ? resetPasswordData.validate === -1 ? null : resetPasswordData.confirm === 1 ? <Link to="/signin"><CustomButton onClick={sendClickHandle} inverted>Login</CustomButton></Link> : <CustomButton onClick={sendClickHandle} inverted>Change</CustomButton>
            : resetPasswordData.request === 1 ? null : <CustomButton onClick={sendClickHandle} inverted>Reset</CustomButton>}
        </div>
      </div>

    </div>
  </div>)
};

const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    resetPasswordData: selectResetPassword,
  });

const mapDispatchToProps = (dispatch) => ({
  validate: (token) => dispatch(resetPasswordValidateStart(token)),
  confirm: (token, password) => dispatch(resetPasswordConfirmStart(token, password)),
  request: (email) => dispatch(resetPasswordRequestStart(email)),
  fireNotificaton: (message) => dispatch(notificationAddStart(message, 'danger', 5000)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);
