import { createSelector } from "reselect";
import {getAccessCodeById, getSectionById, getSessionById} from "../../utils/users.utils"

const selectUser = state => state.user;

export const selectCurrentUser = createSelector(
  [selectUser],
  user => user.currentUser
);

export const selectResetPassword = createSelector(
  [selectUser],
  user => user.resetPassword?user.resetPassword:{}
);


export const selectUserProcessing = createSelector(
  [selectUser],
  user => user.processing
);

export const selectUserConfigProcessing = createSelector(
  [selectUser],
  user => user.configProcessing
);

export const selectUserIsLogged = createSelector(
  [selectUser],
  user => user.isLogged
);

export const selectUserShowHelpModal = createSelector(
  [selectUser],
  user => user.showHelpModal
);

export const selectRegistrationId = createSelector(
  [selectUser],
  user => user.registrationId
);

export const selectCompleteModal = createSelector(
  [selectUser],
  user => user.completeModal
);

export const selectRedirectUrl = createSelector(
  [selectUser],
  user => user.redirectUrl
);

export const selectUserCurrentAccessCode = createSelector(
  [selectUser],
  user => user.userData?user.userData['current_access_code']:0
);

export const selectCurrentNotificationTimestamp = createSelector(
  [selectUser,selectUserCurrentAccessCode],
  (user,access_code_id) => user.notifications[access_code_id]?user.notifications[access_code_id]['timestamp']:0
);

export const selectCurrentNotifications = createSelector(
  [selectUser,selectUserCurrentAccessCode],
  (user,access_code_id) => user.notifications[access_code_id]?user.notifications[access_code_id]['notifications']:[]
);


export const selectUserError = createSelector([selectUser], user => user.error);

export const selectUserData = createSelector(
  [selectUser],
  user => user.userData
);

export const selectLast4 = createSelector(
  [selectUser],
  user => user.userData?user.userData['last_4']:0
);

export const selectHelpVideo = createSelector(
  [selectUser],
  user => user.userData?user.userData['help_video']:''
);

export const selectOrganisation = createSelector(
  [selectUser],
  user => user.userData?user.userData['organisation']:''
);

export const selectTax = createSelector(
  [selectUser],
  user => user.userData?user.userData['tax']:0
);

export const selectCountries = createSelector(
  [selectUser],
  user => user.countries?user.countries:[]
);

export const selectHomepageConfig = createSelector(
  [selectUser],
  user => user.homePageConfig?user.homePageConfig:{}
);

export const selectConfig = createSelector(
  [selectUser],
  user => user.config?user.config:false
);

export const selectAccessCodeConfig = (id) => createSelector(
  [selectUser],
  user => user.config?getAccessCodeById(user.config,id):false
);

export const selectCurrentAccessCodeConfig = createSelector(
  [selectUser,selectUserCurrentAccessCode],
  (user,accessCode) => user.config?getAccessCodeById(user.config,accessCode):false
);

export const selectCurrentSectionById = (id) => createSelector(
  [selectCurrentAccessCodeConfig],
  (config) => config?getSectionById(config, id):false
);

export const selectCurrentSessionById = (id) => createSelector(
  [selectCurrentAccessCodeConfig],
  (config) => {return config?getSessionById(config, id):false}
);

export const selectCurrentAccessCodeTeamProgress = createSelector(
  [selectCurrentAccessCodeConfig],
  (config) => config.team?config.team:[]
);