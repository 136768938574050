import React, { useEffect, useState, useRef, useCallback } from 'react';
import { MentionsInput, Mention } from 'react-mentions'
import Picker from 'emoji-picker-react';

import './chat.styles.scss'

const ChatTextarea = ({ sendTextHandle, users, ...otherProps }) => {
    const localStateInit = {
        text: "",
        chosenEmoji: "",
        showEmojiPicker:false,
        caretPosition:0,
    };
    const [localState, setLocalState] = useState(localStateInit);
    const refTextarea = useRef(null);

    const {
        text,
        chosenEmoji,
        showEmojiPicker,
        caretPosition,
    } = localState;

    const extractTextAreaKeys = (text = "", keys) => {
        // const regex = new RegExp(`{\\*(.*?)\\*}`, "g");
        // const regex = new RegExp(`{\[@\[.*?\]\((.*?\)\]}`, "g");
        const regex = new RegExp("\\[@\\[.*?\\]\\((.*?)\\)\\]", "g");
        
        
    
        if (text) {
          return Array.from(text.matchAll(regex)).map((match) => {
            const key = match[1];
            const token = match[0];
            
            const displayValue = keys.find((display) => display.id == key);
            console.log('find',token,key,displayValue,keys)
            
            return {
              key,
              token,
              index: match.index,
              diffLength: token.length - displayValue.display.length,
              display: displayValue ? displayValue?.display : "Not Found",
              startPlainTextkeyIndex: match.index,
              endPlainTextKeyIndex: match.index + token.length-1
            };
          });
        } else return [];
      };
    const insertAtCaret = ( val) => {
        const prevCaretPos = caretPosition;
        const keys = extractTextAreaKeys(text, users);
        let newText = ""
        const sumDiffLength = keys?.reduce((sumValue, current, index) => {
          let sumDifLength = index === 0 ? 0 : sumValue[index - 1]?.sum;
          sumDifLength += current.diffLength;
          return [
            ...sumValue,
            {
              ...current,
              sum: index === 0 ? current.diffLength : sumDifLength,
              plainTextIndex:
                index === 0
                  ? current.index
                  : current?.index - sumValue?.[index - 1]?.sum
            }
          ];
        }, []);
        const getFormatDiffLength = sumDiffLength?.map((item, index) => {
          return item.plainTextIndex;
        });
    
        const getCountKey = getFormatDiffLength.reduce((sum, current) => {
          if (prevCaretPos > current) {
            return (sum += 1);
          } else {
            return sum;
          }
        }, 0);
        keys.length = getCountKey;
        const getSumLength = keys.reduce((sum, current) => {
          return (sum += current.diffLength);
        }, 0);
        const getLastKey = keys?.[getCountKey - 1];
        const startValueLength =
          getCountKey > 1 ? getLastKey.index : getLastKey?.startPlainTextkeyIndex;
        const endValueLength =
          getCountKey > 1
            ? getLastKey.index + getLastKey?.token.length
            : getLastKey?.endPlainTextKeyIndex + getCountKey;
    
        const valuePosition = prevCaretPos + getSumLength;
        if (valuePosition >= startValueLength && valuePosition <= endValueLength) {
            newText = text.substring(0, startValueLength) + val + text.substring(endValueLength)
        } else {
            newText = text.substring(0, valuePosition) + val + text.substring(valuePosition)
        }
        return newText
      }


    const showHideEmojiPicker = (mode) => {
        setLocalState({ ...localState, showEmojiPicker: mode })
    };

    const storeCaretPosition = (position) => {
        setLocalState({ ...localState, caretPosition: position })
    };

    const onEmojiClick = (event, emojiObject) => {
        let newValue = text
        newValue = insertAtCaret(emojiObject.emoji)

        refTextarea.current.focus();
        setLocalState({ ...localState, chosenEmoji: emojiObject, showEmojiPicker:false, text: newValue })
    };

    const textChange = (event, newValue, newPlainTextValue, mentions) => {
        setLocalState({ ...localState, text: newValue })
    }

    const onEnterPress = (e) => {

        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendText()
        }
    }

    const sendText = () => {
        if (!text)
            return false;
        if (sendTextHandle)
            sendTextHandle(text)

        textChange(false, '')
    }

    return (
        <React.Fragment>
             <div className="spk-chat__textarea-new-emoji-picker" tabIndex={0} onBlur={(e)=>{
                 if (!e.currentTarget.contains(e.relatedTarget)) {
                        showHideEmojiPicker(false)
                    }      
             }}>
               {showEmojiPicker?
                <Picker native={true} preload={true} onEmojiClick={onEmojiClick} />:null}  
            </div>
            <div className="rta  spk-chat__textarea">
                <div className="rta  spk-chat__textarea-new">
                    <div className="rta  spk-chat__textarea-new-input">
                    <MentionsInput onSelect={(event) => storeCaretPosition(event.target.selectionStart)} inputRef={refTextarea} style={{'input': {'overflow': 'auto'},'highlighter': {'overflow': 'auto'}}} onKeyDown={onEnterPress} placeholder="Type your message. Use the @ symbol to respond to someone directly. Use @all to respond to the whole team." allowSuggestionsAboveCursor={true} className="mentions" value={text} onChange={textChange}>
                    <Mention
                        className="mentions__mention0"
                        trigger="@"
                        appendSpaceOnAdd={true}
                        data={users}
                        markup={'[@[__display__](__id__)]'}
                        renderSuggestion={(
                            suggestion,
                            search,
                            highlightedDisplay,
                            index,
                            focused
                        ) => (
                            <div className={`user ${focused ? 'focused' : ''}`}>
                                {highlightedDisplay}
                            </div>
                        )}
                        style={{ backgroundColor: '#3cc4f1' }}
                    />
                </MentionsInput>
                    </div>
                    <div className="rta  spk-chat__textarea-new-controls">
                        <div className="rta  spk-chat__textarea-new-control">
                            <div className="rta  spk-chat__textarea-new-control--emoji" onClick={()=>showHideEmojiPicker(true)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" data-supported-dps="24x24" fill="currentColor" class="" width="24" height="24" focusable="false">
                                    <path d="M8 10.5A1.5 1.5 0 119.5 12 1.5 1.5 0 018 10.5zm6.5 1.5a1.5 1.5 0 10-1.5-1.5 1.5 1.5 0 001.5 1.5zm7.5 0A10 10 0 1112 2a10 10 0 0110 10zm-2 0a8 8 0 10-8 8 8 8 0 008-8zm-8 4a6 6 0 01-4.24-1.76l-.71.76a7 7 0 009.89 0l-.71-.71A6 6 0 0112 16z"></path>
                                </svg>
                            </div>
                        </div>
                        <div className="rta  spk-chat__textarea-new-control">
                            <button className="spk-chat__send-button-new" onClick={sendText}>
                                Send
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
            
        </React.Fragment>
    )
}

export default ChatTextarea;