import React from 'react';

import './assessment-stats.styles.scss'

import ReactApexChart from 'react-apexcharts'


const AssessmentStats = ({ options,series,height, ...otherProps }) => (
    <div className='assessment-stats' {...otherProps}>
        <ReactApexChart options={options} series={series} type="radialBar" height={height?height:350} />
    </div>
)

export default AssessmentStats;