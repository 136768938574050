import React from 'react';


import './toggle-button.styles.scss'


const ToggleButton = ({ name1,name2,selected,handleClick, ...otherProps }) => (
    <div  className="toggle-button" {...otherProps}>
       <div className="toggle-button__container">
         <input readOnly onClick={()=>handleClick(1)} type="radio" id="name1" name="sort" checked={selected===1} /><label htmlFor="name1">{name1}</label>
         <input readOnly onClick={()=>handleClick(2)} type="radio" id="name2" name="sort" checked={selected===2} /><label htmlFor="name2">{name2}</label>
      </div>
    </div>
)

export default ToggleButton;