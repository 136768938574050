import React,{useRef} from "react";

import "./navigation.styles.scss";

const Navigation = ({ items }) => {
  const inputEl = useRef(null);
  const clickHandle= (clickFunc) =>{
    inputEl.current.checked = false;
    clickFunc();
  }
  return(
  <div className='navigation'>
      <input type="checkbox" ref={inputEl} id="navi-toggle" className="navigation__checkbox" />

<label htmlFor="navi-toggle" className="navigation__button">
  <span className="navigation__icon">
    <span className="navigation__icon-span">&nbsp;</span>
    <span className="navigation__icon-span">&nbsp;</span>
    <span className="navigation__icon-span">&nbsp;</span>
    <span className="navigation__icon-span">&nbsp;</span>
    <span className="navigation__icon-span">&nbsp;</span>
    <span className="navigation__icon-span">&nbsp;</span>
  </span>
</label>

<div className="navigation__background">&nbsp;</div>

<nav className="navigation__nav">
  <ul className="navigation__list">
  {items.filter(item=>item!==false).map((item,index)=>{
    return (
    <li key={index} className="navigation__item">
      <a href="javascript:;" onClick={()=>clickHandle(item.onClick)} className="navigation__link">{item.name}</a>
    </li>)
  })}    
  </ul>
</nav>
  </div>

)};




export default Navigation;
