import React from 'react';

import './section-item.styles.scss'


const SectionItem = ({ item,itsbookmark,itsnote,handleClick,handleBookmarkClick, ...otherProps }) => (
    <div onClick={(event) => handleClick ? handleClick(event, otherProps.id) : null} className={`${ item.completed ? "completed" : ""}  ${ item.current ? "current" : ""} ${ item.inactive ? "inactive" : ""}  section-item`} {...otherProps}>
    
        <div className="section-item__checkmark">
        {!itsbookmark?<svg className="checkmark" width="100%" height="100%" viewBox="0 0 100 100">
                <circle className="checkmark-disc" cx="50" cy="50" r="45" fill="#eef4f7" stroke="#1d87e8" strokeWidth="4">
                </circle>
                <path
                    className="checkmark-check" d="M75 33L40 66 25 50"
                    fill="none"
                    stroke="#1d87e8"
                    strokeWidth="10"
                    strokeLinecap="round">
                </path>
            </svg>:null}
        </div>
        
        <div className='section-item__text'>
            <div className='section-item__name'>
                {item.name}
            </div>
            <div className='section-item__description'>
                {item.description}
            </div>
        </div>
        <div className='section-item__description section-item__description--small'>
                {item.description}
            </div>
        <div className='section-item__stats'>
        {!itsbookmark?
        <React.Fragment>
            {/* <div className='section-item__time'>
                <i className="far fa-clock"></i>
                <span>{item.duration} min</span>
            </div> */}
            <div className='section-item__chat'>
                <i className="far fa-comment"></i>
                <span>{item.chat.length}</span>
            </div>
            <div className='section-item__bookmark'>
            <i className={`far fa-bookmark ${item.bookmarked?'active':''}`}></i>
            <span>{item.bookmarks}</span>
            </div>
        </React.Fragment>:itsnote?
            <div className='section-item__bookmark notes' onClick={(e)=>{ e.stopPropagation();handleBookmarkClick(item.id)}}>
                <i className={`far fa-heart ${item.bookmarked?'active':''}`}></i>
            </div>
        :
            <div className='section-item__bookmark bookmarks'>
                <i className={`far fa-bookmark ${item.bookmarked?'active':''}`}></i>
                <span>{item.bookmarks}</span>
            </div>
            }
            
        </div>

    </div>
)

export default SectionItem;