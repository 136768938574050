import React from 'react';

import './slide-modal.styles.scss'


const SlideModal = ({ show,children,overlayClickHandle,closeClickHandle, ...otherProps }) => (
    <div className={`${show?"active":null} slide-modal`} {...otherProps}>
        <div className='slide-modal__overlay' onClick={overlayClickHandle}></div>
        <div className="slide-modal__body">
        {closeClickHandle ? <div onClick={closeClickHandle} className="slide-modal__close-button"><svg width="50" height="50" viewBox="0 0 30 30" className="gg"><path d="M18.13 6.11l-5.61 5.61-5.6-5.61-.81.8 5.61 5.61-5.61 5.61.8.8 5.61-5.6 5.61 5.6.8-.8-5.6-5.6 5.6-5.62"></path></svg></div> : null}
          {children}
        </div>
      </div>
)

export default SlideModal;