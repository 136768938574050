import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";


import arrayMove from 'array-move';

import { selectRegistrationId, selectUserCurrentAccessCode, selectCurrentAccessCodeConfig, selectUserData } from '../../redux/user/user.selectors.js'
import {setPushRegistrationId, saveValueStart, getConfigStart, avatarUploadStart, avatarDiscardStart } from '../../redux/user/user.actions.js'

import AvatarModal from '../../components/avatar-modal/avatar-modal.component'


import SelectBubblesGroup from '../../components/select-bubbles-group/select-bubbles-group.component'
import SelectBubblesSortableGroup from '../../components/select-bubbles-sortable-group/select-bubbles-sortable-group.component'
import SimpleVideoPlayer from '../../components/simple-video-player/simple-video-player.component'
import Journey from '../../components/journey/journey.component'
import CollabNavButtons from '../../components/collab-nav-buttons/collab-nav-buttons.component'
import { changeSessionFieldInConfig } from '../../utils/users.utils'


import "./dahboard.styles.scss";

const DashboardPage = ({ config, setNativeRegistrationId, registrationId, accessCodeId,avatarDiscard, uploadAvatar, user, getConfig, history }) => {


  const localStateInit = {
    avatarModalShow: false,
    previewAvatar: null,
    srcAvatar: ''
  };

  const [localState, setLocalState] = useState(localStateInit);

  const {
    avatarModalShow,
    previewAvatar,
    srcAvatar
  } = localState;


  useEffect(() => {
    if (user && user.constructor === Object && Object.keys(user).length !== 0 && !user.avatar) {
      showHideAvatarModal(true)
    } else {
      showHideAvatarModal(false)
    }
  }, [user]);

  useEffect(() => {
    if (!config)
      getConfig()
    if (registrationId)
      setNativeRegistrationId(registrationId)
  }, []);

  const showHideAvatarModal = (show) => {
    setLocalState({ ...localState, avatarModalShow: show });
  }

  const discardAvatarCreation = () => {
    //default avatar creation command
    avatarDiscard()
    setLocalState({ ...localState, avatarModalShow: false });
  }


  const handleTaskClick = (item, canRedirect) => {
    if (canRedirect)
      history.push(`section/${item.id}`)
  }

  const avatarSaveClick = () => {
    if (previewAvatar)
      uploadAvatar(previewAvatar)
  }

  const onCloseAvatar = () => {
    setLocalState({ ...localState, previewAvatar: null });
  }

  const onCropAvatar = (preview) => {
    setLocalState({ ...localState, previewAvatar: preview });
  }

  const navButtons = [{ 'link': '', 'name': (config && config.course) ? config.course.name : 'My Course', 'active': true }, { 'link': '/team', 'name': 'Team Progress', 'active': false }, { 'link': '/collaborativeway', 'name': 'Resources', 'active': false }]

  const prepareNavButtons = (navButtons) => {

    if (config && config.forum_access)
      navButtons.push({ 'link': '/forum', 'name': 'Practice Forum', 'active': false })

    return navButtons
  }

  return (
    <div className="dashboard-page">

      <CollabNavButtons buttons={prepareNavButtons(navButtons)} />
      <div className="dashboard-page__container">
          <Journey items={typeof config !== 'undefined' && config.course ?config.course.sections:false} handleTaskClick={handleTaskClick} />
      </div>
      <AvatarModal show={avatarModalShow} previewAvatar={previewAvatar} srcAvatar={srcAvatar} overlayClick={() => discardAvatarCreation()} avatarSaveClick={avatarSaveClick} onCloseAvatar={onCloseAvatar} onCropAvatar={onCropAvatar} />
    </div>
  );
};

const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    config: selectCurrentAccessCodeConfig,
    accessCodeId: selectUserCurrentAccessCode,
    user: selectUserData,
    registrationId: selectRegistrationId,
  });

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(getConfigStart()),
  uploadAvatar: (image) => dispatch(avatarUploadStart(image)),
  avatarDiscard: () => dispatch(avatarDiscardStart()),
  setNativeRegistrationId: (registration_id) => dispatch(setPushRegistrationId(registration_id)),
  // saveValues: (access_code_id, value_ids) => dispatch(saveValueStart(access_code_id, value_ids)),
});
export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
