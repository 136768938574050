import React from 'react';

import './section-item-group.styles.scss';

import SectionItem from '../section-item/section-item.component'

const SectionItemGroup = ({ items,itsbookmark,itsnote, handleItemClick,handleBookmarkClick }) => {
  return (
    
    <div className="section-item-group">
      {items.map(item => (item?
        <SectionItem
          key={item.id}
          itsbookmark={itsbookmark}
          itsnote={itsnote}
          {...item}
          item={item}
          handleClick={handleItemClick}
          handleBookmarkClick={handleBookmarkClick}
        />:null
      ))}
    </div>
  );
}

export default SectionItemGroup;