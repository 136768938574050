import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";
import { selectHomepageConfig, selectCurrentAccessCodeConfig } from '../../redux/user/user.selectors';
import { getHomepageConfigStart } from '../../redux/user/user.actions.js'
import CollabNavButtons from '../../components/collab-nav-buttons/collab-nav-buttons.component'


import "./collaborative-way.styles.scss";

const CollaborativeWayPage = ({ config, homepageConfig, getHomepageConfig, history, ...otherProps }) => {


  const localStateInit = {
    modalShow: false,
  };
  let { id } = useParams();
  const [localState, setLocalState] = useState(localStateInit);

  const {
    modalShow,
  } = localState;

  const location = useLocation();

  useEffect(() => {
    if (!homepageConfig) {
      getHomepageConfigStart()
    }
    if (window.cordova) {
      document.addEventListener('click', (e) => {
        var href;
        var target = e.target || e.srcElement;
        if (target.tagName === 'A') {
          href = target.getAttribute('href');
          if (target.parentElement.parentElement.parentElement.className==="accordion-cb-content"){
            e.preventDefault();
            if (href.includes("http"))
              window.cordova.InAppBrowser.open(href, '_blank', 'location=no,closebuttoncaption=Close');
            else
              history.push(href)   
          }
         
        }
      });
    }

  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (location.hash && homepageConfig.collab_page) {
        document.querySelector(location.hash).checked = true;
        document.querySelector(location.hash).scrollIntoView()
      }

    }, 1000)
    return () => {
      clearTimeout(timer)
    }
  }, [homepageConfig])

  const navButtons = [{ 'link': '', 'name': (config && config.course) ? config.course.name : 'My Course', 'active': false }, { 'link': '/team', 'name': 'Team Progress', 'active': false }, { 'link': '/collaborativeway', 'name': 'Resources', 'active': true }]

  const prepareNavButtons = (navButtons) => {

    if (config && config.forum_access)
      navButtons.push({ 'link': '/forum', 'name': 'Practice Forum', 'active': false })

    return navButtons
  }

  const createMarkup = () => ({ __html: homepageConfig.collab_page });

  return (
    <div className="collaborative-way-page">
      <CollabNavButtons buttons={prepareNavButtons(navButtons)} />
      <div className="collaborative-way-page__container" dangerouslySetInnerHTML={createMarkup()}>

      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return createStructuredSelector({
    homepageConfig: selectHomepageConfig,
    config: selectCurrentAccessCodeConfig,
  })
}

const mapDispatchToProps = (dispatch) => {
  return {
    getHomepageConfig: () => dispatch(getHomepageConfigStart()),
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(CollaborativeWayPage);

