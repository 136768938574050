import {createStore, applyMiddleware,compose} from 'redux';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';

import rootSaga from './root.saga';

import rootReducer from './root-reducer';



const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware]; 

if (process.env.NODE_ENV==='development'){
    middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, /* preloadedState, */ composeEnhancers(applyMiddleware(...middlewares)));
// export const store = createStore(rootReducer, applyMiddleware(...middlewares));

 sagaMiddleware.run(rootSaga);

export default store;