import React from 'react';

import './team-card.styles.scss'


const TeamCard = ({ item,user, handleClick, ...otherProps }) => (
    <div onClick={() => handleClick ? handleClick(item) : null} className={`team-card ${item.coach?"coach":""}`} {...otherProps}>
        <div className='team-card__name-container'>
            <div className='team-card__avatar'>
                <img alt={item.display} src={process.env.REACT_APP_URL + "" + item.avatar}></img>
            </div>
            <div className='team-card__name'>
                {  
                    user.id===item.id
                        ?
                        <span>Me <i className="team-card__user-icon far fa-user"></i></span>
                        :
                        <span>{item.first_name} {item.last_name}</span>
                }

            </div>
            <div className='team-card__coach'>
                {item.coach && <span>Coach</span>}
            </div>
        </div>
        <div className='team-card__progressbar_container'>
            {!item.coach && <div className='team-card__progressbar'>
                <div className="team-card__progress">
                    <div style={{ width: `${item.percent}%` }} className="team-card__progress-bar"></div>
                </div>
            </div>}
        </div>

    </div>
)

export default TeamCard;