import AccessCodesTypes from './access-codes.types'

export const getAccessCodeConfigStart = () => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_CONFIG_START
});

export const getAccessCodeConfigSuccess = (config) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_CONFIG_SUCCESS,
    payload: config
});

export const getAccessCodeConfigError = (error) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_CONFIG_ERROR,
    payload: error
});

export const createAccessCodeStart = (data) => ({
    type: AccessCodesTypes.CREATE_ACCESS_CODE_START,
    payload:data
});

export const createAccessCodeSuccess = (id) => ({
    type: AccessCodesTypes.CREATE_ACCESS_CODE_SUCCESS,
    payload: id
});

export const createAccessCodeError = (error) => ({
    type: AccessCodesTypes.CREATE_ACCESS_CODE_ERROR,
    payload: error
});



export const getAccessCodesFeaturesStart = () => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_FEATURES_START
});

export const getAccessCodesFeaturesSuccess = (features) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_FEATURES_SUCCESS,
    payload: features
});

export const getAccessCodesFeaturesError = () => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_FEATURES_ERROR
});

export const getAccessCodesResourcesStart = () => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_RESOURCES_START
});

export const getAccessCodesResourcesSuccess = (resources) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_RESOURCES_SUCCESS,
    payload: resources
});

export const getAccessCodesResourcesError = () => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_RESOURCES_ERROR
});



export const checkAccessCodeCouponStart = (coupon) => ({
    type: AccessCodesTypes.CHECK_ACCESS_CODE_COUPON_START,
    payload: coupon
});

export const checkAccessCodeCouponSuccess = (discount) => ({
    type: AccessCodesTypes.CHECK_ACCESS_CODE_COUPON_SUCCESS,
    payload: discount
});

export const checkAccessCodeCouponError = (error) => ({
    type: AccessCodesTypes.CHECK_ACCESS_CODE_COUPON_ERROR,
    payload: error
});


export const getAccessCodeStart = (id) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_START,
    payload: id
});

export const getAccessCodeSuccess = (accessCode) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_SUCCESS,
    payload: accessCode
});

export const getAccessCodeError = (error) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_ERROR,
    payload: error
});


export const editAccessCodeStart = (id, data) => ({
    type: AccessCodesTypes.EDIT_ACCESS_CODE_START,
    payload: {id, data}
});

export const editAccessCodeSuccess = () => ({
    type: AccessCodesTypes.EDIT_ACCESS_CODE_SUCCESS,
});

export const editAccessCodeError = (error) => ({
    type: AccessCodesTypes.EDIT_ACCESS_CODE_ERROR,
    payload: error
});


export const listAccessCodesStart = (id) => ({
    type: AccessCodesTypes.LIST_ACCESS_CODES_START,
    payload: id
});

export const listAccessCodesSuccess = (list) => ({
    type: AccessCodesTypes.LIST_ACCESS_CODES_SUCCESS,
    payload: list
});

export const listAccessCodesError = (error) => ({
    type: AccessCodesTypes.LIST_ACCESS_CODES_ERROR,
    payload: error
});

export const getAccessCodeStatsStart = (id) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_STATS_START,
    payload: id
});

export const getAccessCodeStatsSuccess = (id,stats) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_STATS_SUCCESS,
    payload: {id,stats}
});

export const getAccessCodeStatsError = (error) => ({
    type: AccessCodesTypes.GET_ACCESS_CODE_STATS_ERROR,
    payload: error
});


export const getInvitePdfStart = (id,name) => ({
    type: AccessCodesTypes.GET_INVITE_PDF_START,
    payload: [id,name]
});

export const getInvitePdfSuccess = () => ({
    type: AccessCodesTypes.GET_INVITE_PDF_SUCCESS,
});

export const getInvitePdfError = (error) => ({
    type: AccessCodesTypes.GET_INVITE_PDF_ERROR,
    payload: error
});