import React, { useEffect, useState } from 'react';

import CustomButton from "../../../forms/custom-button/custom-button.component"
import QuizAnswersGroup from '../quiz-answers-group/quiz-answers-group.component'
import '../quiz.styles.scss'
// import {saveUserChoiceQuestionAnswerStart} from '../../../../redux/user/user.actions'


const UserChoice = ({ item, saveAnswerHandle, quizEndedHandle, ...otherProps }) => {

    const localStateInit = {
        state: 'start',
        currentQuestionIndex: 0,
        currentQuestionAnswers: [],
        currentQuestion: {},
        fadingText: false,
    };
    const [localState, setLocalState] = useState(localStateInit);

    const {
        state,
        currentQuestionIndex,
        currentQuestion,
        currentQuestionAnswers,
        fadingText,

    } = localState;

    let timer = false;
    useEffect(() => {

        // setLocalState({ ...localState, state:'quiz',currentQuestionAnswers:newCurQuestionAnswers, currentQuestion:curQuestion})
    }, [])

    useEffect(() => {
        let newstate = state
        if (item.completed) {
            newstate = 'quiz'
            setTimeout(() => {
                quizEndedHandle(item)
            }, 100)
        }
        let curQuestion = item.questions[currentQuestionIndex]
        let newCurQuestionAnswers = prepareCurQuestionAnswers(curQuestion)
        setLocalState({ ...localState, state: newstate, currentQuestionAnswers: newCurQuestionAnswers, currentQuestion: curQuestion })
        if (newstate === "start") {
            newstate = 'quiz'
            const newCurrentQuestionIndex = item.questions.findIndex(question => question.total_selected < question.max_choices)
            curQuestion = item.questions[newCurrentQuestionIndex]
            newCurQuestionAnswers = prepareCurQuestionAnswers(curQuestion)
            setLocalState({ ...localState, state: newstate, currentQuestionIndex: newCurrentQuestionIndex, currentQuestionAnswers: newCurQuestionAnswers, currentQuestion: curQuestion })
        }

    }, [item]);

    const prepareCurQuestionAnswers = (currentQuestion) => {
        currentQuestion['standard'] = !currentQuestion.answers.some(item => item.correct)
        return currentQuestion.answers.map(item => item.selected ? item.id : -1).filter(item => item !== -1)
    }

    const startQuizButton = () => {
        const curQuestion = item.questions[currentQuestionIndex]
        setLocalState({ ...localState, state: 'quiz', currentQuestion: curQuestion })
    }

    const calcCorrectAnswersInQuestion = () => {
        if (currentQuestion.standard)
            return currentQuestion.total_selected >= currentQuestion.max_choices
        else
            return currentQuestion.total_correct_selected >= currentQuestion.total_correct
    }

    const saveAnswerHandler = (e, answer_id) => {
        let newCurQuestionAnswers = [...currentQuestionAnswers]

        if (calcCorrectAnswersInQuestion() && !newCurQuestionAnswers.includes(answer_id))
            return false;

        saveAnswerHandle(item.id, currentQuestion.id, answer_id)

        if (newCurQuestionAnswers.includes(answer_id))
            newCurQuestionAnswers = newCurQuestionAnswers.filter(val => val !== answer_id);
        else
            newCurQuestionAnswers.push(answer_id)

        setLocalState({ ...localState, currentQuestionAnswers: newCurQuestionAnswers })
    }



    const handleChangeQuestion = (type) => {
        switch (type) {
            case 'next':
                if (item.questions[currentQuestionIndex + 1]) {
                    if (timer)
                        clearTimeout(timer)
                    const curQuestion = item.questions[currentQuestionIndex + 1]
                    const curQuestionIndex = currentQuestionIndex + 1
                    const newCurQuestionAnswers = prepareCurQuestionAnswers(curQuestion)
                    setLocalState({ ...localState, fadingText: true })
                    timer = setTimeout(() => {
                        setLocalState({ ...localState, currentQuestionAnswers: newCurQuestionAnswers, currentQuestion: curQuestion, currentQuestionIndex: curQuestionIndex, fadingText: false })
                    }, 300); // animation timing offset

                } else {
                    // quizEndedHandle(item)
                    // setLocalState({ ...localState, state: 'finished'})
                }

                break;
            case 'prev':
                if (item.questions[currentQuestionIndex - 1]) {
                    const curQuestion = item.questions[currentQuestionIndex - 1]
                    const curQuestionIndex = currentQuestionIndex - 1
                    const newCurQuestionAnswers = prepareCurQuestionAnswers(curQuestion)
                    setLocalState({ ...localState, fadingText: true })
                    timer = setTimeout(() => {
                        setLocalState({ ...localState, currentQuestionAnswers: newCurQuestionAnswers, currentQuestion: curQuestion, currentQuestionIndex: curQuestionIndex, currentQuestionPercent: curQuestion.answer === -1 ? 0 : curQuestion.answer, fadingText: false })
                    }, 300); // animation timing offset
                }
                break;

            default:
                break;
        }
    };

    return item ? (
        <div className='quiz user-choice'>
            {
                state === "start"
                    ?
                    <div className="quiz__start-container">
                        <CustomButton onClick={startQuizButton}>Start {item.name}</CustomButton>
                    </div>
                    :
                    <div className="quiz__container">
                        <div className="quiz__desc">
                            {item.description}
                        </div>
                        <div className={`quiz__question-text ${fadingText ? 'faded' : ''}`}>
                            {currentQuestion.question}
                        </div>
                        {currentQuestion.standard ?
                            <div className={`quiz__question-text quiz__question-subtext ${fadingText ? 'faded' : ''}`}>
                                Choose {currentQuestion.max_choices} {`answer${currentQuestion.max_choices > 1 ? 's' : ''}`}. Currently selected: {currentQuestion.total_selected}
                            </div> : null}
                        <div className={`quiz__answers-container ${fadingText ? 'faded' : ''}`}>
                            <QuizAnswersGroup handleItemClick={saveAnswerHandler} items={currentQuestion.answers} selected={currentQuestionAnswers} />
                        </div>

                        {(calcCorrectAnswersInQuestion()) ?
                            <React.Fragment>
                                {currentQuestion.feedback ?
                                    <div className={`quiz__question-feedback quiz__question-feedback-user-choice  ${(calcCorrectAnswersInQuestion() && !fadingText && currentQuestion.feedback) ? 'show' : ''}`}>
                                        {currentQuestion.feedback}
                                    </div>
                                    : null
                                }
                                <div className={`quiz__controls ${(calcCorrectAnswersInQuestion() && !fadingText) ? 'show' : ''}`}>
                                    <CustomButton className={`custom-button ${item.questions[currentQuestionIndex - 1] && !fadingText ? '' : 'hided'}`} disabled={!item.questions[currentQuestionIndex - 1]} onClick={() => handleChangeQuestion('prev')}>Prev</CustomButton>
                                    <CustomButton className={`custom-button ${item.questions[currentQuestionIndex + 1] && !fadingText ? '' : 'hided'}`} disabled={!item.questions[currentQuestionIndex + 1]} onClick={() => handleChangeQuestion('next')}>Next</CustomButton>
                                </div>
                            </React.Fragment>
                            : null}


                    </div>
            }


        </div>
    ) : null
}

export default UserChoice;