import React, { useState } from "react";
import './homepage.styles.scss';
import { connect } from 'react-redux';

import SignUp from '../../components/forms/sign-up/sign-up.component';
import SignIn from '../../components/forms/sign-in/sign-in.component'
import { selectUserProcessing,selectHomepageConfig } from '../../redux/user/user.selectors';
import { selectFeatures } from '../../redux/access-codes/access-codes.selectors';
import { createStructuredSelector } from 'reselect';
import Logo from "../../assets/app_svg_logo.svg";
import CustomButton from "../../components/forms/custom-button/custom-button.component"

const HomePage = ({ processing, features,homepageConfig }) => {

  const localeStateInit = {
    state: 'welcome',
  };

  const [localeState, setLocaleState] = useState(localeStateInit);

  const {
    state,
  } = localeState;

  const handleChangeState = (state) => {
    setLocaleState({ ...localeState, state: state })
  };

  return (
    <div className="homepage">
      <br/>
      <img className="collaboration-app-homepage-logo" src={Logo} alt="collaboration-app-homepage" onClick={()=>handleChangeState('welcome')} />
      <div className={`middle_container ${state==='signup'?"signup":""} ${state==='signin'?"signin":""}`}>
        {state==='welcome' ? (
          <div className="welcome-container">
            <div className="subtitle">WELCOME TO</div>
            <div className="title">The Collaborative Way</div>
            <div className='description'  style={{ whiteSpace: "pre-line" }} >{homepageConfig.text?.split("<br>").join("\n")}</div>
            <div className="blacklisted-modal__controls">
              <CustomButton
                onClick={()=>handleChangeState('signup')}
                inverted
              >
                Register
            </CustomButton>
            <button onClick={()=>handleChangeState('signin')} className='signin-link'>Login</button>
            </div>
          </div>
        ) : state==='signup' ?
          (
            <div className='signup-container'>
              <SignUp agreementText={homepageConfig.agreement_text} />
            </div>
          ):
          <div className='signin-container'>
              <SignIn />
          </div>
          }

      </div>

    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  processing: selectUserProcessing,
  features: selectFeatures,
  homepageConfig:selectHomepageConfig
});


export default connect(mapStateToProps)(HomePage);
