import AccessCodesTypes from "./access-codes.types";

const INITIAL_STATE = {
  processing: false,
  error: "",
  features: [],
  resources: [],
  links: [],
  annual_discount: null,
  coupon: 0,
  coupon_discount: null,
  success: 0,
  accessCodesList: []
};

const accessCodesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AccessCodesTypes.GET_ACCESS_CODE_CONFIG_START:
      return {
        ...state,
        processing: true,
        error: ""
      };
    case AccessCodesTypes.CHECK_ACCESS_CODE_COUPON_START:
      return {
        ...state,
        coupon: action.payload,
        processing: true,
        error: ""
      };
    case AccessCodesTypes.GET_ACCESS_CODE_CONFIG_SUCCESS:
      return {
        ...state,
        annual_discount: action.payload.annual_discount,
        features: action.payload.features,
        processing: false,
        error: ""
      };
    case AccessCodesTypes.CHECK_ACCESS_CODE_COUPON_SUCCESS:
      return {
        ...state,
        coupon_discount: action.payload,
        processing: false,
        error: ""
      };

    case AccessCodesTypes.GET_ACCESS_CODE_CONFIG_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload
      };

    case AccessCodesTypes.CHECK_ACCESS_CODE_COUPON_ERROR:
      return {
        ...state,
        coupon: "",
        processing: false,
        coupon_discount: null,
        error: action.payload
      };

    case AccessCodesTypes.GET_ACCESS_CODE_FEATURES_START:
      return {
        ...state,
        processing: true,
        error: ""
      };
    case AccessCodesTypes.GET_ACCESS_CODE_FEATURES_SUCCESS:
      return {
        ...state,
        features: action.payload,
        processing: false,
        error: ""
      };

    case AccessCodesTypes.GET_ACCESS_CODE_FEATURES_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload
      };

      case AccessCodesTypes.GET_ACCESS_CODE_RESOURCES_START:
        return {
          ...state
        };
        
      case AccessCodesTypes.GET_ACCESS_CODE_RESOURCES_SUCCESS:
        const {resources,links} = action.payload
        return {
          ...state,
          resources: resources,
          links:links
        };
  
      case AccessCodesTypes.GET_ACCESS_CODE_RESOURCES_ERROR:
        return {
          ...state,
          error: action.payload
        };

    case AccessCodesTypes.CREATE_ACCESS_CODE_START:
      return {
        ...state,
        processing: true,
        error: "",
        success: 0
      };
    case AccessCodesTypes.CREATE_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        processing: false,
        error: "",
        annual_discount: null,
        coupon: 0,
        coupon_discount: null,
        success: action.payload
      };

    case AccessCodesTypes.CREATE_ACCESS_CODE_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload
      };

    case AccessCodesTypes.GET_ACCESS_CODE_START:
      return {
        ...state,
        processing: true,
        error: ""
      };
    case AccessCodesTypes.GET_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        processing: false,
        error: "",
        accessCodesList: [action.payload, ...state.accessCodesList]
      };

    case AccessCodesTypes.GET_ACCESS_CODE_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload
      };

    case AccessCodesTypes.EDIT_ACCESS_CODE_START:
      return {
        ...state,
        processing: true,
        error: ""
      };
    case AccessCodesTypes.EDIT_ACCESS_CODE_SUCCESS:
      return {
        ...state,
        processing: false,
        error: ""
      };

    case AccessCodesTypes.EDIT_ACCESS_CODE_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload
      };

    case AccessCodesTypes.LIST_ACCESS_CODES_START:
      return {
        ...state,
        processing: true,
        error: "",
        accessCodesList: []
      };
    case AccessCodesTypes.LIST_ACCESS_CODES_SUCCESS:
      return {
        ...state,
        processing: false,
        error: "",
        accessCodesList: action.payload
      };

    case AccessCodesTypes.LIST_ACCESS_CODES_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload
      };

    case AccessCodesTypes.GET_ACCESS_CODE_STATS_START:
      return {
        ...state,
        // processing: true,
        error: ""
      };
    case AccessCodesTypes.GET_ACCESS_CODE_STATS_SUCCESS:
      const { id, stats } = action.payload;

      const newAccessCodesList = state.accessCodesList.map(accessCode => {
        if (id == accessCode.id) return { ...accessCode, stats: stats };
        return accessCode;
      });

      return {
        ...state,
        // processing: false,
        error: "",
        accessCodesList: newAccessCodesList
      };

    case AccessCodesTypes.GET_ACCESS_CODE_STATS_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload
      };

    case AccessCodesTypes.GET_INVITE_PDF_START:
      return {
        ...state,
        processing: true,
        error: ""
      };
    case AccessCodesTypes.GET_INVITE_PDF_SUCCESS:
      return {
        ...state,
        processing: false,
        error: "",
      };
    case AccessCodesTypes.GET_INVITE_PDF_ERROR:
      return {
        ...state,
        processing: false,
        error: action.payload
      };

    default:
      return state;
  }
};

export default accessCodesReducer;
