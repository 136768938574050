import React, { useRef, useState, useEffect } from "react";

import "./simple-video-player.styles.scss";

const SimpleVideoPlayer = ({ src, poster,startTime, setTime,autoplay, startPercent,toStartIfEnd, setPercent, percentCallback, ...otherProps }) => {
  const localStateInit = {
    playActive: 0,
    percent:-1,
    lastCurrentTime:-1,
  };

  const [localState, setLocalState] = useState(localStateInit);

  const { playActive,percent } = localState;

  useEffect(() => {handlePlayVideo()}, []);



  const vidRef = useRef(null);


  const handleLoadVideo = (event) => {
    if (startTime)
      vidRef.current.currentTime = startTime;
    if (startPercent)
      vidRef.current.currentTime =  Math.round(vidRef.current.duration*parseFloat(startPercent,10)/100)
    
      if ((toStartIfEnd)&&((startPercent===99)||(startPercent===100)))
      setTimeout(()=>{
          vidRef.current.currentTime = 0
      },1000)
    
    
  }

  const handlePlayVideo = (event) => {
    // if (vidRef.current.paused())
    // console.log('POKO',event,vidRef.current.paused)
    const newPercent = Math.round(vidRef.current.currentTime/vidRef.current.duration*100)
    if (vidRef.current.paused&&newPercent&&newPercent!==percent){//if paused then store percent   
      setLocalState({ ...localState, percent: newPercent});
      if (percentCallback)
        percentCallback(newPercent)
      }
    setLocalState({ ...localState, playActive: vidRef.current.paused });
  };
  const handleProgressVideo = (event) => {
    const newPercent = Math.round(vidRef.current.currentTime/vidRef.current.duration*100)
    
    const calcDiff = Math.abs(Math.round(vidRef.current.duration*parseFloat(percent,10)/100)-Math.round(vidRef.current.duration*parseFloat(newPercent,10)/100))
    const magicVar = (1/vidRef.current.duration*60);
    // console.log('video calc',calcDiff,magicVar,newPercent,vidRef.current.duration,vidRef.current.currentTime)
    if ((newPercent!==percent)&&((calcDiff>magicVar)||newPercent===100)){
      setLocalState({ ...localState, percent: newPercent});
      if (percentCallback)
        percentCallback(newPercent)
    }
    
  };

  const handleSeekedVideo = (event) => {//catch if seek at start
    const newPercent = Math.round(vidRef.current.currentTime/vidRef.current.duration*100)
    if (newPercent!==percent){
      setLocalState({ ...localState, percent: newPercent});
      if (percentCallback)
        percentCallback(newPercent)
    }
  };

  const handleClickOnVideo = (event) => {
    // console.log('video prob',vidRef.current.paused);
    // vidRef.current.paused?vidRef.current.play():vidRef.current.pause()
    // if (vidRef.current.paused){//if paused then store percent
    // const newPercent = Math.round(vidRef.current.currentTime/vidRef.current.duration*100)
    // setLocalState({ ...localState, percent: newPercent});
    // if (percentCallback)
    //   percentCallback(newPercent)

    // }
  };

  return (
    <div className="simple-video-player" onClick={handleClickOnVideo}>
      <video
        ref={vidRef}
        onPlay={handlePlayVideo}
        onPause={handlePlayVideo}
        onTimeUpdate={handleProgressVideo}
        onSeeked={handleSeekedVideo}
        onLoadedData={handleLoadVideo}
        autoPlay={autoplay?true:false}
        
        className="simple-video-player__video"
        poster={poster}
        src={`${src}#t=0.001`}
        {...otherProps}
        controls
        controlsList="nodownload"
        
      />
      <div
        className={`simple-video-player__playpause ${
          playActive ? "active" : null
        }`}
      ></div>
    </div>
  );
};

export default SimpleVideoPlayer;
