import {
  METHOD_POST,
  METHOD_GET,
  METHOD_PUT,
} from "./methods";

export const endpoints = {
  getFeatures: {
    url: "/access_code/features",
    method: METHOD_GET,
    jwt: false
  },
  getResources: {
    url: "/access_code/resources",
    method: METHOD_GET,
    jwt: true
  },
  getAccessCodeConfig: {
    url: "/access_code/configuration",
    method: METHOD_GET,
    jwt: true
  },
  checkCoupon: {
    url: "/access_code/check_coupon",
    method: METHOD_POST,
    jwt: true
  },
  createAccessCode: {
    url: "/access_codes",
    method: METHOD_POST,
    jwt: true
  },
  getAccessCodesList: {
    url: "/access_codes",
    method: METHOD_GET,
    jwt: true
  },
  editAccessCode: (id)=>({
    url: `/access_codes/${id}`,
    method: METHOD_PUT,
    jwt: true
  }),
  getAccessCode: (id)=>({
    url: `/access_codes/${id}`,
    method: METHOD_GET,
    jwt: true
  }),
  getInvitePdf: (id)=>({
    url: `/access_codes/${id}/invite_pdf`,
    method: METHOD_GET,
    jwt: true
  }),
  getAccessCodeStats: (id)=>({
    url: `/access_codes/${id}/stats`,
    method: METHOD_GET,
    jwt: true
  })
};
