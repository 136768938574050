import React from 'react';


const NotificationsItem = ({text,type, show}) => {

    let notyPretty = "";
    try {
      const jsonObj = JSON.parse(text);
      // console.log('objects',jsonObj)
      notyPretty = Object.entries(jsonObj);
    } catch (e) {
        notyPretty = text;
    }

    if (!notyPretty) return null;
    if (Array.isArray(notyPretty))
        return (<div className={`${type} notifications-item ${show?'show':null}`}>
            {notyPretty.map(item => (
                <div className="notification-label-small" key={item[0]}>
                  {item[0]}: {item[1]}
                </div>
              ))}
            </div>)
    else
        return (
        <div className={`${type} notifications-item ${show?'show':null}`}>
            {notyPretty}
        </div>
)};

export default NotificationsItem;