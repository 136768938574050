import React from 'react';

import {SortableElement} from 'react-sortable-hoc';
import './select-bubble-sortable-item.styles.scss';


const SelectBubbleSortableItem = SortableElement(({name , ...otherProps}) => (
    <div className="select-bubble-sortable-item" {...otherProps}>
        <div className='select-bubble-sortable-item__name'>
            {name}
        </div>
    </div>
));

export default SelectBubbleSortableItem;