import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

import arrayMove from 'array-move';

import { selectCurrentSectionById, selectCurrentSessionById } from '../../redux/user/user.selectors.js'
import { getConfigStart } from '../../redux/user/user.actions.js'
import CollabBreadcrumb from '../../components/collab-breadcrumb/collab-breadcrumb.component'
import SectionItemGroup from '../../components/section-item-group/section-item-group.component'
import {prepareSessions as _prepareSessions} from "../../utils/sessions.utils.js"

import "./section.styles.scss";

const SectionPage = ({ section, accessCodeId, getConfig, history, ...otherProps }) => {


  const localStateInit = {
    modalValue: {},
    valueModalShow: false,
  };
  let { id } = useParams();
  const [localState, setLocalState] = useState(localStateInit);

  const {
    valueModalShow,
    modalValue
  } = localState;

  const prepareSessions = (sessions)=>{
      return _prepareSessions(sessions)
  }

  const handleSessionClick = (event,id)=>{
      const session = prepareSessions(section.sessions).find(item => item.id===id)
      if (session.current==='true' || session.completed)
        history.push(`/session/${id}`)
  }

  useEffect(() => {
    if (!section) {
      getConfig()
    }
    
  }, []);

  return (
    <div className="section-page">
      <span className="breadcrumb">
        <CollabBreadcrumb parentLink="/dashboard" parentName={section.course_name} currentName={section.name} />
      </span>
      <div className="section-page__container">
        {section ?
          <SectionItemGroup itsbookmark={false} handleItemClick={handleSessionClick} items={prepareSessions(section.sessions)} />
          : null}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    section: selectCurrentSectionById(ownProps.match.params.id)
  });

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(getConfigStart()),
});
export default connect(mapStateToProps, mapDispatchToProps)(SectionPage);
