import {all, call} from 'redux-saga/effects';
import userSaga from './user/user.sagas';
import notificationsSaga from './notifications/notifications.sagas';
import accessCodesSaga from './access-codes/access-codes.sagas'

export default function* rootSaga(){
    yield all([
        call(userSaga),
        call(notificationsSaga),
        call(accessCodesSaga),
    ])
};
