import React, { useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectNotifications } from '../../redux/notifications/notifications.selectors';
import {
    TransitionGroup,
    CSSTransition
} from "react-transition-group";


import NotificationsStackItem from './notifications-stack-item.component';
import './notifications-stack.styles.scss';



const NotificationsStack = ({ notifications, closed, clickOpen,mentionReplace, clickMainHandle,clickMainClose, clickClose, clickCloseAll, avatarPrefix }) => {
    const useOutsideAlerter = (ref)=>{
        useEffect(() => {
            /**
             * Alert if clicked on outside of element
             */
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    clickMainClose()
                }
            }

            // Bind the event listener
            document.addEventListener("click", handleClickOutside,true);
            document.addEventListener("ontouchstart", handleClickOutside,true);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("click", handleClickOutside,true);
                document.removeEventListener("ontouchstart", handleClickOutside,true);
            };
        }, [ref, clickMainClose]);
    }

    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const prepareNotifications = (notifications, max) => {
        let newNoty = [...notifications]
        if (notifications.length > max) {
            newNoty = notifications.slice(notifications.length - max)
            newNoty.unshift({ 'id': 'overkill', 'text': 'Another ' + (notifications.length - max) + ' notification(s)' })
        }
        return newNoty
    }

    return (
        <div ref={wrapperRef} onClick={clickMainHandle} className={`notifications-stack ${closed ? "closed" : ""}`}>
            {notifications.length>0?<div className="notifications-stack__badge">{notifications.length}</div>:null}
            <div className="notifications-stack__items">
                {notifications.length > 1 ?
                    <div className="notifications-stack__clear-all">
                        <div onClick={clickCloseAll} className="notifications-stack__clear-all-button">
                            Clear All
                            <div className="notifications-stack__clear-all-icon">
                                <i className="fas fa-times"></i>
                            </div>
                        </div>
                    </div>
                    :
                    null
                }


                <TransitionGroup>
                    {
                        prepareNotifications(notifications, 5).map((item) => (
                            <CSSTransition
                                key={item.id}
                                classNames="notifications-stack-item__transition"
                                timeout={1000}
                            >
                                <NotificationsStackItem mentionReplace={mentionReplace} clickClose={clickClose} clickOpen={clickOpen} avatarPrefix={avatarPrefix} key={item.id} {...item} />
                            </CSSTransition>
                        ))
                    }
                </TransitionGroup>
            </div>
        </div>
    )
}

const mapStateToProps = createStructuredSelector({
    //   notifications:selectNotifications
});


export default connect(mapStateToProps)(NotificationsStack);