import { v4 as uuidv4 } from 'uuid';


import {
  takeEvery,
  call,
  put,
  all,
  delay
} from "redux-saga/effects";

import NotificationsActionTypes from "./notifications.types";
import {
  notificationAdd,
  notificationRemove,
  notificationShow,
  notificationUnShow
} from "./notifications.actions";

export function* notificationAddAsync(action) {
  const {text, type,duration} = action.payload;
  const id = uuidv4();

  yield put(notificationAdd(id, text, type,duration));
  yield delay(100);
  yield put(notificationShow(id));
  yield delay(duration);
  yield put(notificationUnShow(id));
  yield delay(1000);
  yield put(notificationRemove(id));

}

export function* notificationAddSaga() {
  yield takeEvery(NotificationsActionTypes.NOTIFICATION_ADD_START, notificationAddAsync);
}

export default function* notificationsSaga() {
  yield all([
    call(notificationAddSaga),
  ]);
}
