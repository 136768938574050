import React, { useEffect, useState,useRef, useCallback } from 'react';

import { MentionsInput, Mention } from 'react-mentions'
import ChatMessage from "./chat-message.component"
import ChatTextarea from "./chat-textarea.component"
import moment from 'moment'
import { parse } from '../../utils/bb-code.utils'
import SimpleVideoPlayer from "../simple-video-player/simple-video-player.component"

import './chat.styles.scss'



const tags = {
    'video': (params, childs) => (<div className='spk-chat__message-video'>{<SimpleVideoPlayer src={childs[0].props.children} />}</div>),
    'url': (params, childs) => (<a className='spk-chat__message-link' rel="noopener noreferrer" target="_blank" href={params.url}>{childs}</a>),
    'img': (params, childs) => {return (<img className='spk-chat__message-image' alt='img' src={childs[0].props.children} />)},
    'h1': (params, childs) => (<h1>{childs}</h1>),
    'h2': (params, childs) => (<h2>{childs}</h2>),
    'b': (params, childs) => (<b>{childs}</b>),
    'list': (params, childs) => (params.list ? (<ol>{childs}</ol>) : (<ul>{childs}</ul>)),
    '*': (params, childs) => (<li>{childs}</li>),
    'code': (params, childs) => (<code>{childs}</code>),
    'sup': (params, childs) => (<sup>{childs}</sup>),
    'sub': (params, childs) => (<sub>{childs}</sub>),
    's': (params, childs) => (<s>{childs}</s>),
    'u': (params, childs) => (<u>{childs}</u>),
    'i': (params, childs) => (<span style={{ 'fontStyle': 'italic' }}>{childs}</span>),
}

const MyTextareaMemo = React.memo(props => {
    return <input {...props}/>;
  });

const Chat = ({ chat,title, users, sendTextHandle, closeButtonHandle, ...otherProps }) => {
    const localStateInit = {
        text: "",
    };
    const [localState, setLocalState] = useState(localStateInit);

    const {
        text
    } = localState;
    const bottomChatRef = useRef(null);
    const textChange = (event, newValue, newPlainTextValue, mentions) => {
        setLocalState({ ...localState, text: newValue })
    }

    const prepareUsers = (users)=>{
        if (!(users.find(user=>user.id==='all')))
            users.unshift({'id':'all','display':'All'})
        return users
    }

    const onEnterPress = (e) => {

        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            sendText()
        }
    }

    const sendText = (direct_text) => {
        let text_value = ""
        if (direct_text)
            text_value = direct_text
        else 
            text_value = text
        if (!text_value)
            return false;
       
        if (sendTextHandle)
            sendTextHandle(text_value)

        textChange(false, '')
    }

    const formatTextWithMentions = (text) => {

        let parts = text.split(/\[@\[(.*?)\]\((.*?)\)\]/g)

        for (let i = 1; i < parts.length; i += 3) {
            parts[i] = (
                <span className='spk-chat__mention' data-user={parts[i + 1]} key={i}>
                    {parts[i]}
                </span>
            )
            
            parts[i + 1] = null;
        }
        for (let i = 0; i < parts.length; i++) {
            // console.log("TUUUUTTUUU",parts[i])
            if (typeof parts[i]==="string")
                parts[i]=parse(parts[i].replace(/<[^>]+>/g, ''), tags)
        }
        

        return <React.Fragment>{parts}</React.Fragment>

    }

    const prepareChatMessageObj = (message) => {
        return {
            'text': formatTextWithMentions(message.text),
            'date': message.date,
            'name': message.user_name,
            'coach': message.coach,
            'avatar': process.env.REACT_APP_URL + "" + message.user_avatar
        }
    }
    const scrollToBottom = () => {
        // bottomChatRef.current.scrollIntoView({
        //     behavior: "smooth",
        //     block: "nearest",
        //     inline: "start"
        //   });
        if (bottomChatRef&&bottomChatRef.current)
            bottomChatRef.current.parentNode.parentNode.scrollTop = bottomChatRef.current.offsetTop;
         
    }

    useEffect(() => {
        setTimeout(()=>{if (chat&&chat.length>4) scrollToBottom();},1000)
    }, [chat]);

    // '[@[__display__](__id__)]'
    const prepareChatMessages = (chat) => {
        if (!chat)
            return [];

        let last_separator = false;

        const _messages = []
        chat.forEach((message, index) => {

            if ((!last_separator) || (moment(message.date).diff(moment(last_separator), 'hours') > 24)) {
                _messages.push({
                    'type': 'separator-date',
                    'user_id': '',
                    'messages': [{ 'date': message.date }],
                })
                last_separator = message.date
            }

            let last_element = { 'type': false, 'user_id': 0, 'messages': [] }
            if (_messages.length && _messages[_messages.length - 1])
                last_element = _messages[_messages.length - 1]

            if ((last_element.user_id === message.user_id) && (moment(message.date).diff(moment(last_element.messages[last_element.messages.length - 1].date), 'minutes') < 30))
                _messages[_messages.length - 1].messages.push(prepareChatMessageObj(message))
            else
                _messages.push({
                    'type': message.who,
                    'bot': message.bot,
                    'user_id': message.user_id,
                    'messages': [prepareChatMessageObj(message)],
                })


        });

        return _messages
    }

    

    return (
        <div className="spk-chat" >
            <div className="spk-chat__container">
                <div className="spk-chat__main-panel">
                    <div className="spk-chat__header">
                    {title? <div className="spk-chat__title">{title}</div>:<div className="spk-chat__title">Chat</div>}
                        {closeButtonHandle ? <div onClick={closeButtonHandle} className="spk-chat__close-button"><svg width="30" height="30" viewBox="0 0 30 30" className="gg"><path d="M18.13 6.11l-5.61 5.61-5.6-5.61-.81.8 5.61 5.61-5.61 5.61.8.8 5.61-5.6 5.61 5.6.8-.8-5.6-5.6 5.6-5.62"></path></svg></div> : null}
                    </div>


                    <div className="spk-chat__list">
                        <div className="spk-chat__ul">
                            {prepareChatMessages(chat).map((item, index) => (<ChatMessage key={index} messages={item.messages} type={item.type} bot={item.bot} />))}
                            <div style={{ float: "left", clear: "both" }}
                                ref={bottomChatRef}>
                            </div>
                        </div>
                    </div>

                    <div className="spk-chat__input-flat">
                        <div className="spk-chat__input-flat-wrapper">
                            <div className="spk-chat__input-flat--textarea-wrapper">
                                    <ChatTextarea users={prepareUsers(users)} sendTextHandle={(text)=>sendText(text)}/>
                                    {/* <div className="rta  spk-chat__textarea">
                                    <MentionsInput onKeyDown={onEnterPress} placeholder="Type your message" allowSuggestionsAboveCursor={true} className="mentions" value={text} onChange={textChange}>
                                        <Mention
                                            className="mentions__mention0"
                                            trigger="@"
                                            appendSpaceOnAdd={true}
                                            data={prepareUsers(users)}
                                            markup={'[@[__display__](__id__)]'}
                                            renderSuggestion={(
                                                suggestion,
                                                search,
                                                highlightedDisplay,
                                                index,
                                                focused
                                            ) => (
                                                    <div className={`user ${focused ? 'focused' : ''}`}>
                                                        {highlightedDisplay}
                                                    </div>
                                                )}
                                            style={{ backgroundColor: '#3cc4f1' }}
                                        />
                                    </MentionsInput>
                                </div>
                                <button className="spk-chat__send-button" onClick={sendText}>
                                    <svg width="18" height="17" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg"><title>Send</title><path d="M0 17.015l17.333-8.508L0 0v6.617l12.417 1.89L0 10.397z" fillRule="evenodd" fill="#006cff"></path></svg>
                                </button> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Chat;