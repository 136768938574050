 const UserActionTypes = {
    LOGIN_USER_START : "LOGIN_USER_START",
    LOGIN_USER_SUCCESS : "LOGIN_USER_SUCCESS",
    LOGIN_USER_ERROR : "LOGIN_USER_ERROR",
    SIGNUP_USER_START : "SIGNUP_USER_START",
    SIGNUP_USER_SUCCESS : "SIGNUP_USER_SUCCESS",
    SIGNUP_USER_ERROR : "SIGNUP_USER_ERROR",
    SIGNOUT_USER_START : "SIGNOUT_USER_START",
    SIGNOUT_USER_SUCCESS : "SIGNOUT_USER_SUCCESS",
    SIGNOUT_USER_ERROR : "SIGNOUT_USER_ERROR",
    GET_USER_DATA_START : "GET_USER_DATA_START",
    GET_USER_DATA_SUCCESS : "GET_USER_DATA_SUCCESS",
    GET_USER_DATA_ERROR : "GET_USER_DATA_ERROR",
    SAVE_USER_DATA_START : "SAVE_USER_DATA_START",
    SAVE_USER_DATA_SUCCESS : "SAVE_USER_DATA_SUCCESS",
    SAVE_USER_DATA_ERROR : "SAVE_USER_DATA_ERROR",
    CHECK_TOKEN_AND_LOGGED_IN_START:"CHECK_TOKEN_AND_LOGGED_IN_START",
    CHECK_TOKEN_AND_LOGGED_IN_SUCCESS:"CHECK_TOKEN_AND_LOGGED_IN_SUCCESS",
    CHECK_TOKEN_AND_LOGGED_IN_ERROR:"CHECK_TOKEN_AND_LOGGED_IN_ERROR",
    GET_COUNTRIES_START : "GET_COUNTRIES_START",
    GET_COUNTRIES_SUCCESS : "GET_COUNTRIES_SUCCESS",
    GET_COUNTRIES_ERROR : "GET_COUNTRIES_ERROR",
    GET_HOMEPAGE_CONFIG_START : "GET_HOMEPAGE_CONFIG_START",
    GET_HOMEPAGE_CONFIG_SUCCESS : "GET_HOMEPAGE_CONFIG_SUCCESS",
    GET_HOMEPAGE_CONFIG_ERROR : "GET_HOMEPAGE_CONFIG_ERROR",
    CHECK_COUPON_START : "CHECK_COUPON_START",
    CHECK_COUPON_SUCCESS : "CHECK_COUPON_SUCCESS",
    CHECK_COUPON_ERROR : "CHECK_COUPON_ERROR",
    GET_CONFIG_START : "GET_CONFIG_START",
    GET_CONFIG_SUCCESS : "GET_CONFIG_SUCCESS",
    GET_CONFIG_ERROR : "GET_CONFIG_ERROR",
    SAVE_VALUE_START : "SAVE_VALUE_START",
    SAVE_VALUE_SUCCESS : "SAVE_VALUE_SUCCESS",
    SAVE_VALUE_ERROR : "SAVE_VALUE_ERROR",
    SAVE_VALUE_TRACK_START : "SAVE_VALUE_TRACK_START",
    SAVE_VALUE_TRACK_SUCCESS : "SAVE_VALUE_TRACK_SUCCESS",
    SAVE_VALUE_TRACK_ERROR : "SAVE_VALUE_TRACK_ERROR",
    SET_CURRENT_ACCESS_CODE:"SET_CURRENT_ACCESS_CODE",
    SET_PUSH_REGISTRATION_ID:"SET_PUSH_REGISTRATION_ID",
    SAVE_CURRENT_ACCESS_CODE_SUCCESS : "SAVE_CURRENT_ACCESS_CODE_SUCCESS",
    SAVE_CURRENT_ACCESS_CODE_ERROR : "SAVE_CURRENT_ACCESS_CODE_ERROR",
    SAVE_CURRENT_ACCESS_CODE_START:"SAVE_CURRENT_ACCESS_CODE_START",
    ADD_ACCESS_CODE_SUCCESS : "ADD_ACCESS_CODE_SUCCESS",
    ADD_ACCESS_CODE_ERROR : "ADD_ACCESS_CODE_ERROR",
    ADD_ACCESS_CODE_START:"ADD_ACCESS_CODE_START",
    DELETE_ACCESS_CODE_SUCCESS : "DELETE_ACCESS_CODE_SUCCESS",
    DELETE_ACCESS_CODE_ERROR : "DELETE_ACCESS_CODE_ERROR",
    DELETE_ACCESS_CODE_START:"DELETE_ACCESS_CODE_START",
    DELETE_MY_ACCOUNT_SUCCESS : "DELETE_MY_ACCOUNT_SUCCESS",
    DELETE_MY_ACCOUNT_ERROR : "DELETE_MY_ACCOUNT_ERROR",
    DELETE_MY_ACCOUNT_START:"DELETE_MY_ACCOUNT_START",
    SHOW_HELP_MODAL:"SHOW_HELP_MODAL",
    SHOW_COMPLETE_MODAL:"SHOW_COMPLETE_MODAL",
    SET_COMPLETE_MODAL:"SET_COMPLETE_MODAL",
    SET_REDIRECT_URL:"SET_REDIRECT_URL",
    SEND_SESSION_MESSAGE_SUCCESS : "SEND_SESSION_MESSAGE_SUCCESS",
    SEND_SESSION_MESSAGE_ERROR : "SEND_SESSION_MESSAGE_ERROR",
    SEND_SESSION_MESSAGE_START:"SEND_SESSION_MESSAGE_START",
    SEND_FORUM_MESSAGE_SUCCESS : "SEND_FORUM_MESSAGE_SUCCESS",
    SEND_FORUM_MESSAGE_ERROR : "SEND_FORUM_MESSAGE_ERROR",
    SEND_FORUM_MESSAGE_START:"SEND_FORUM_MESSAGE_START",
    GET_SESSION_CHAT_SUCCESS : "GET_SESSION_CHAT_SUCCESS",
    GET_SESSION_CHAT_ERROR : "GET_SESSION_CHAT_ERROR",
    GET_SESSION_CHAT_START:"GET_SESSION_CHAT_START",
    SAVE_COMPLETED_ITEM_SUCCESS : "SAVE_COMPLETED_ITEM_SUCCESS",
    SAVE_COMPLETED_ITEM_ERROR : "SAVE_COMPLETED_ITEM_ERROR",
    SAVE_COMPLETED_ITEM_START:"SAVE_COMPLETED_ITEM_START",
    TOGGLE_SESSION_BOOKMARK_SUCCESS : "TOGGLE_SESSION_BOOKMARK_SUCCESS",
    TOGGLE_SESSION_BOOKMARK_ERROR : "TOGGLE_SESSION_BOOKMARK_ERROR",
    TOGGLE_SESSION_BOOKMARK_START:"TOGGLE_SESSION_BOOKMARK_START",

    SAVE_QUESTION_ANSWER_SUCCESS : "SAVE_QUESTION_ANSWER_SUCCESS",
    SAVE_QUESTION_ANSWER_ERROR : "SAVE_QUESTION_ANSWER_ERROR",
    SAVE_QUESTION_ANSWER_START:"SAVE_QUESTION_ANSWER_START",

    SAVE_SESSION_ITEM_PROGRESS_SUCCESS : "SAVE_SESSION_ITEM_PROGRESS_SUCCESS",
    SAVE_SESSION_ITEM_PROGRESS_ERROR : "SAVE_SESSION_ITEM_PROGRESS_ERROR",
    SAVE_SESSION_ITEM_PROGRESS_START:"SAVE_SESSION_ITEM_PROGRESS_START",

    GET_ASSESSMENT_RESULT_SUCCESS : "GET_ASSESSMENT_RESULT_SUCCESS",
    GET_ASSESSMENT_RESULT_ERROR : "GET_ASSESSMENT_RESULT_ERROR",
    GET_ASSESSMENT_RESULT_START:"GET_ASSESSMENT_RESULT_START",

    SAVE_USER_CHOICE_QUESTION_ANSWER_SUCCESS : "SAVE_USER_CHOICE_QUESTION_ANSWER_SUCCESS",
    SAVE_USER_CHOICE_QUESTION_ANSWER_ERROR : "SAVE_USER_CHOICE_QUESTION_ANSWER_ERROR",
    SAVE_USER_CHOICE_QUESTION_ANSWER_START:"SAVE_USER_CHOICE_QUESTION_ANSWER_START",
    AVATAR_UPLOAD_SUCCESS : "AVATAR_UPLOAD_SUCCESS",
    AVATAR_UPLOAD_ERROR : "AVATAR_UPLOAD_ERROR",
    AVATAR_UPLOAD_START:"AVATAR_UPLOAD_START",
    AVATAR_DISCARD_SUCCESS : "AVATAR_DISCARD_SUCCESS",
    AVATAR_DISCARD_ERROR : "AVATAR_DISCARD_ERROR",
    AVATAR_DISCARD_START:"AVATAR_DISCARD_START",
    USER_NOTE_SAVE_SUCCESS : "USER_NOTE_SAVE_SUCCESS",
    USER_NOTE_SAVE_ERROR : "USER_NOTE_SAVE_ERROR",
    USER_NOTE_SAVE_START:"USER_NOTE_SAVE_START",
    USER_NOTE_FAVORITE_SUCCESS : "USER_NOTE_FAVORITE_SUCCESS",
    USER_NOTE_FAVORITE_ERROR : "USER_NOTE_FAVORITE_ERROR",
    USER_NOTE_FAVORITE_START:"USER_NOTE_FAVORITE_START",

    RESET_PASSWORD_CONFIRM_SUCCESS : "RESET_PASSWORD_CONFIRM_SUCCESS",
    RESET_PASSWORD_CONFIRM_ERROR : "RESET_PASSWORD_CONFIRM_ERROR",
    RESET_PASSWORD_CONFIRM_START:"RESET_PASSWORD_CONFIRM_START",

    RESET_PASSWORD_VALIDATE_SUCCESS : "RESET_PASSWORD_VALIDATE_SUCCESS",
    RESET_PASSWORD_VALIDATE_ERROR : "RESET_PASSWORD_VALIDATE_ERROR",
    RESET_PASSWORD_VALIDATE_START:"RESET_PASSWORD_VALIDATE_START",

    RESET_PASSWORD_REQUEST_SUCCESS : "RESET_PASSWORD_REQUEST_SUCCESS",
    RESET_PASSWORD_REQUEST_ERROR : "RESET_PASSWORD_REQUEST_ERROR",
    RESET_PASSWORD_REQUEST_START:"RESET_PASSWORD_REQUEST_START",

    GET_NOTIFICATIONS_SUCCESS : "GET_NOTIFICATIONS_SUCCESS",
    GET_NOTIFICATIONS_ERROR : "GET_NOTIFICATIONS_ERROR",
    GET_NOTIFICATIONS_START:"GET_NOTIFICATIONS_START",

    READ_NOTIFICATION_SUCCESS : "READ_NOTIFICATION_SUCCESS",
    READ_NOTIFICATION_ERROR : "READ_NOTIFICATION_ERROR",
    READ_NOTIFICATION_START:"READ_NOTIFICATION_START",

    
    
}

export default UserActionTypes;