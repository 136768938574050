import NotificationsActionTypes from './notifications.types'

export const notificationAddStart = (text,type,duration) => ({
    type: NotificationsActionTypes.NOTIFICATION_ADD_START,
    payload: {text, type,duration}
});

export const notificationAdd = (id,text,type,duration) => ({
    type: NotificationsActionTypes.NOTIFICATION_ADD,
    payload: {id,text, type,duration}
});

export const notificationShow = (id) => ({
    type: NotificationsActionTypes.NOTIFICATION_SHOW,
    payload: id
});

export const notificationUnShow = (id) => ({
    type: NotificationsActionTypes.NOTIFICATION_UNSHOW,
    payload: id
});

export const notificationRemove = (id) => ({
    type: NotificationsActionTypes.NOTIFICATION_REMOVE,
    payload: id
});
