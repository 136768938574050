import NotificationsActionTypes from './notifications.types'
import {makeShowForNotificationId,removeForNotificationId} from './notifications.utils';

const INITIAL_STATE = {
    notifications: [],
}

const notificationsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NotificationsActionTypes.NOTIFICATION_ADD_START:
            return {
                ...state          
            }
        case NotificationsActionTypes.NOTIFICATION_ADD:
            //const {noty_id,text,type,duration} = action.payload; 
            return {
                ...state,
                notifications:[...state.notifications,{...action.payload,show:null}],             
            }
        case NotificationsActionTypes.NOTIFICATION_SHOW:
            return {
                ...state,
                notifications:makeShowForNotificationId(state.notifications,action.payload,true),             
            }
        case NotificationsActionTypes.NOTIFICATION_UNSHOW:
            return {
                ...state,
                notifications:makeShowForNotificationId(state.notifications,action.payload,false),             
            }
        case NotificationsActionTypes.NOTIFICATION_REMOVE:
            return {
                ...state,
                notifications:removeForNotificationId(state.notifications,action.payload),             
            }
        default:
            return state;
    }
}

export default notificationsReducer;