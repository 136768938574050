export const prepareSessions = (sessions) => {
  return sessions.map((session, index) => {
    let _session = { ...session }
    if ((!session.completed && (sessions[index - 1] && sessions[index - 1].completed)) || (!session.completed && index === 0))
      _session['current'] = "true"
    return _session
  })
};

export const getDayCountForDelaySectionNew = (delay_date) => {
  if (!delay_date)
    return 0;
  const date1 = new Date(delay_date);
  const date2 = new Date();
  // To calculate the time difference of two dates 
  let Difference_In_Time = date1.getTime() - date2.getTime();

  // To calculate the no. of days between two dates 
  let Difference_In_Days = Math.ceil(Difference_In_Time / (1000 * 3600 * 24));

  if (Difference_In_Days <= 0)
    Difference_In_Days = 0;

  return Difference_In_Days
}

export const checkUserAccessForSession = (sections, session_id, section_id) => {
  if (!session_id || !section_id)
    return true;
    
  const section = sections.find(sec => sec.id === section_id)
  const sectionIndex = sections.findIndex(sec => sec.id === section_id)
  let canRedirect = true;
  let days = 0;
  // const session = prepareSessions(section.sessions).find(_item => _item.id === session_id)
  // console.log('!1111!',section,session_id,session)
  // return false;
  days = getDayCountForDelaySectionNew(section.delay);

  if ((days > 0) || (sections[sectionIndex - 1] && !sections[sectionIndex - 1].completed))
    canRedirect = false
  if (canRedirect) {
    const session = prepareSessions(section.sessions).find(_item => _item.id === session_id)
    
    if (session.current === 'true' || session.completed)
      return true
    else
      return false
  } else {
    return false
  }
};