import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { readNotificationStart, getNotificationsStart, getConfigStart } from '../../redux/user/user.actions';
import { selectUserCurrentAccessCode, selectCurrentNotificationTimestamp, selectCurrentNotifications, selectCurrentAccessCodeConfig } from '../../redux/user/user.selectors'

import { checkUserAccessForSession } from "../../utils/sessions.utils.js"


import CustomButton from '../../components/forms/custom-button/custom-button.component'
import ModalPopup from '../../components/forms/modal-popup/modal-popup.component'
import NotificationsStack from './notifications-stack.component';
import './notifications-stack.styles.scss';



const NotificationsStackManager = ({ show, config, getConfig, getNotifications, readNotification, currentAccessCode, currentNotificationTimestamp, currentNotifications }) => {
  const history = useHistory();
  const localStateInit = {
    openedStackNotifications: false,
    noAccessModalShow: false,
    notifications: []
  };
  const [localState, setLocalState] = useState(localStateInit);

  const {
    openedStackNotifications,
    notifications,
    noAccessModalShow
  } = localState;

  useEffect(() => {
    if (currentAccessCode) {
      getNotifications(currentNotificationTimestamp, currentAccessCode)
    }
  }, [currentAccessCode])

  useEffect(() => {
    let interval = setInterval(() => {
      if (currentAccessCode) {
        getNotifications(currentNotificationTimestamp, currentAccessCode)
      }
    }, 20000);
    return () => clearInterval(interval);
  }, [currentAccessCode, getNotifications, currentNotificationTimestamp])

  useEffect(() => {
    // alert('first load');
  }, [])

  useEffect(() => {
    setLocalState({ ...localState, notifications: currentNotifications })
  }, [currentNotifications])

  const openStackNotifications = (e) => {
    if (!localState.openedStackNotifications) {
      e.stopPropagation();
      setLocalState({ ...localState, openedStackNotifications: 1 })
    }
  }
  const closeStackNotifications = () => {
    setLocalState({ ...localState, openedStackNotifications: 0 })
  }
  const closeHandleNotification = (e, id) => {
    e.stopPropagation()
    readNotification(currentAccessCode, id)
    const newNoty = [...notifications].filter(item => id !== item.id)
    setLocalState({ ...localState, notifications: newNoty })
  }
  const openHandleNotification = (e, id) => {
    e.stopPropagation()

    if (id === 'overkill')
      return;

    if (!localState.openedStackNotifications && notifications.length !== 1) {
      setLocalState({ ...localState, openedStackNotifications: 1 })
      return;
    }
    readNotification(currentAccessCode, id)
    const noty_find = notifications.find(item => id === item.id)
    if (checkUserAccessForSession(config.course.sections, noty_find.session_id, noty_find.section_id))
      history.push(noty_find.link)
    else
      showHideNoAccessModal(true)

  }
  const closeAllHandleNotification = (e) => {
    e.stopPropagation()
    notifications.map(noty => readNotification(currentAccessCode, noty.id))
    const newNoty = []
    setLocalState({ ...localState, notifications: newNoty })
  }

  const showHideNoAccessModal = (show) => {
    setLocalState({ ...localState, noAccessModalShow: show });
  }

  return (
    <div className={`notifications-stack-manager ${show ? "show" : "hide"}`}>
      <NotificationsStack clickOpen={openHandleNotification} clickMainClose={closeStackNotifications} clickMainHandle={openStackNotifications} clickCloseAll={closeAllHandleNotification} clickClose={closeHandleNotification} closed={!openedStackNotifications} notifications={notifications} mentionReplace="\[@\[(.*?)\]\((.*?)\)\]" avatarPrefix={process.env.REACT_APP_URL} />
      <ModalPopup
        className="standard-modal"
        show={noAccessModalShow}
        handleOverlayClick={() => showHideNoAccessModal(false)}
      >
        <div className="standard-modal__title"></div>
        <div className="standard-modal__body">
          <div className="standard-modal__text">
            You don't have access to this content yet.
          </div>
        </div>
        <div className="value-modal__controls">
          <CustomButton
            onClick={() => showHideNoAccessModal(false)}
            inverted
          >
            Close
          </CustomButton>
        </div>

      </ModalPopup>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  getNotifications: (timestamp, access_code_id) => dispatch(getNotificationsStart(timestamp, access_code_id)),
  readNotification: (access_code_id, notification_id) => dispatch(readNotificationStart(access_code_id, notification_id)),
  getConfig: () => dispatch(getConfigStart()),
})

const mapStateToProps = createStructuredSelector({
  currentAccessCode: selectUserCurrentAccessCode,
  config: selectCurrentAccessCodeConfig,
  currentNotificationTimestamp: selectCurrentNotificationTimestamp,
  currentNotifications: selectCurrentNotifications,
});


export default connect(mapStateToProps, mapDispatchToProps)(NotificationsStackManager);