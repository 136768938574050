import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { fetchUserLoginStart } from "../../../redux/user/user.actions";
import { Link } from "react-router-dom";
import {
  selectUserProcessing,
  selectUserError
} from "../../../redux/user/user.selectors";

import FormInput from "../form-input/form-input.component";
import CustomButton from "../custom-button/custom-button.component";
import ErrorLabel from "../error-label/error-label.component";

import "./sign-in.styles.scss";

const SignIn = ({ fetchUserLogin, isLogging }) => {
  const history = useHistory();
 
  const userCredentialsState = {
    email: "",
    password: ""
  };

  const [userCredentials, setUserCredentials] = useState(userCredentialsState);

  const { email, password } = userCredentials;

  const handleSubmit = async event => {
    event.preventDefault();
    fetchUserLogin(email, password, history);
  };

  const handleChange = event => {
    const { value, name } = event.target;
    setUserCredentials({ ...userCredentials, [name]: value });
  };

  return (
    <div className="sign-in">
      <span className="title">Sign In</span>
      <form onSubmit={handleSubmit}>
    
        <div class='sign-in__email-outer'>
          <FormInput
            handleChange={handleChange}
            label="Email"
            name="email"
            type="email"
            value={email}
            required
          />
          <Link to="/reset_password"><div className="sign-in__forgot-password">Forgot Password?</div></Link>
        </div>
        
        <FormInput
          handleChange={handleChange}
          label="Password"
          name="password"
          type="password"
          value={password}
          required
        />
        <div className="buttons">
          <CustomButton inverted type="submit">
            Sign In
          </CustomButton>
        </div>
        <div className="footnote-signup">
          Lloyd Fickett & Associates, Inc. ⓒ 2022. All Rights Reserved. The Collaborative Way®
        </div>
      </form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchUserLogin: (login, password, history) =>
    dispatch(fetchUserLoginStart(login, password, history))
});

const mapStateToProps = createStructuredSelector({
  isLogging: selectUserProcessing,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
