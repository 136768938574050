import React, { useEffect, useState,useCallback } from "react";
import { useParams, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {compose} from 'redux';
import {getSessionById} from "../../utils/users.utils"


import { createStructuredSelector } from "reselect";

import { selectCurrentSessionById,selectCurrentAccessCodeConfig, selectCurrentAccessCodeTeamProgress,selectUserCurrentAccessCode } from '../../redux/user/user.selectors.js'
import { getConfigStart,getAssessmentResultStart,sendSessionMessageStart, toggleSessionBookmarkStart,saveCompletedItemStart,saveSessionItemProgressStart, saveQuestionAnswerStart, saveUserChoiceQuestionAnswerStart, saveUserNoteStart } from '../../redux/user/user.actions.js'
import CollabBreadcrumb from '../../components/collab-breadcrumb/collab-breadcrumb.component'
import SessionItem from '../../components/session-item/session-item.component'
import Chat from '../../components/chat/chat.component'
import moment from 'moment'


import "./session.styles.scss";

const SessionPage = ({ config,team, accessCodeId,saveSessionItemProgress,saveUserChoiceQuestionAnswer,getAssessmentResult, getConfig,sendMessage,saveQuestionAnswer, toggleBookmark,markAsComplete,saveUserNote, history, ...otherProps }) => {

  const assessmentResultReload = false;
  const localStateInit = {
    modalValue: {},
    valueModalShow: false,
    viewChat: false,
    change:true,
    session:false,
  };
  let { id,chat } = useParams();
  const [localState, setLocalState] = useState(localStateInit);

  const {
    valueModalShow,
    modalValue,
    viewChat,
    change,
    session,
  } = localState;

  const toggleViewChat = ()=>{
    setLocalState({...localState,viewChat:localState.viewChat^1})
}

const changeSessionDelay = (id)=>{
  if (!id||!config)
    return;
  const newSession = getSessionById(config, id)
  setLocalState({...localState,session:Object.assign(newSession)})
  // setLocalState({...localState,change:false})
  setTimeout(()=>{ 
    document.querySelector(".app-main").scrollTo({
    top: 0,
    behavior: "instant"
  });
},2)
  // setTimeout(()=>{setLocalState({...localState,session:Object.assign(newSession)})},700)
  // setTimeout(()=>{setLocalState({...localState,session:Object.assign(newSession),change:false})},700)
}

const makeChange = (value)=>{
  setLocalState({...localState,change:value})
}

  const toggleBookmarkHandle = ()=>{
    toggleBookmark(accessCodeId, session.id)
  }
  
  const sessionItemProgress = (sid, percent) => {
    saveSessionItemProgress(accessCodeId, sid, session.id, percent)
};

  const saveNote = (text)=>{
    saveUserNote(accessCodeId,session.id,0,'',text)
  }

  const saveAssessmentAnswer = (assessment_id, question_id,answer)=>{
    saveQuestionAnswer(assessment_id, accessCodeId,question_id,session.id,answer)
  }

  const saveUserChoiceAnswer = (user_choice_id, question_id,answer_id)=>{
    saveUserChoiceQuestionAnswer(user_choice_id, accessCodeId,question_id,session.id,answer_id)
  }

  const markAsCompleteHandle = ()=>{
    markAsComplete('session', accessCodeId, session.id, history)
    if (session.next)
      history.push("/session/" + session.next)
  }


  const sendMessageHandle = (message)=>{
    sendMessage(message, accessCodeId, session.id)
  }
  
  const openViewChatCallback = useCallback(
    () => {
      setLocalState({...localState,viewChat:1})
    },
    [],
  );

  useEffect(() => {
    if (!config) {
      getConfig()
    }
  }, []);


  useEffect(() => {
    if (session&&session.items){
      
      const sess_buff=session.items.find(item=>(item.type==='assessment_result')&&(item.assessment.stats.answered!==item.assessment.stats.total))
      
      if (sess_buff){
        // console.log('ssss update',sess_buff)
        getAssessmentResult(sess_buff.assessment.id, accessCodeId, session.id)
      }
      
      if (otherProps.location.search.includes('?chat'))
        setTimeout(()=>toggleViewChat(),1000)
    }
    
    }, [session]);

  useEffect(() => {
  
  changeSessionDelay(id)
  // makeChange(true)
 
  // setTimeout(()=>{makeChange(false)},10000)
  }, [id,config]);
  

  return config?(
    <div className="session-page">
    {/* {console.log('session',session)} */}
      <span className={`breadcrumb ${change?"change":''}`}>
        <CollabBreadcrumb parentLink={`/section/${session.section_id}`} parentName={session.section_name} currentName={session.name} />
      </span>
      <div className="session-page__container">
          <SessionItem item={session} change={change} team={team} history={history} next={session.next} prev={session.prev} assessmentClick={saveAssessmentAnswer} userChoiceClick={saveUserChoiceAnswer} saveNote={saveNote} commentClick={toggleViewChat} bookmarkClick={toggleBookmarkHandle} markAsCompleteClick={markAsCompleteHandle} sessionItemProgress={sessionItemProgress}/>
      </div>
      <div className={`${viewChat?"active":null} chat-container`}>
        <div className='chat-container__overlay' onClick={toggleViewChat}></div>
        <div className="chat-container__chat">
          <Chat users={team} chat={session.chat} sendTextHandle={sendMessageHandle} closeButtonHandle={toggleViewChat} accessCodeId={accessCodeId} sessionId={session.id} />
        </div>
      </div>
    </div>
  ):null;
};

const mapStateToProps = (state, ownProps) =>{ 
  // console.log('mapStateToProps FIRED',state,ownProps)
  return  createStructuredSelector({
    config: selectCurrentAccessCodeConfig,
    // session: selectCurrentSessionById(ownProps.match.params.id),
    team: selectCurrentAccessCodeTeamProgress,
    accessCodeId: selectUserCurrentAccessCode
  })}

const mapDispatchToProps = (dispatch) => {
  // console.log('mapDispatchToProps FIRED')
  return {
  getConfig: () => dispatch(getConfigStart()),
  sendMessage:(message,access_code_id,session_id) =>dispatch(sendSessionMessageStart(message,access_code_id,session_id)),
  toggleBookmark:(access_code_id,session_id)=> dispatch(toggleSessionBookmarkStart(access_code_id,session_id)),
  markAsComplete:(related,access_code_id,item_id,history)=> dispatch(saveCompletedItemStart(related,access_code_id,item_id,history)),
  saveQuestionAnswer:(assessment,access_code,question,session,answer)=> dispatch(saveQuestionAnswerStart(assessment,access_code,question,session,answer)),
  saveUserChoiceQuestionAnswer:(user_choice,access_code,question,session,answer)=> dispatch(saveUserChoiceQuestionAnswerStart(user_choice,access_code,question,session,answer)),
  saveUserNote:(access_code,session,note_id, name, text)=> dispatch(saveUserNoteStart(access_code,session,note_id, name, text)),
  getAssessmentResult:(assessment,access_code,session)=>dispatch(getAssessmentResultStart(assessment,access_code,session)),
  saveSessionItemProgress:(access_code,session_item,session,percent)=>dispatch(saveSessionItemProgressStart(access_code,session_item,session,percent)),
}};
export default connect(mapStateToProps, mapDispatchToProps)(SessionPage);
// export default compose(
//   withRouter,
//   connect(mapStateToProps, mapDispatchToProps)
// )(SessionPage);
