import React, { useEffect, useState } from 'react';

import CustomButton from "../../forms/custom-button/custom-button.component"
import QuizAnswersGroup from './quiz-answers-group/quiz-answers-group.component'
import './quiz.styles.scss'


const Quiz = ({ item, quizEndedHandle, ...otherProps }) => {

    const localStateInit = {
        state: 'start',
        currentQuestionIndex: 0,
        questionAnswers: {},
        currentQuestion: {},
        fadingText: false,
    };
    const [localState, setLocalState] = useState(localStateInit);

    const {
        state,
        currentQuestionIndex,
        currentQuestion,
        questionAnswers,
        fadingText,

    } = localState;

    let timer = false;


    useEffect(() => {
        startQuizButton()
    }, [item]);

    const startQuizButton = () => {
        const curQuestion = item.questions[currentQuestionIndex]
        const questionAnswersNew = {}
        questionAnswersNew[currentQuestionIndex]=[]
        setLocalState({ ...localState, state: 'quiz',currentQuestion:curQuestion,questionAnswers:questionAnswersNew})
    }

    const rewindQuizButton = () => {
        const newCurrentQuestionIndex=0
        const curQuestion = item.questions[newCurrentQuestionIndex]
        const questionAnswersNew = {}
        questionAnswersNew[newCurrentQuestionIndex]=[]
        setLocalState({ ...localState, state: 'quiz',currentQuestion:curQuestion,questionAnswers:questionAnswersNew,currentQuestionIndex:newCurrentQuestionIndex})
    }

    const viewMyAnswersButton = () => {
        const curQuestion = item.questions[0]
        setLocalState({ ...localState, state: 'quiz',currentQuestion:curQuestion,currentQuestionIndex:0})
    }

    const saveAnswerHandle = (e,answer_id) => {
        let currentQuestionAnswersNew = {...questionAnswers}
        if (!(currentQuestionIndex in currentQuestionAnswersNew))
            currentQuestionAnswersNew[currentQuestionIndex]=[]
        currentQuestionAnswersNew[currentQuestionIndex].push(answer_id)
        setLocalState({ ...localState, questionAnswers: currentQuestionAnswersNew });
    };
    
    const calcCorrectAnswersInQuestion = () =>{
        const corrects = questionAnswers[currentQuestionIndex].reduce(function(acc, answer_id) {
            const findAnswer = currentQuestion.answers.find(answer=>answer.id===answer_id)
            return acc + findAnswer.correct;
          }, 0);
        return corrects>=currentQuestion.total_correct
    }

    const calcInCorrectAnswers = () =>{
        const incorrects = Object.keys(questionAnswers).reduce((acc_in, curr) => {
            const incorrect_in_answer = questionAnswers[curr].reduce((acc, answer_id)=>{
                const findAnswer = item.questions[curr].answers.find(answer=>answer.id===answer_id)
                return acc + !findAnswer.correct;
            },0)
            return acc_in+incorrect_in_answer
        }, 0)
        return incorrects
    }

    const handleChangeQuestion = (type) => {
        switch (type) {
            case 'next':
                if (item.questions[currentQuestionIndex + 1]) {
                    if (timer)
                        clearTimeout(timer)
                    const curQuestion = item.questions[currentQuestionIndex + 1]
                    const curQuestionIndex = currentQuestionIndex + 1
                    const questionAnswersNew = {...questionAnswers}
                    if (!questionAnswersNew[curQuestionIndex])
                        questionAnswersNew[curQuestionIndex]=[]
                    setLocalState({ ...localState, fadingText: true })
                    timer = setTimeout(() => {
                        setLocalState({ ...localState, currentQuestion: curQuestion, currentQuestionIndex: curQuestionIndex,questionAnswers:questionAnswersNew, fadingText: false })
                    }, 300); // animation timing offset

                } else {
                    quizEndedHandle(item)
                    setLocalState({ ...localState, state: 'finished'})
                }

                break;
            case 'prev':
                if (item.questions[currentQuestionIndex - 1]) {
                    const curQuestion = item.questions[currentQuestionIndex - 1]
                    const curQuestionIndex = currentQuestionIndex - 1
                    setLocalState({ ...localState, fadingText: true })
                    timer = setTimeout(() => {
                        setLocalState({ ...localState, currentQuestion: curQuestion, currentQuestionIndex: curQuestionIndex, currentQuestionPercent: curQuestion.answer === -1 ? 0 : curQuestion.answer, fadingText: false })
                    }, 300); // animation timing offset
                }
                break;

            default:
                break;
        }
    };

    return item ? (
        <div className='quiz'>
            {
                state==="start"
                    ?
                    <div className="quiz__start-container">
                        <CustomButton onClick={startQuizButton}>Start {item.name}</CustomButton>
                    </div>
                    : state ==="finished"?
                    <div className="quiz__finished-container">
                        <div className="quiz__finished-text">
                            Quiz complete.
                        </div>
                        <div className="quiz__finished-controls">
                            <div className="quiz__finished-control">
                                <CustomButton onClick={viewMyAnswersButton}>View My Answers</CustomButton>
                            </div>
                            <div className="quiz__finished-control" >
                                <div className="quiz__finished-button" onClick={rewindQuizButton}>
                                    Start Quiz Again <i className="fas fa-history"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <div className="quiz__container">
                        <div className="quiz__desc">
                            {item.description}
                        </div>
                        <div className={`quiz__question-text ${fadingText ? 'faded' : ''}`}>
                            {currentQuestion.question}
                        </div>
                        <div className={`quiz__answers-container ${fadingText ? 'faded' : ''}`}>
                            <QuizAnswersGroup handleItemClick={saveAnswerHandle} items={currentQuestion.answers} selected={questionAnswers[currentQuestionIndex]}/>
                        </div>
                        {(calcCorrectAnswersInQuestion()&&currentQuestion.feedback)?
                        <React.Fragment>
                            <div className={`quiz__question-feedback quiz__question-feedback-user-choice ${(calcCorrectAnswersInQuestion()&&!fadingText)?'show':''}`}>
                                {currentQuestion.feedback}
                            </div>
                            <div className={`quiz__controls ${(calcCorrectAnswersInQuestion()&&!fadingText)?'show':''}`}>
                                <CustomButton onClick={()=>handleChangeQuestion('next')}>{(item.questions[currentQuestionIndex + 1])?'Next':'Finish'}</CustomButton>
                            </div>
                        </React.Fragment>
                        :null}
                       
                        
                    </div>
            }


        </div>
    ) : null
}

export default Quiz;