import React from 'react';

import './quiz-answer.styles.scss'


const QuizAnswer = ({answer,correct,standard ,selected, handleClick, ...otherProps}) => (
    <div onClick={(event)=>handleClick?handleClick(event, otherProps.id):null} className={`${selected?correct?"selected correct":"selected wrong":""} ${standard?"standard":''} quiz-answer`} {...otherProps}>
        <div className='quiz-answer__name'>
            {answer}
        </div>
    </div>
)

export default QuizAnswer;