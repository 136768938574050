import {
  takeEvery,
  take,
  takeLatest,
  call,
  put,
  all,
  select
} from "redux-saga/effects";

import history,{forwardTo} from '../../utils/history.utils';

import { endpoints } from "../../api/access-codes.endpoints";
import { apiCall,apiCallDownload } from "../../api/api";

import AccessCodesTypes from "./access-codes.types";
import {
  getAccessCodeConfigError,
  getAccessCodeConfigSuccess,
  checkAccessCodeCouponError,
  checkAccessCodeCouponSuccess,
  getAccessCodesFeaturesSuccess,
  getAccessCodesFeaturesError,
  createAccessCodeSuccess,
  createAccessCodeError,
  getAccessCodeSuccess,
  getAccessCodeError,
  listAccessCodesError,
  listAccessCodesSuccess,
  editAccessCodeError,
  editAccessCodeSuccess,
  getAccessCodesResourcesSuccess,
  getAccessCodesResourcesError,
  getAccessCodeStatsSuccess,
  getAccessCodeStatsError,
  getInvitePdfSuccess,
  getInvitePdfError

} from "./access-codes.actions";



import {
  userSignOutStart,
  getUserDataStart,
} from '../user/user.actions';

import {notificationAddStart} from '../notifications/notifications.actions';
import {selectFeatures} from './access-codes.selectors';


export function* getAccessCodeConfigStartAsync() {
  try {
    const config = yield call(apiCall, endpoints.getAccessCodeConfig);
    yield put(getAccessCodeConfigSuccess(config));
  } catch (error) {
 
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(getAccessCodeConfigError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* getAccessCodeConfigStart() {
  yield takeLatest(AccessCodesTypes.GET_ACCESS_CODE_CONFIG_START, getAccessCodeConfigStartAsync);
}


export function* getAccessCodesFeaturesStartAsync() {
  try {
    const features = yield call(apiCall, endpoints.getFeatures);
    yield put(getAccessCodesFeaturesSuccess(features));
  } catch (error) {
 
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(getAccessCodesFeaturesError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* getAccessCodesFeaturesStart() {
  yield takeLatest(AccessCodesTypes.GET_ACCESS_CODE_FEATURES_START, getAccessCodesFeaturesStartAsync);
}


export function* getAccessCodesResourcesStartAsync() {
  try {
    const data = yield call(apiCall, endpoints.getResources);
    yield put(getAccessCodesResourcesSuccess(data));
  } catch (error) {
 
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(getAccessCodesResourcesError(error.message));
    yield put(notificationAddStart(error.message, 'danger',2000));
  }
}

export function* getAccessCodesResourcesStart() {
  yield takeLatest(AccessCodesTypes.GET_ACCESS_CODE_RESOURCES_START, getAccessCodesResourcesStartAsync);
}

export function* checkAccessCodeCouponStartAsync(action) {
  try {
    const discount = yield call(apiCall, endpoints.checkCoupon,{coupon:action.payload});
    yield put(checkAccessCodeCouponSuccess(discount));
  } catch (error) {
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(checkAccessCodeCouponError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* checkAccessCodeCouponStart() {
  yield takeLatest(AccessCodesTypes.CHECK_ACCESS_CODE_COUPON_START, checkAccessCodeCouponStartAsync);
}

export function* createAccessCodeStartAsync(action) {
  try {
    const code_id = yield call(apiCall, endpoints.createAccessCode,action.payload);
    yield put(createAccessCodeSuccess(code_id));
    yield put(notificationAddStart('Your payment has been successfully processed', 'success',5000));
    yield put(getUserDataStart()); 
    yield call(forwardTo, '/dashboard');
  } catch (error) {
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(createAccessCodeError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* createAccessCodeStart() {
  yield takeLatest(AccessCodesTypes.CREATE_ACCESS_CODE_START, createAccessCodeStartAsync);
}


export function* getAccessCodeStartAsync(action) {
  try {
   
    const access_code = yield call(apiCall, endpoints.getAccessCode(action.payload));
    yield put(getAccessCodeSuccess(access_code));
  } catch (error) {
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(getAccessCodeError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* getAccessCodeStart() {
  yield takeLatest(AccessCodesTypes.GET_ACCESS_CODE_START, getAccessCodeStartAsync);
}


export function* editAccessCodeStartAsync(action) {
  try {
   
    const access_code = yield call(apiCall, endpoints.editAccessCode(action.payload.id),action.payload.data);
    yield put(editAccessCodeSuccess(access_code));
    yield put(getAccessCodeSuccess(access_code));
    yield put(notificationAddStart('Saved!', 'success',5000));
  } catch (error) {
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(editAccessCodeError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* editAccessCodeStart() {
  yield takeLatest(AccessCodesTypes.EDIT_ACCESS_CODE_START, editAccessCodeStartAsync);
}


export function* listAccessCodesStartAsync() {
  try {
   
    const access_codes = yield call(apiCall, endpoints.getAccessCodesList);
    yield put(listAccessCodesSuccess(access_codes));
  } catch (error) {
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(listAccessCodesError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* listAccessCodesStart() {
  yield takeLatest(AccessCodesTypes.LIST_ACCESS_CODES_START, listAccessCodesStartAsync);
}

export function* getAccessCodeStatsStartAsync(action) {
  try {
    const stats = yield call(apiCall, endpoints.getAccessCodeStats(action.payload));
    yield put(getAccessCodeStatsSuccess(action.payload,stats));
  } catch (error) {
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(getAccessCodeStatsError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* getAccessCodeStatsStartSaga() {
  yield takeLatest(AccessCodesTypes.GET_ACCESS_CODE_STATS_START, getAccessCodeStatsStartAsync);
}




export function* getInvitePdfStart() {
  yield takeLatest(AccessCodesTypes.GET_INVITE_PDF_START, getInvitePdfStartAsync);
}

export function* getInvitePdfStartAsync(action) {
  try {
    yield call(apiCallDownload, endpoints.getInvitePdf(action.payload[0]),{},action.payload[1]);
    yield put(getInvitePdfSuccess());
  } catch (error) {
    yield put(getInvitePdfError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}


export default function* accessCodesSaga() {
  yield all([
    call(getAccessCodesFeaturesStart),
    call(getAccessCodesResourcesStart),
    call(getAccessCodeConfigStart),
    call(checkAccessCodeCouponStart),
    call(createAccessCodeStart),
    call(getAccessCodeStart),
    call(editAccessCodeStart),
    call(listAccessCodesStart),
    call(getAccessCodeStatsStartSaga),
    call(getInvitePdfStart),
  ]);
}
