import React from 'react';

import './team-card-group.styles.scss';

import TeamCard from '../team-card/team-card.component'

const TeamCardGroup = ({ items,user, handleItemClick }) => {
  return (
    <div className="team-card-group">
      {items.map(item => (
        <TeamCard
          key={item.id}
          user={user}
          {...item}
          item={item}
          handleClick={handleItemClick}
        />
      ))}
    </div>
  );
}

export default TeamCardGroup;