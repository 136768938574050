import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

import { selectUserCurrentAccessCode, selectCurrentAccessCodeConfig, selectUserData } from '../../redux/user/user.selectors.js'
import { getConfigStart } from '../../redux/user/user.actions.js'
import CollabNavButtons from '../../components/collab-nav-buttons/collab-nav-buttons.component'
import SectionItemGroup from '../../components/section-item-group/section-item-group.component'
import {getSessionById} from '../../utils/users.utils'
import ToggleButton from '../../components/toggle-button/toggle-button.component'

import "./bookmarks.styles.scss";

const BookmarksPage = ({ config, accessCodeId,user, getConfig, history }) => {

  const localStateInit = {
    toggleSelected: 1,
  };

  const [localState, setLocalState] = useState(localStateInit);

  const {
    toggleSelected,
  } = localState;

  useEffect(() => {
    getConfig()
  }, []);

 

  const handleToggleClick = (el) => {
    setLocalState({ ...localState, toggleSelected: el });
  }

  const handleBookmarkClick = (e,item) => {
    const session = getSessionById(config, item)
    if (session.inactive)
      return;
    else
      history.push(`session/${session.id}`)

  }

  const prepareBookmarkItems = (items) => {
    return items.map(item=>getSessionById(config, item))
  }
  const prepareMostPopularBookmarkItems = (items) => {
    console.log('AAaaaa',items.map(item=>getSessionById(config, item.id)))
    return items.map(item=>getSessionById(config, item.id))
  }
  
  return (
    <div className="bookmarks-page">
      <CollabNavButtons buttons={[{ 'link': '/dashboard', 'name': (config && config.course) ? config.course.name : 'My Course', 'active': false }, { 'link': '', 'name': 'Bookmarks', 'active': true }]} />
      <div className="bookmarks-page__container">
        <div className='bookmarks-page__toggle'>
          <ToggleButton selected={toggleSelected} handleClick={handleToggleClick} name1='My' name2="Popular"/>
        </div>
        {typeof config !== 'undefined' && config.bookmarks ? 
        <SectionItemGroup handleItemClick={handleBookmarkClick} itsbookmark={true} items={toggleSelected===1?prepareBookmarkItems(config.bookmarks.bookmarks):prepareMostPopularBookmarkItems(config.bookmarks.popular_bookmarks)}/> 
        : 
        null}

      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    config: selectCurrentAccessCodeConfig,
    accessCodeId: selectUserCurrentAccessCode,
    user:selectUserData,
  });

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(getConfigStart()),
  // saveValues: (access_code_id, value_ids) => dispatch(saveValueStart(access_code_id, value_ids)),
});
export default connect(mapStateToProps, mapDispatchToProps)(BookmarksPage);
