import React, { useEffect, useState } from "react";

import { connect } from "react-redux";

import { createStructuredSelector } from "reselect";

import { selectUserCurrentAccessCode, selectCurrentAccessCodeConfig, selectUserData } from '../../redux/user/user.selectors.js'
import { getConfigStart } from '../../redux/user/user.actions.js'
import CustomButton from '../../components/forms/custom-button/custom-button.component'
import ModalPopup from '../../components/forms/modal-popup/modal-popup.component'
import CollabNavButtons from '../../components/collab-nav-buttons/collab-nav-buttons.component'
import TeamCardGroup from '../../components/team-card-group/team-card-group.component'
import SlideModal from '../../components/slide-modal/slide-modal.component'
import TeamFeed from '../../components/team-feed/team-feed.component'
import { prepareSessions as _prepareSessions } from "../../utils/sessions.utils.js"
import { getDayCountForDelaySectionNew, checkUserAccessForSession } from "../../utils/sessions.utils.js"

import "./team.styles.scss";

const TeamPage = ({ config, accessCodeId, user, getConfig, history }) => {

  const localStateInit = {
    modalFeed: false,
    feedModalShow: false,
    noAccessModalShow: false,
  };

  const [localState, setLocalState] = useState(localStateInit);

  const {
    feedModalShow,
    modalFeed,
    noAccessModalShow
  } = localState;


  useEffect(() => {
    // if (config && config.constructor === Object && Object.keys(config).length !== 0 && !config.values.length) {
    //   history.push('/select_values');
    // } else if (config && config.values) {

    // }
  }, [config]);

  useEffect(() => {
    if (!config)
      getConfig()
  }, []);

  const showHideFeedModal = (show) => {
    setLocalState({ ...localState, feedModalShow: show });
  }

  const showHideNoAccessModal = (show) => {
    setLocalState({ ...localState, noAccessModalShow: show });
  }


  const handleTeamCardClick = (item) => {
    setLocalState({ ...localState, feedModalShow: true, modalFeed: item });
  }
  const handleTeamFeedClick = (item) => {

    if (checkUserAccessForSession(config.course.sections,item.item_id,item.parent_id)){
      if (item.action==="commented")
          history.push(`/session/${item.item_id}?chat`)
        else
          history.push(`/session/${item.item_id}`)
    }else
      showHideNoAccessModal(true)

  }

  const navButtons = [{ 'link': '/dashboard', 'name': (config && config.course) ? config.course.name : 'My Course', 'active': false }, { 'link': '/team', 'name': 'Team Progress', 'active': true }, { 'link': '/collaborativeway', 'name': 'Resources', 'active': false }]
  const prepareNavButtons = (navButtons) => {

    if (config && config.forum_access)
      navButtons.push({ 'link': '/forum', 'name': 'Practice Forum', 'active': false })

    return navButtons
  }
  return (
    <div className="team-page">
      <CollabNavButtons buttons={prepareNavButtons(navButtons)} />
      <div className="team-page__container">
        {typeof config !== 'undefined' && config.course ? (
          <TeamCardGroup user={user} items={config.team.filter(item => item.id !== 'all')} handleItemClick={handleTeamCardClick} />
        ) : null}

      </div>
      <SlideModal show={feedModalShow} overlayClickHandle={() => showHideFeedModal(false)} closeClickHandle={() => showHideFeedModal(false)}>
        <TeamFeed handleItemClick={handleTeamFeedClick} data={modalFeed} />
      </SlideModal>
      <ModalPopup
        className="standard-modal"
        show={noAccessModalShow}
        handleOverlayClick={()=>showHideNoAccessModal(false)}
      >
        <div className="standard-modal__title"></div>
        <div className="standard-modal__body">
          <div className="standard-modal__text">
            You don't have access to this content yet.
          </div>
        </div>
        <div className="value-modal__controls">
          <CustomButton
            onClick={()=>showHideNoAccessModal(false)}
            inverted
          >
            Close
          </CustomButton>
        </div>

      </ModalPopup>
    </div>
  );
};

const mapStateToProps = (state, ownProps) =>
  createStructuredSelector({
    config: selectCurrentAccessCodeConfig,
    accessCodeId: selectUserCurrentAccessCode,
    user: selectUserData,
  });

const mapDispatchToProps = (dispatch) => ({
  getConfig: () => dispatch(getConfigStart()),
  // saveValues: (access_code_id, value_ids) => dispatch(saveValueStart(access_code_id, value_ids)),
});
export default connect(mapStateToProps, mapDispatchToProps)(TeamPage);
