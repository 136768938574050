import React from 'react';

import Avatar from 'react-avatar-edit'
import ModalPopup from '../../components/forms/modal-popup/modal-popup.component'
import CustomButton from '../../components/forms/custom-button/custom-button.component'

import './avatar-modal.styles.scss';


const onBeforeFileLoadAvatar = (elem) => {
    if (elem.target.files[0].size > 20000000) {
      alert("File is too big!");
      elem.target.value = "";
    };
  }

const AvatarModal =({active,show,previewAvatar,srcAvatar,onCropAvatar,onCloseAvatar,avatarSaveClick,overlayClick, ...otherProps})=>(
    <ModalPopup
        className="avatar-modal"
        show={show}
        handleOverlayClick={overlayClick}
      >
        <div className="avatar-modal__title">Image for Profile</div>
        <div className="avatar-modal__body">
        <div className="avatar-modal__text">Choose and crop your avatar</div>
        <div className='avatar-modal__preview'>
            {previewAvatar ? <img  src={previewAvatar} alt="Preview" /> : null}
        </div>
       
          <div className="avatar-modal__avatar">
            <Avatar
              width={'100%'}
              height={295}
              onCrop={onCropAvatar}
              onClose={onCloseAvatar}
              onBeforeFileLoad={onBeforeFileLoadAvatar}
              src={srcAvatar}
            />
          </div>
        </div>
        <div className="value-modal__controls">
          <CustomButton
            onClick={avatarSaveClick}
            inverted
          >
            Save
        </CustomButton>
        </div>

      </ModalPopup>
);

export default AvatarModal;