import React, { useEffect, useState } from 'react';

import AssessmentInputRange from "./assessment-input-range/assessment-input-range.component"
import AssesmentStats from "./assessment-stats/assessment-stats.component"
import ReactApexChart from 'react-apexcharts'

import './assessment.styles.scss'


const Assessment = ({ item, team, result, saveAnswerHandle, endedHandle, ...otherProps }) => {

    const localStateInit = {
        showStats: false,
        openedCatergory: [],
        compareData: {},
        showHelper: false,
        nextAvailable: false,
        confirmModalShow: 'none',
        currentQuestionIndex: 1,
        currentQuestion: {},
        currentQuestionPercent: 10,
        fadingText: false,
        chartSeries: [76, 67, 61, 90],
        chartOptions: {
            colors: ['#1ac93b', '#266fff'],
            chart: {
                height: '100%',
                type: 'radar',
                toolbar: {
                    show: false
                },
                dropShadow: {
                    enabled: true,
                    blur: 1,
                    left: 1,
                    top: 1
                }
            },
            tooltip: {
                enabled: false,
            },
            dataLabels: {
                enabled: true
            },
            title: {
                text: ''
            },
            stroke: {
                width: 1
            },
            fill: {
                opacity: 0.1
            },
            markers: {
                size: 5
            },
            legend: {
                position: 'top'
            },
            xaxis: {
                labels: {
                    hideOverlappingLabels: true,
                    rotateAlways: true,
                },
                categories: ['2011', '2012', '2013', '2014', '2015', '2016']
            },
            yaxis: {
                tickAmount: 4,
                labels: {
                    formatter: function (val, i) {
                        return ''
                    }
                }
            }
        }
    };
    const [localState, setLocalState] = useState(localStateInit);

    const {
        currentQuestionIndex,
        currentQuestion,
        currentQuestionPercent,
        fadingText,
        showStats,
        chartSeries,
        openedCatergory,
        chartOptions,
        compareData,
        showHelper,
        nextAvailable,
        confirmModalShow
    } = localState;

    let timer = false;


    useEffect(() => {
        prepareCurrentQuestion();
    }, [item]);

    const toggleOpenCategory = (id) => {
        let newOpenedCatergory = [...openedCatergory];
        if (newOpenedCatergory.includes(id))
            newOpenedCatergory = newOpenedCatergory.filter(function (item) {
                return item !== id
            })
        else newOpenedCatergory.push(id)
        setLocalState({ ...localState, openedCatergory: newOpenedCatergory })
    };

    const prepareCurrentQuestion = () => {
        const curQuestion = item.questions.find(question => question.answer === -1)
        const curQuestionIndex = item.questions.findIndex(question => question.answer === -1)
        // alert("ID:"+item.id+" INDEX: "+currentQuestionIndex+" answered:"+item.stats.answered)
        
        if (curQuestionIndex === -1 || item.stats.coach) {
            const { newOptions, newSeries } = prepareChartData()
            if (timer)
                clearTimeout(timer)
            // console.log('arrarar',curQuestionIndex,currentQuestionIndex,item.questions.length)

            //  if (((currentQuestionIndex+1)>=item.questions.length)||(currentQuestionIndex===1))
            if (confirmModalShow === 'none') {
                // alert(confirmModalShow)
                endedHandle(item)
                setLocalState({ ...localState, showStats: true, compareData: prepareCompareData(), chartOptions: newOptions, chartSeries: newSeries })
            }

        }
        else {
            if (timer)
                clearTimeout(timer)
            setLocalState({ ...localState, currentQuestion: curQuestion, currentQuestionIndex: curQuestionIndex, currentQuestionPercent: curQuestion.answer === -1 ? -1 : curQuestion.answer, showHelper: (curQuestionIndex === 0 && curQuestion.answer === -1) ? true : false, nextAvailable: (curQuestion.answer !== -1) ? true : false })
        }
        // console.log('asssesss', item, curQuestion, curQuestionIndex)
    }
    const handleChange = (id, percent) => {
        setLocalState({ ...localState, currentQuestionPercent: percent, showHelper: false, nextAvailable: (percent.answer !== 55 || percent.answer !== -1) ? true : false });

    };

    const prepareCompareData = () => {
        const newCompareData = {};
        newCompareData['overall_percent'] = Math.round((item.stats.overall - item.stats.overall_compare) / item.stats.overall_compare * 100);

        const groups = item.stats.groups.map((group) => Math.round((group.percent - group.percent_compare) / group.percent_compare * 100))

        const questions = item.stats.groups.reduce((acc, group) => [...acc, ...group.questions], [])//get all questions
        let calc_questions_compare = questions.map(question => { return { ...question, "diff_percent": Math.round((question.percent - question.percent_compare) / question.percent_compare * 100) } })
        calc_questions_compare = calc_questions_compare.sort(function (a, b) {
            var x = a['diff_percent']; var y = b['diff_percent'];
            return ((x > y) ? -1 : ((x < y) ? 1 : 0));
        });
        const questions_compared = calc_questions_compare.reduce((acc, question) => {
            acc[question['question']] = question['diff_percent']
            return acc;
        }, {})
        newCompareData['bad_improve'] = calc_questions_compare.slice(-5)
        newCompareData['good_improve'] = calc_questions_compare.slice(0, 5)
        newCompareData['questions_compared'] = questions_compared;
        newCompareData['groups_compared'] = groups;
        // console.log('get questions',newCompareData)
        return newCompareData;
    };

    const formatBigLabelsForChart = (label) => {
        const cutoff = 15
        const explode = label.split(" ");
        let newLabelArr = []
        for (var i = 0; i < explode.length; i++) {
            if (explode[i + 1])
                if ((explode[i] + " " + explode[i + 1]).length < cutoff) {
                    newLabelArr.push(explode[i] + " " + explode[i + 1])
                    i++;
                    continue;
                }
            newLabelArr.push(explode[i])
        }
        return newLabelArr
    }
    const prepareChartData = () => {
        let series = []
        let labels = []
        let newSer = { 'name': 'Post' }
        if (!item.stats.compare)
            newSer = { 'name': 'Rate' }
        newSer.data = item.stats.groups.map(group => {
            if (!labels.includes(group.category))
                labels.push(formatBigLabelsForChart(group.category));
            return group.percent
        })
        series.push(newSer)

        if (item.stats.compare)
            series.push({
                'name': 'Pre', 'data':
                    item.stats.groups.map(group => {
                        return group.percent_compare
                    })
            })

        const newOptions = { ...chartOptions }
        const newSeries = series
        newOptions.xaxis.categories = labels
        if (item.stats.compare) {
            const _colors = ['#266fff', '#1ac93b'];
            newOptions.colors = _colors
        }

        return { newOptions, newSeries }
        // setLocalState({ ...localState, chartOptions: newOptions,chartSeries: newSeries});
    };

    const handleCancelConfirm = () => {
        setLocalState({ ...localState, showStats: false, confirmModalShow: 'cancel' })
    }
    const handleConfirmConfirm = () => {
        const { newOptions, newSeries } = prepareChartData()
        endedHandle(item)
        setLocalState({ ...localState, confirmModalShow: 'none', showStats: true, compareData: prepareCompareData(), chartOptions: newOptions, chartSeries: newSeries })
    }

    const handleChangeQuestion = (type) => {
        switch (type) {
            case 'next':
                if (item.questions[currentQuestionIndex + 1]) {

                    if (timer)
                        clearTimeout(timer)
                    setLocalState({ ...localState, fadingText: true })
                    const curQuestion = item.questions[currentQuestionIndex + 1]
                    const curQuestionIndex = currentQuestionIndex + 1
                    timer = setTimeout(() => {
                        setLocalState({ ...localState, currentQuestion: curQuestion, currentQuestionIndex: curQuestionIndex, currentQuestionPercent: curQuestion.answer === -1 ? -1 : curQuestion.answer, fadingText: false, nextAvailable: (curQuestion.answer !== -1) ? true : false })
                    }, 300); // animation timing offset


                } else {

                    setLocalState({ ...localState, confirmModalShow: 'show' })

                }

                break;
            case 'prev':
                if (item.questions[currentQuestionIndex - 1]) {
                    setLocalState({ ...localState, fadingText: true })
                    const curQuestion = item.questions[currentQuestionIndex - 1]
                    const curQuestionIndex = currentQuestionIndex - 1
                    timer = setTimeout(() => {
                        setLocalState({ ...localState, currentQuestion: curQuestion, currentQuestionIndex: curQuestionIndex, currentQuestionPercent: curQuestion.answer === -1 ? -1 : curQuestion.answer, fadingText: false, nextAvailable: (curQuestion.answer !== -1) ? true : false })
                    }, 300); // animation timing offset
                }
                break;

            default:
                break;
        }
        if ((saveAnswerHandle) && (currentQuestion.answer !== currentQuestionPercent))
            saveAnswerHandle(item.id, currentQuestion.id, currentQuestionPercent)

    };

    return item ? (
        <div className='assessment'>
            <div className="assessment__text">

            </div>
            {
                showStats
                    ? (!item.stats.show_result && !result) ?
                        <div className="assessment__container">
                            <div className="assessment__title assessment__title--old assessment__title--old--flex">
                                <div className="icon icon--order-success svg green-tick">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 154 154">
                                        <g fill="none" stroke="#22AE73" strokeWidth="2">
                                            <circle cx="77" cy="77" r="72" style={{'strokeDasharray':'480px, 480px', 'strokeDashoffset': '960px'}}></circle>
                                            <circle id="colored" fill="#22AE73" cx="77" cy="77" r="72" style={{"strokeDasharray":"480px, 480px", "strokeDashoffset": "960px"}}></circle>
                                            <polyline className="st0" stroke="#fff" strokeWidth="10" points="43.5,77.8 63.7,97.9 112.2,49.4 " style={{"strokeDasharray":"100px, 100px","strokeDashoffset": "200px"}} />
                                        </g>
                                    </svg>
                                </div>
                                Your survey has been successfully submitted
                            </div>
                        </div>
                        : (item.stats.users_started < 2&& item.stats.show_result===1) ?
                            <div className="assessment__container">
                                <div className="assessment__title assessment__title--old">
                                    Not enough team members have completed their survey.
                                </div>
                            </div>
                            :
                            <div className="assessment__stats-container assessment__stats-container--fade-in">
                                {item.stats.show_result === 1 ? <React.Fragment>
                                    <div className="assessment__title">
                                        Team Results
                                    </div>
                                    <div className="assessment__subtitle">
                                        <div>{Math.round(item.stats.users_started / item.stats.users_total * 100)}% of the participants have completed the assessment.</div>
                                        <div className="assessment__team-container">
                                            <div className="assessment__team-icon">
                                                <i className="fas fa-users"></i>
                                            </div>
                                            <div className="assessment__team">
                                                {item.stats.users_total === 0 ? (
                                                    <div className="assessment__team_noone">
                                                        No users
                                                    </div>) : team.filter(person => person.id !== 'all'&&!person.coach).map(person => (
                                                        <div className="assessment__team-person" key={person.id}>
                                                            <div className="assessment__team-person-icon">
                                                                {item.stats.users_not_started.includes(person.id) ? <i className="fas fa-times red"></i> : <i className="fas fa-check green"></i>}
                                                            </div>
                                                            <div className="assessment__team-person-name">{person.display}</div>

                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="assessment__stats_radar_container">
                                        <div className="assessment__stats_radar">
                                            <ReactApexChart options={chartOptions} series={chartSeries} type="radar" height="100%" />
                                        </div>
                                    </div>
                                </React.Fragment> : null
                                }

                                <div className="assessment__stats_body">
                                    <div className="assessment__stats_overal_result">
                                        <div className="assessment__stats_overal_result_score">
                                            {item.stats.show_result === 1 ? <React.Fragment>Overall team average:</React.Fragment> : <React.Fragment>Your average score:</React.Fragment>}
                                            <strong> {item.stats.overall}</strong> {item.stats.overall_compare ? <span className={`assessment__stats_compare_score ${compareData.overall_percent > 0 ? "assessment--green" : "assessment--red"}`}>{compareData.overall_percent > 0 ? '+' : ''}{compareData.overall_percent}%</span> : null}
                                        </div>
                                    </div>
                                    {item.stats.compare ?
                                        <div className="assessment__stats_improvements">
                                            <div className="assessment__stats_improvements_column">
                                                <div className="assessment__stats_improvements_title">
                                                    Most improved areas
                                                </div>
                                                {compareData.good_improve.map(improve =>
                                                    <div className="assessment__stats_improvements_area">
                                                        <div className="assessment__stats_improvements_area_name">
                                                            {improve.question}
                                                        </div>
                                                        <div className={`assessment__stats_compare_score ${improve.diff_percent > 0 ? "assessment--green" : "assessment--red"}`}>
                                                            {improve.diff_percent > 0 ? '+' : ''}{improve.diff_percent}%
                                                        </div>
                                                    </div>)}
                                            </div>
                                            <div className="assessment__stats_improvements_column">
                                                <div className="assessment__stats_improvements_title">
                                                    Least improved areas
                                                </div>
                                                {compareData.bad_improve.map(improve =>
                                                    <div className="assessment__stats_improvements_area">
                                                        <div className="assessment__stats_improvements_area_name">
                                                            {improve.question}
                                                        </div>
                                                        <div className={`assessment__stats_compare_score ${improve.diff_percent > 0 ? "assessment--green" : "assessment--red"}`}>
                                                            {improve.diff_percent > 0 ? '+' : ''}{improve.diff_percent}%
                                                        </div>
                                                    </div>)}
                                            </div>
                                        </div>
                                        : null}

                                    {item.stats.groups.map((group, group_index) => (
                                        <div key={group.category} className="assessment__group_container">
                                            <div className={`assessment__group ${openedCatergory.includes(group.category) ? 'active' : ''}`} onClick={() => toggleOpenCategory(group.category)} >
                                                <div className="assessment__group-name">
                                                    {group.category}
                                                </div>
                                                <div style={{ background: group.color }} className="assessment__group-color">

                                                </div>
                                                <div className="assessment__group-percent">
                                                    {group.percent}
                                                </div>
                                                <div className="assessment__group-control">
                                                    {!openedCatergory.includes(group.category) ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-up"></i>}

                                                </div>
                                                <div className="assessment__group-subgroups">
                                                    {item.stats.compare ?
                                                        <div className="assessment__group-subgroup">
                                                            <div className="assessment__group-subgroup-name">
                                                                {group.category}
                                                            </div>
                                                            <div className="assessment__group-subgroup-bar">
                                                                <div className="assessment__group-subgroup-bar-progress assessment__group-subgroup-bar-progress--light-orange" style={{ width: group.percent_compare * 10 + "%" }}>
                                                                    <div className="assessment__group-subgroup-bar-progress-value">
                                                                        {group.percent_compare}
                                                                    </div>
                                                                </div>
                                                                <div className="assessment__group-subgroup-bar-progress assessment__group-subgroup-bar-progress--orange" style={{ width: group.percent * 10 + "%" }}>
                                                                    <div className="assessment__group-subgroup-bar-progress-value">
                                                                        {group.percent}
                                                                        <div className={`assessment__group-subgroup-bar-progress-score assessment__stats_compare_score ${compareData.groups_compared[group_index] > 0 ? "assessment--green" : "assessment--red"}`}>
                                                                            {compareData.groups_compared[group_index] > 0 ? '+' : ''}{compareData.groups_compared[group_index]}%
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </div>

                                                        : null}
                                                    {item.stats.compare ?
                                                        <div className="assessment__group-subgroup-divider"></div>
                                                        : null}
                                                    {group.questions.map(subgroup => (
                                                        <div key={subgroup.question} className="assessment__group-subgroup">
                                                            <div className="assessment__group-subgroup-name">
                                                                {subgroup.question}
                                                            </div>
                                                            <div className="assessment__group-subgroup-bar">
                                                                {item.stats.compare ?
                                                                    <div className="assessment__group-subgroup-bar-progress " style={{ width: subgroup.percent_compare * 10 + "%" }}>
                                                                        <div className="assessment__group-subgroup-bar-progress-value">
                                                                            {subgroup.percent_compare}
                                                                        </div>
                                                                    </div>
                                                                    : null}
                                                                <div className="assessment__group-subgroup-bar-progress assessment__group-subgroup-bar-progress--alt" style={{ width: subgroup.percent * 10 + "%" }}>
                                                                    <div className="assessment__group-subgroup-bar-progress-value">
                                                                        {subgroup.percent}
                                                                        {item.stats.compare ?
                                                                            <div className={`assessment__group-subgroup-bar-progress-score assessment__stats_compare_score ${compareData.questions_compared[subgroup['question']] > 0 ? "assessment--green" : "assessment--red"}`}>
                                                                                {compareData.questions_compared[subgroup['question']] > 0 ? '+' : ''}{compareData.questions_compared[subgroup['question']]}%
                                                                            </div> : null}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))}
                                                    <div className="assessment__group-subgroup assessment__group-subtitle">
                                                        <div className="assessment__group-subgroup-name">

                                                        </div>
                                                        <div className="assessment__group-subgroup-bar labels">
                                                            <span>1 = Worst level</span>
                                                            <span style={{ float: 'right' }}>10 = Best level</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    ))}
                                </div>

                            </div>
                    : result ?
                        <div className="assessment__container">
                            <div className="assessment__title assessment__title--old">
                                Not enough team members have completed their survey.
                            </div>
                        </div>
                        :
                        <div className="assessment__container">
                            {confirmModalShow === 'show' ?
                                <div className="assessment__confirm_container">
                                    <div className="assessment__confirm_overlay">
                                    </div>
                                    <div className="assessment__confirm_modal">
                                        <div className="assessment__confirm_text">
                                            Are you ready to submit your survey?
                                        </div>
                                        <div className="assessment__confirm_controls">
                                            <div onClick={() => handleCancelConfirm()} className="assessment__confirm_control">
                                                Cancel
                                            </div>
                                            <div onClick={() => handleConfirmConfirm()} className="assessment__confirm_control">
                                                Confirm
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                : null}
                            <div className="assessment__title">
                                Question {currentQuestionIndex + 1} of {item.stats.total}
                            </div>
                            <div className={`assessment__question-text assessment__question-text--category ${fadingText ? 'faded' : ''}`}>
                                {currentQuestion.category_name}
                            </div>
                            <div className={`assessment__question-text ${fadingText ? 'faded' : ''}`}>
                                {currentQuestion.name}
                            </div>
                            <div className={`assessment__question-text assessment__question-text--statements ${fadingText ? 'faded' : ''}`}>
                                <div className="assessment__question-statement ">
                                    {currentQuestion.left_statement}
                                </div>
                                <div className="assessment__question-statement">
                                    {currentQuestion.right_statement}
                                </div>
                            </div>
                            <div className="assessment__track-container">
                                <div className="assessment__track">
                                    <AssessmentInputRange
                                        name='assessment__track'
                                        showHelper={showHelper}
                                        percent={parseFloat(currentQuestionPercent, 10)}
                                        handleChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className="assessment__controls">
                                { }
                                <div className={`assessment__control ${item.questions[currentQuestionIndex - 1] ? "" : "disabled"}`} onClick={() => handleChangeQuestion('prev')}>
                                    <i className="fas fa-angle-double-left assessment__control-icon"></i>
                                </div>
                                <div className={`assessment__control ${nextAvailable ? "" : "no-active"}`} onClick={() => nextAvailable ? handleChangeQuestion('next') : null}>
                                    <i className="fas fa-angle-double-right assessment__control-icon"></i>
                                </div>
                            </div>
                        </div>
            }


        </div>
    ) : null
}

export default Assessment;