import React, { useState, useEffect, useCallback } from "react";

import { connect } from 'react-redux';

import { useHistory } from "react-router-dom";

import { createStructuredSelector } from "reselect";

import { selectRegistrationId, selectUserProcessing, selectUserIsLogged, selectCountries, selectConfig, selectUserCurrentAccessCode } from "./redux/user/user.selectors";
import { setPushRegistrationId, getUserDataStart, checkTokenAndLoggedInStart, getCountriesStart, getHomepageConfigStart, getConfigStart, setCurrentAccessCode } from './redux/user/user.actions';



import {
  TransitionGroup,
  CSSTransition
} from "react-transition-group";

import { Switch, Route, Redirect, useLocation, useParams } from "react-router-dom";

import "./App.scss";
import HomePage from "./pages/homepage/homepage.component";
import Header from "./components/header/header.component";
import SignInPage from "./pages/sign-in/sign-in.component";
import DashboardPage from "./pages/dashboard/dashboard.component";
import SectionPage from "./pages/section/section.component";
import SessionPage from "./pages/session/session.component"
import BookmarksPage from "./pages/bookmarks/bookmarks.component"
import NotesPage from "./pages/notes/notes.component"
import TeamPage from './pages/team/team.component.jsx'
import ResetPasswordPage from './pages/reset_password/reset_password.component'
import AccountPage from './pages/account/account.component.jsx'
import CollaborativeWayPage from './pages/collaborative-way/collaborative-way.component'
import ForumPage from './pages/forum/forum.component'
import OverlayLoader from "./components/overlay-loader/overlay-loader.component";
import PublicRoute from './components/routers/public-route/public-route.component';
import PrivateRoute from './components/routers/private-route/private-route.component';
import Notifications from './components/notifications/notifications.component';
import NotificationsStackManager from "./components/notifications-stack/notifications-stack-manager.component";
import CollabCanvasBgShader from "./components/collab-canvas-bg-shader/collab-canvas-bg-shader.component"
import { subscribeUser } from "./utils/notifications.utils"
import ScrollToTop from "./components/scroll-top.component";

const App = ({ config, setNativeRegistrationId, registrationId, currentAccessCode, accessCodesProcessing, processing, getUserData, isLogged, checkToken, getConfig, getHomepageConfig, getCountries, setCurrentAccessCode }) => {

  const localStateInit = {
    nothing: false
  };
  const [localState, setLocalState] = useState(localStateInit);
  const history = useHistory();
  const {
    nothing
  } = localState;

  useEffect(() => {
    checkToken();
    if (isLogged) {
      getUserData();
      if (config && config.constructor === Object && Object.keys(config).length !== 0) {

      } else
        getConfig();
    }
    else {
      getCountries();
      getHomepageConfig();
    }

  }, [isLogged])

  useEffect(() => {
    if (config.length) {
      if (!currentAccessCode)
        setCurrentAccessCode(config[0].id)
    }

  }, [config, currentAccessCode, setCurrentAccessCode,])

  useEffect(() => {
    if (!registrationId) {
      if (typeof window['cordova'] !== 'undefined') {
        let push = window.PushNotification.init({
          android: {
          },
          ios: {
            alert: "true",
            badge: true,
            sound: "true",
            "clearBadge": true
          }
        });
        console.log('aao')
       
        push.on('registration', (data) => {
          console.log("on registration");
          setNativeRegistrationId(data)
          console.log('registration', data);
        });

        push.on('notification', data => {
          notificationHandler(data)
          console.log('notification', data);
        });

        push.on('error', (e) => {
          console.log('error notifictaion', e.message);
        });
        console.log("NATIVE PUSH SYSTEM:", push);
      }
    }

  }, [registrationId, setPushRegistrationId,])

  const notificationHandler = (data) => {
    if (data['payload']['data']['url'] !== 'undefined')
      history.push(data['payload']['data']['url']);
  }

  useEffect(() => {
    // alert('first load');
  }, [])


  const location = useLocation();


  const headerPages = ['dashboard', 'team', 'forum', 'bookmarks', 'notes', 'collaborativeway', 'account']
  const bgPages = ['sections']

  return (
    <div style={{ position: 'relative' }}>
      {((headerPages + bgPages).includes(location.pathname.split("/")[1]) || location.pathname.split("/")[1] === "") ? <CollabCanvasBgShader /> : null}
      <div style={{ position: 'relative' }} className={`app-main ${location.pathname.split("/")[1]}  ${(headerPages.includes(location.pathname.split("/")[1]) || location.pathname.split("/")[1] === "") ? 'show-header' : ''}`}>
        <OverlayLoader isLoading={processing || accessCodesProcessing} />
        {isLogged ? <Header /> : null}
        <div id="app-container" className={`app-container ${!isLogged ? "no-header" : ""} app-${location.pathname.split("/")[1]}`} >
          <ScrollToTop/>
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              classNames={['session', 'section'].includes(location.pathname.split("/")[1]) ? "slide" : "fade"}
              timeout={1000}
            >
              <Switch location={location}>
                <PublicRoute restricted={true} exact path="/" component={HomePage} />
                <PublicRoute restricted={true} exact path="/signin" component={SignInPage} />
                <PublicRoute restricted={true} exact path="/reset_password/:token?" component={ResetPasswordPage} />
                <PrivateRoute exact path="/dashboard" component={DashboardPage} />
                <PrivateRoute exact path="/team" component={TeamPage} />
                <PrivateRoute exact path="/account" component={AccountPage} />
                <PrivateRoute exact path="/forum" component={ForumPage} />
                <PrivateRoute exact path="/bookmarks" component={BookmarksPage} />
                <PrivateRoute exact path="/notes" component={NotesPage} />
                <PrivateRoute exact path="/section/:id" component={SectionPage} />
                <PrivateRoute exact path="/session/:id" component={SessionPage} />
                <PrivateRoute exact path="/collaborativeway" component={CollaborativeWayPage} />
              </Switch>
            </CSSTransition>
          </TransitionGroup>
          <Notifications />
          {
            <NotificationsStackManager show={['dashboard', 'section'].includes(location.pathname.split("/")[1])} />}



        </div>

      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  getUserData: () => dispatch(getUserDataStart()),
  checkToken: () => dispatch(checkTokenAndLoggedInStart()),
  getCountries: () => dispatch(getCountriesStart()),
  getHomepageConfig: () => dispatch(getHomepageConfigStart()),
  getConfig: () => dispatch(getConfigStart()),
  setCurrentAccessCode: (id) => dispatch(setCurrentAccessCode(id)),
  setNativeRegistrationId: (registration_id) => dispatch(setPushRegistrationId(registration_id)),
})

const mapStateToProps = createStructuredSelector({
  processing: selectUserProcessing,
  config: selectConfig,
  currentAccessCode: selectUserCurrentAccessCode,
  registrationId: selectRegistrationId,
  isLogged: selectUserIsLogged,
  countries: selectCountries,
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
