 const AccessCodesActionTypes = {
    GET_ACCESS_CODE_FEATURES_START : "GET_ACCESS_CODE_FEATURES_START",
    GET_ACCESS_CODE_FEATURES_SUCCESS : "GET_ACCESS_CODE_FEATURES_SUCCESS",
    GET_ACCESS_CODE_FEATURES_ERROR : "GET_ACCESS_CODE_FEATURES_ERROR",
    GET_ACCESS_CODE_RESOURCES_START : "GET_ACCESS_CODE_RESOURCES_START",
    GET_ACCESS_CODE_RESOURCES_SUCCESS : "GET_ACCESS_CODE_RESOURCES_SUCCESS",
    GET_ACCESS_CODE_RESOURCES_ERROR : "GET_ACCESS_CODE_RESOURCES_ERROR",
    GET_ACCESS_CODE_CONFIG_START : "GET_ACCESS_CODE_CONFIG_START",
    GET_ACCESS_CODE_CONFIG_SUCCESS : "GET_ACCESS_CODE_CONFIG_SUCCESS",
    GET_ACCESS_CODE_CONFIG_ERROR : "GET_ACCESS_CODE_CONFIG_ERROR",
    CHECK_ACCESS_CODE_COUPON_START : "CHECK_ACCESS_CODE_COUPON_START",
    CHECK_ACCESS_CODE_COUPON_SUCCESS : "CHECK_ACCESS_CODE_COUPON_SUCCESS",
    CHECK_ACCESS_CODE_COUPON_ERROR : "CHECK_ACCESS_CODE_COUPON_ERROR",
    CREATE_ACCESS_CODE_START : "CREATE_ACCESS_CODE_START",
    CREATE_ACCESS_CODE_SUCCESS : "CREATE_ACCESS_CODE_SUCCESS",
    CREATE_ACCESS_CODE_ERROR : "CREATE_ACCESS_CODE_ERROR",
    GET_ACCESS_CODE_START : "GET_ACCESS_CODE_START",
    GET_ACCESS_CODE_SUCCESS : "GET_ACCESS_CODE_SUCCESS",
    GET_ACCESS_CODE_ERROR : "GET_ACCESS_CODE_ERROR",
    GET_ACCESS_CODE_STATS_START : "GET_ACCESS_CODE_STATS_START",
    GET_ACCESS_CODE_STATS_SUCCESS : "GET_ACCESS_CODE_STATS_SUCCESS",
    GET_ACCESS_CODE_STATS_ERROR : "GET_ACCESS_CODE_STATS_ERROR",
    EDIT_ACCESS_CODE_START : "EDIT_ACCESS_CODE_START",
    EDIT_ACCESS_CODE_SUCCESS : "EDIT_ACCESS_CODE_SUCCESS",
    EDIT_ACCESS_CODE_ERROR : "EDIT_ACCESS_CODE_ERROR",
    LIST_ACCESS_CODES_START : "LIST_ACCESS_CODES_START",
    LIST_ACCESS_CODES_SUCCESS : "LIST_ACCESS_CODES_SUCCESS",
    LIST_ACCESS_CODES_ERROR : "LIST_ACCESS_CODES_ERROR",
    GET_INVITE_PDF_START : "GET_INVITE_PDF_START",
    GET_INVITE_PDF_SUCCESS : "GET_INVITE_PDF_SUCCESS",
    GET_INVITE_PDF_ERROR : "GET_INVITE_PDF_ERROR",

}

export default AccessCodesActionTypes;