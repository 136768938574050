import React from 'react';

import './checkmark-check.styles.scss';



const CheckmarkCheck =({active, ...otherProps})=>(
    <div className={`checkmark-check ${active?'active':''}`}>
            <svg className="checkmark-check__checkmark" width="100%" height="100%" viewBox="0 0 100 100">
                <circle className="checkmark-check__checkmark-disc" cx="50" cy="50" r="45" fill="#eef4f7" stroke="#1d87e8" strokeWidth="4">
                </circle>
                <path
                    className="checkmark-check__checkmark-check" d="M75 33L40 66 25 50"
                    fill="none"
                    stroke="#1d87e8"
                    strokeWidth="10"
                    strokeLinecap="round">
                </path>
            </svg>
        </div>
);

export default CheckmarkCheck;