import { METHOD_POST, METHOD_PUT } from "./methods";


const apiUrl = url => `${process.env.REACT_APP_API_URL}${url}`;

export const apiCall = (endpoint, payload) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const token = localStorage.getItem('token');

  if (endpoint.jwt === true && token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const config = {
    method: endpoint.method,
    headers: headers,
  };

  if (
    (endpoint.method === METHOD_POST || endpoint.method === METHOD_PUT) &&
    payload
  ) {
    config["body"] = JSON.stringify({ ...payload });
  }

  return fetch(apiUrl(endpoint.url), config)
    .then(resp => {
      if (resp.ok) return resp.json();
      else {
        throw resp;
      }
    })
    .then(data => data.data)
    .catch(error =>  {
        if (error instanceof Response)
            return error.json().then(err=> {throw {message:err.error, status:error.status}}) 
        else
            throw {message:error.message, status:0}
    })
};

export const apiCallDownload = (endpoint, payload,name) => {
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json"
  };
  const token = localStorage.getItem('token');

  if (endpoint.jwt === true && token) {
    headers["Authorization"] = `Bearer ${token}`;
  }

  const config = {
    method: endpoint.method,
    headers: headers,
  };

  if (
    (endpoint.method === METHOD_POST || endpoint.method === METHOD_PUT) &&
    payload
  ) {
    config["body"] = JSON.stringify({ ...payload });
  }

  return fetch(apiUrl(endpoint.url), config)
    .then(resp => {
      if (resp.ok) return resp.blob();
      else {
        throw resp;
      }
    })
    .then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', name);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }).catch(error =>  {
      if (error instanceof Response)
          return error.json().then(err=> {throw {message:err.error, status:error.status}}) 
      else
          throw {message:error.message, status:0}
  })
};