import React from 'react';
import SignIn from '../../components/forms/sign-in/sign-in.component';

import './sign-in.styles.scss';



const SignInPage = () => (
    <div className='sign-in'>
        <div className="middle_container">
            <SignIn />
        </div>
    </div>
)

export default SignInPage;