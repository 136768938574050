import {
  takeEvery,
  take,
  takeLatest,
  takeLeading,
  call,
  put,
  all,
  select
} from "redux-saga/effects";


import { endpoints } from "../../api/user.endpoints";
import { apiCall } from "../../api/api";
import history,{forwardTo} from '../../utils/history.utils';

import { selectRedirectUrl } from "./user.selectors";
import UserActionTypes from "./user.types";
import {
  getConfigStart,
  getConfigSuccess,
  getConfigError,
  getHomepageConfigSuccess,
  getHomepageConfigError,
  saveValueTrackSuccess,
  saveValueTrackError,
  saveValueError,
  saveValueSuccess,
  fetchUserLoginSuccess,
  fetchUserLoginError,
  userSignupError,
  userSignupSuccess,
  userSignOutError,
  userSignOutStart,
  userSignOutSuccess,
  getUserDataError,
  getUserDataSuccess,
  saveUserDataError,
  saveUserDataSuccess,
  getCountriesSuccess,
  getCountriesError,
  checkTokenAndLoggedInSuccess,
  checkTokenAndLoggedInError,
  showHelpModal,
  saveValueStart,
  saveValueTrackStart,
  saveCurrentAccessCodeError,
  saveCurrentAccessCodeSuccess,
  setCurrentAccessCode,
  saveCurrentAccessCodeStart,
  addAccessCodeError,
  addAccessCodeSuccess,
  addAccessCodeStart,
  deleteAccessCodeError,
  deleteAccessCodeSuccess,
  deleteMyAccountSuccess,
  deleteMyAccountError,
  sendSessionMessageError,
  sendSessionMessageSuccess,
  sendForumMessageError,
  sendForumMessageSuccess,
  getSessionChatSuccess,
  getSessionChatError,
  toggleSessionBookmarkSuccess,
  toggleSessionBookmarkError,
  saveCompletedItemSuccess,
  saveCompletedItemError,
  avatarUploadError,
  avatarUploadSuccess,
  saveQuestionAnswerSuccess,
  saveQuestionAnswerError,
  saveUserChoiceQuestionAnswerSuccess,
  saveUserChoiceQuestionAnswerError,
  saveUserNoteFavoriteError,
  saveUserNoteFavoriteSuccess,
  saveUserNoteSuccess,
  saveUserNoteError,
  resetPasswordConfirmError,
  resetPasswordConfirmSuccess,
  resetPasswordRequestSuccess,
  resetPasswordRequestError,
  resetPasswordValidateSuccess,
  resetPasswordValidateError,
  getAssessmentResultError,
  getAssessmentResultSuccess,
  getNotificationsError,
  getNotificationsSuccess,
  readNotificationError,
  readNotificationSuccess,
  saveSessionItemProgressSuccess,
  saveSessionItemProgressError,
  setCompleteModal,
  avatarDiscardSuccess,
  avatarDiscardError,
} from "./user.actions";

import {notificationAddStart} from '../notifications/notifications.actions';

export function* fetchUserLoginAsync(action) {
  try {
    const { login, password, history } = action.payload;
    // const userLogin = yield apiCall(endpoints.login,{username:login, password});
    const userLogin = yield call(apiCall, endpoints.login, {
      email: login,
      password
    });
    localStorage.setItem("token", userLogin.token);
    const urlRedirect = yield select(selectRedirectUrl);

    yield put(fetchUserLoginSuccess(userLogin.token));
    yield put(getConfigStart());
    // console.log('RRRedirect', urlRedirect)
    if (urlRedirect&&urlRedirect!=='/'){
      // history.push(urlRedirect)
      yield call([history, history.push], urlRedirect);
    }
  } catch (error) {
 
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(fetchUserLoginError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* fetchUserLoginStart() {
  yield takeLatest(UserActionTypes.LOGIN_USER_START, fetchUserLoginAsync);
}

export function* userSignupAsync(action) {
  try {
    const {
      firstName,
      lastName,
      gender,
      email,
      password,
      country,
      year,
      code
    } = action.payload[0];
    const history = action.payload[1];

    const userLogin = yield call(apiCall, endpoints.signup, {
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName,
      gender: gender,
      year: year,
      code: code,
      country:country
    });
    localStorage.setItem("token", userLogin.token);
    yield put(showHelpModal(true));
    yield put(userSignupSuccess(userLogin.token));
    yield call([history,history.push], '/dashboard');
  } catch (error) {
    yield put(userSignupError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* userSignOutAsync() {
  try {
    localStorage.setItem("token", "");
    yield put(userSignOutSuccess());
  } catch (error) {
    yield put(userSignOutError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* userSignupStart() {
  yield takeLatest(UserActionTypes.SIGNUP_USER_START, userSignupAsync);
}

export function* userSignOutStartSaga() {
  yield takeLatest(UserActionTypes.SIGNOUT_USER_START, userSignOutAsync);
}

export function* userGetDataAsync() {
  try {
    // const userLogin = yield apiCall(endpoints.login,{username:login, password});
    const userData = yield call(apiCall, endpoints.getUser);
    
    yield put(getUserDataSuccess(userData));
  } catch (error) {
 
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(getUserDataError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* userGetDataSaga() {
  yield takeLatest(UserActionTypes.GET_USER_DATA_START, userGetDataAsync);
}

export function* userSaveDataAsync(action) {
  try {
    // const userLogin = yield apiCall(endpoints.login,{username:login, password});
    const userData = yield call(apiCall, endpoints.saveUser,{...action.payload});
    yield put(saveUserDataSuccess(userData));
  } catch (error) {
 
    if (error.status === 401) 
      yield put(userSignOutStart(error.message));
    
    yield put(saveUserDataError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* userSaveDataSaga() {
  yield takeLatest(UserActionTypes.SAVE_USER_DATA_START, userSaveDataAsync);
}

export function* useruserCheckTokenAndLoggedInAsync() {
  try {
    // const userLogin = yield apiCall(endpoints.login,{username:login, password});
    const token = yield localStorage.getItem('token');
    if (token)
      yield put(checkTokenAndLoggedInSuccess());
    else
      yield put(checkTokenAndLoggedInError())
  } catch (error) {
    yield put(checkTokenAndLoggedInError())
  }
}


export function* userCheckTokenAndLoggedInSaga() {
  yield takeLatest(UserActionTypes.CHECK_TOKEN_AND_LOGGED_IN_START, useruserCheckTokenAndLoggedInAsync);
}


export function* userGetCountriesAsync() {
  try {
    const countries = yield call(apiCall, endpoints.getCountries);

    yield put(getCountriesSuccess(countries));
  } catch (error) {
    
    yield put(getCountriesError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* userGetCountries() {
  yield takeLatest(UserActionTypes.GET_COUNTRIES_START, userGetCountriesAsync);
}

export function* userGetHomepageConfigAsync() {
  try {
    const countries = yield call(apiCall, endpoints.getHomepageConfig);

    yield put(getHomepageConfigSuccess(countries));
  } catch (error) {
    
    yield put(getHomepageConfigError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* userGetHomepageConfig() {
  yield takeLatest(UserActionTypes.GET_HOMEPAGE_CONFIG_START, userGetHomepageConfigAsync);
}

export function* userGetConfigAsync() {
  try {
    const config = yield call(apiCall, endpoints.getConfig);
    yield put(getConfigSuccess(config));
  } catch (error) {
    yield put(getConfigError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* userGetConfig() {
  yield takeLeading(UserActionTypes.GET_CONFIG_START, userGetConfigAsync);
}


export function* saveValueAsync(action) {
  try {
    const {
      access_code,
      value_ids
    } = action.payload;

    yield call(apiCall, endpoints.saveValue, {
      access_code: access_code,
      value_ids: value_ids,
    });
   
    yield put(saveValueSuccess());
    yield put(getConfigStart());
    yield call(forwardTo, '/track_values');
  } catch (error) {
    yield put(saveValueError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* saveValue() {
  yield takeLatest(UserActionTypes.SAVE_VALUE_START, saveValueAsync);
}


export function* saveTrackValueAsync(action) {
  try {
    const {access_code, track_values} = action.payload
    yield call(apiCall, endpoints.saveTrackValue, {
      access_code: access_code,
      track_values: track_values
    });
   
    yield put(saveValueTrackSuccess());
    yield call(forwardTo, '/dashboard');
  } catch (error) {
    yield put(saveValueTrackError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* saveTrackValue() {
  yield takeLatest(UserActionTypes.SAVE_VALUE_TRACK_START, saveTrackValueAsync);
}


export function* saveCurrentAccessCodeAsync(action) {
  try {
    const result = yield call(apiCall, endpoints.saveCurrentAccessCode, {
      access_code: action.payload
    });
    yield put(saveCurrentAccessCodeSuccess());
    yield put(setCurrentAccessCode(action.payload));
  } catch (error) {
    yield put(saveCurrentAccessCodeError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* saveCurrentAccessCode() {
  yield takeLatest(UserActionTypes.SAVE_CURRENT_ACCESS_CODE_START, saveCurrentAccessCodeAsync);
}

export function* saveRegistrationIdAsync(action) {
  try {
    const result = yield call(apiCall, endpoints.saveRegistrationId, {
      registration_id: action.payload
    });
  } catch (error) {
  }
}

export function* saveRegistrationId() {
  yield takeLatest(UserActionTypes.SET_PUSH_REGISTRATION_ID, saveRegistrationIdAsync);
}



export function* addAccessCodeAsync(action) {
  try {
    const result = yield call(apiCall, endpoints.addAccessCode, {
      code: action.payload
    });
    yield put(addAccessCodeSuccess());
    yield put(getConfigStart());
    yield put(saveCurrentAccessCodeStart(result.access_code_id))
    // yield put(setCurrentAccessCode(result.access_code_id));
  } catch (error) {
    yield put(addAccessCodeError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* addAccessCode() {
  yield takeLatest(UserActionTypes.ADD_ACCESS_CODE_START, addAccessCodeAsync);
}

export function* deleteAccessCodeAsync(action) {
  try {
    const result = yield call(apiCall, endpoints.deleteAccessCode, {
      id: action.payload
    });
    yield put(deleteAccessCodeSuccess());
    yield put(getConfigStart());
  } catch (error) {
    yield put(deleteAccessCodeError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* deleteAccessCode() {
  yield takeLatest(UserActionTypes.DELETE_ACCESS_CODE_START, deleteAccessCodeAsync);
}

export function* deleteMyAccountAsync() {
  try {
    const result = yield call(apiCall, endpoints.deleteMyAccount, {
    });
    yield put(deleteMyAccountSuccess());
    yield put(userSignOutStart());
  } catch (error) {
    yield put(deleteMyAccountError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* deleteMyAccount() {
  yield takeLatest(UserActionTypes.DELETE_MY_ACCOUNT_START, deleteMyAccountAsync);
}

export function* sendSessionMessageAsync(action) {
  try {
    const {message,access_code,session}=action.payload;
    const data = yield call(apiCall, endpoints.sendSessionMessage, {
      message:message,
      access_code:access_code,
      session:session
    });
    yield put(sendSessionMessageSuccess(data));
  } catch (error) {
    yield put(sendSessionMessageError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* sendSessionMessage() {
  yield takeLatest(UserActionTypes.SEND_SESSION_MESSAGE_START, sendSessionMessageAsync);
}

export function* sendForumMessageAsync(action) {
  try {
    const {message,access_code,tag}=action.payload;
    const data = yield call(apiCall, endpoints.sendForumMessage, {
      message:message,
      access_code:access_code,
      tag:tag
    });
    yield put(sendForumMessageSuccess(data));
  } catch (error) {
    yield put(sendForumMessageError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* sendForumMessage() {
  yield takeLatest(UserActionTypes.SEND_FORUM_MESSAGE_START, sendForumMessageAsync);
}

export function* getSessionChatAsync(action) {
  try {
    const {access_code,session}=action.payload;
    const data = yield call(apiCall, endpoints.getSessionChat, {
      access_code:access_code,
      session:session
    });
    yield put(getSessionChatSuccess(data));
  } catch (error) {
    yield put(getSessionChatError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* getSessionChat() {
  yield takeLatest(UserActionTypes.GET_SESSION_CHAT_START, getSessionChatAsync);
}

export function* toggleSessionBookmarkAsync(action) {
  try {
    const {access_code,session}=action.payload;
    const data = yield call(apiCall, endpoints.toggleSessionBookmark, {
      access_code:access_code,
      session:session
    });
    yield put(toggleSessionBookmarkSuccess(data));
  } catch (error) {
    yield put(toggleSessionBookmarkError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* toggleSessionBookmark() {
  yield takeLatest(UserActionTypes.TOGGLE_SESSION_BOOKMARK_START, toggleSessionBookmarkAsync);
}

export function* saveCompletedItemAsync(action) {
  try {
    const {access_code,item_id,related, history}=action.payload;
    const data = yield call(apiCall, endpoints.saveCompletedItem, {
      related:related,
      access_code:access_code,
      item_id:item_id
    });
    yield put(saveCompletedItemSuccess(data));
    if (data.need_config_reload){
      yield put(getConfigStart())
      yield call([history,history.push], '/dashboard');
    }
    if (Object.keys(data.complete_modal).length){
      yield put(setCompleteModal(data.complete_modal))
    }
      
  } catch (error) {
    yield put(saveCompletedItemError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* saveCompletedItem() {
  yield takeLatest(UserActionTypes.SAVE_COMPLETED_ITEM_START, saveCompletedItemAsync);
}

export function* saveQuestionAnswerAsync(action) {
  try {
    const {assessment,access_code,question,session,answer}=action.payload;
    const data = yield call(apiCall, endpoints.saveQuestionAnswer, {
      assessment:assessment,
      access_code:access_code,
      question:question,
      session:session,
      answer:answer
    });
    yield put(saveQuestionAnswerSuccess(data));
      
  } catch (error) {
    yield put(saveQuestionAnswerError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* saveQuestionAnswer() {
  yield takeLatest(UserActionTypes.SAVE_QUESTION_ANSWER_START, saveQuestionAnswerAsync);
}

export function* getAssesmentResultAsync(action) {
  try {
    const {assessment,access_code,session}=action.payload;
    const data = yield call(apiCall, endpoints.getAssessmentResult, {
      assessment:assessment,
      access_code:access_code,
      session:session,
    });
    yield put(getAssessmentResultSuccess(data));
  } catch (error) {
    yield put(getAssessmentResultError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* getAssesmentResult() {
  yield takeLatest(UserActionTypes.GET_ASSESSMENT_RESULT_START, getAssesmentResultAsync);
}

export function* saveUserChoiceQuestionAnswerAsync(action) {
  try {
    const {user_choice,access_code,question,session,answer}=action.payload;
    const data = yield call(apiCall, endpoints.saveUserChoiceQuestionAnswer, {
      user_choice:user_choice,
      access_code:access_code,
      question:question,
      session:session,
      answer:answer,
    });
    yield put(saveUserChoiceQuestionAnswerSuccess(data));
      
  } catch (error) {
    yield put(saveUserChoiceQuestionAnswerError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* saveUserChoiceQuestionAnswer() {
  yield takeLatest(UserActionTypes.SAVE_USER_CHOICE_QUESTION_ANSWER_START, saveUserChoiceQuestionAnswerAsync);
}

export function* avatarUploadAsync(action) {
  try {
  
    const data = yield call(apiCall, endpoints.uploadAvatar, {
      avatar:action.payload
    });
    yield put(avatarUploadSuccess(data.avatar));
    yield put(getConfigStart());
  } catch (error) {
    yield put(avatarUploadError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* avatarUpload() {
  yield takeLatest(UserActionTypes.AVATAR_UPLOAD_START, avatarUploadAsync);
}

export function* avatarDiscardAsync(action) {
  try {
    const data = yield call(apiCall, endpoints.discardAvatar, {
    });
    yield put(avatarDiscardSuccess(data.avatar));
    yield put(getConfigStart());
  } catch (error) {
    yield put(avatarDiscardError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* avatarDiscard() {
  yield takeLatest(UserActionTypes.AVATAR_DISCARD_START, avatarDiscardAsync);
}

export function* saveUserNoteAsync(action) {
  try {
    const {access_code,session,note_id, name, text}=action.payload;
    const data = yield call(apiCall, endpoints.saveUserNote, {
      access_code:access_code,
      note_id:note_id,
      name:name,
      session:session,
      text:text,
    });
    yield put(saveUserNoteSuccess(data));
  } catch (error) {
    yield put(saveUserNoteError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* saveUserNote() {
  yield takeLatest(UserActionTypes.USER_NOTE_SAVE_START, saveUserNoteAsync);
}

export function* saveUserNoteFavoriteAsync(action) {
  try {
    const {access_code,note_id}=action.payload;
    const data = yield call(apiCall, endpoints.saveUserNoteFavorite, {
      access_code:access_code,
      note_id:note_id,
    });
    yield put(saveUserNoteFavoriteSuccess(data));
  } catch (error) {
    yield put(saveUserNoteFavoriteError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* saveUserNoteFavorite() {
  yield takeLatest(UserActionTypes.USER_NOTE_FAVORITE_START, saveUserNoteFavoriteAsync);
}

export function* resetPasswordConfirmAsync(action) {
  try {
    const {token,password}=action.payload;
    const data = yield call(apiCall, endpoints.resetPasswordConfirm, {
      token:token,
      password:password,
    });
    yield put(resetPasswordConfirmSuccess(data));
  } catch (error) {
    yield put(resetPasswordConfirmError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* resetPasswordConfirm() {
  yield takeLatest(UserActionTypes.RESET_PASSWORD_CONFIRM_START, resetPasswordConfirmAsync);
}

export function* resetPasswordValidateAsync(action) {
  try {
    const data = yield call(apiCall, endpoints.resetPasswordValidate, {
      token:action.payload,
    });
    yield put(resetPasswordValidateSuccess(data));
  } catch (error) {
    yield put(resetPasswordValidateError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* resetPasswordValidate() {
  yield takeLatest(UserActionTypes.RESET_PASSWORD_VALIDATE_START, resetPasswordValidateAsync);
}


export function* resetPasswordRequestAsync(action) {
  try {
    const data = yield call(apiCall, endpoints.resetPasswordRequest, {
      email:action.payload
    });
    yield put(resetPasswordRequestSuccess(data));
  } catch (error) {
    yield put(resetPasswordRequestError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* resetPasswordRequest() {
  yield takeLatest(UserActionTypes.RESET_PASSWORD_REQUEST_START, resetPasswordRequestAsync);
}

export function* getNotificationsAsync(action) {
  try {
    const {timestamp,access_code} = action.payload
    
    const data = yield call(apiCall, endpoints.getNotifications, {
      timestamp:timestamp,
      access_code:access_code
    });
    const {access_code_id:new_access_code,timestamp:new_timestamp, notifications} = data
    if (notifications.length>0)
      yield put(getConfigStart());
    yield put(getNotificationsSuccess(new_timestamp, new_access_code, notifications));
  } catch (error) {
    yield put(getNotificationsError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* getNotifications() {
  yield takeLatest(UserActionTypes.GET_NOTIFICATIONS_START, getNotificationsAsync);
}


export function* readNotificationAsync(action) {
  try {
    const {access_code_id, notification_id} = action.payload
    const data = yield call(apiCall, endpoints.setNotificationRead, {
      notification:notification_id,
      access_code:access_code_id
    });
    const {access_code_id:access_code, notification_id:new_notification_id} = data
    yield put(readNotificationSuccess(access_code, new_notification_id));
  } catch (error) {
    yield put(readNotificationError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* readNotification() {
  yield takeEvery(UserActionTypes.READ_NOTIFICATION_START, readNotificationAsync);
}

export function* saveSessionItemProgressAsync(action) {
  try {
    const {access_code,session_item,session,percent}=action.payload;
    const data = yield call(apiCall, endpoints.saveSessionItemProgress, {
      access_code:access_code,
      session_item:session_item,
      session:session,
      percent:percent
    });
    yield put(saveSessionItemProgressSuccess(data));
      
  } catch (error) {
    yield put(saveSessionItemProgressError(error.message));
    yield put(notificationAddStart(error.message, 'danger',5000));
  }
}

export function* saveSessionItemProgress() {
  yield takeLatest(UserActionTypes.SAVE_SESSION_ITEM_PROGRESS_START, saveSessionItemProgressAsync);
}


export default function* userSaga() {
  yield all([
    call(fetchUserLoginStart),
    call(userSignupStart),
    call(userSignOutStartSaga),
    call(userGetDataSaga),
    call(userSaveDataSaga),
    call(userCheckTokenAndLoggedInSaga),
    call(userGetCountries),
    call(userGetHomepageConfig),
    call(userGetConfig),
    call(saveValue),
    call(saveTrackValue),
    call(saveCurrentAccessCode),
    call(addAccessCode),
    call(deleteAccessCode),
    call(sendSessionMessage),
    call(sendForumMessage),
    call(getSessionChat),
    call(toggleSessionBookmark),
    call(saveCompletedItem),
    call(saveQuestionAnswer),
    call(getAssesmentResult),
    call(saveUserChoiceQuestionAnswer),
    call(avatarUpload),
    call(avatarDiscard),
    call(saveUserNote),
    call(saveUserNoteFavorite),
    call(resetPasswordConfirm),
    call(resetPasswordValidate),
    call(resetPasswordRequest),
    call(getNotifications),
    call(readNotification),
    call(saveSessionItemProgress),
    call(deleteMyAccount),
    call(saveRegistrationId),
  ]);
}
