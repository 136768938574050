import React from 'react';
import { Link } from 'react-router-dom';

import './collab-breadcrumb.styles.scss'


const CollabBreadcrumb = ({ parentLink, parentName, currentName, ...otherProps }) => (
    <div className="collab-breadcrumb" {...otherProps}>
        <div className="collab-breadcrumb__options">
            <div className="collab-breadcrumb__option">
                <div className="collab-breadcrumb__parent">
                    <Link to={parentLink}>
                        <div className="collab-breadcrumb__parent-icon">
                            <i className="fas fa-chevron-left" />
                        </div>
                        <div className="collab-breadcrumb__parent-name">
                            {parentName}
                        </div>
                    </Link>
                </div>
            </div>
            <div className="collab-breadcrumb__option">
                <div className="collab-breadcrumb__current">
                    {currentName}
                </div>
            </div>
        </div>
    </div>
)

export default CollabBreadcrumb;