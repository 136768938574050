import React from "react";

import {ReactComponent as Loader} from "./../../assets/loader.svg";

import "./overlay-loader.styles.scss";

const OverlayLoader = ({ isLoading }) => {
  return isLoading ? (
    <div className="overlay-loader-overlay">
      <div className="overlay-loader-container">
        <Loader/>
      </div>
    </div>
  ) : null;
};

export default OverlayLoader;
