import UserActionTypes from './user.types'

export const fetchUserLoginStart = (login, password, history) => ({
    type: UserActionTypes.LOGIN_USER_START,
    payload: {login, password,history}
});

export const fetchUserLoginSuccess = (jwt) => ({
    type: UserActionTypes.LOGIN_USER_SUCCESS,
    payload: jwt
});

export const fetchUserLoginError = (error) => ({
    type: UserActionTypes.LOGIN_USER_ERROR,
    payload: error
});

export const userSignupStart = (credentials,history) => ({
    type: UserActionTypes.SIGNUP_USER_START,
    payload: [credentials, history]
});

export const userSignupSuccess = (jwt) => ({
    type: UserActionTypes.SIGNUP_USER_SUCCESS,
    payload: jwt
});

export const userSignupError = (error) => ({
    type: UserActionTypes.SIGNUP_USER_ERROR,
    payload: error
});

export const userSignOutStart = () => ({
    type: UserActionTypes.SIGNOUT_USER_START
});

export const userSignOutSuccess = () => ({
    type: UserActionTypes.SIGNOUT_USER_SUCCESS,
});

export const userSignOutError = (error) => ({
    type: UserActionTypes.SIGNOUT_USER_ERROR,
    payload: error
});

export const getUserDataStart = () => ({
    type: UserActionTypes.GET_USER_DATA_START
});

export const getUserDataSuccess = (userData) => ({
    type: UserActionTypes.GET_USER_DATA_SUCCESS,
    payload: userData
});

export const getUserDataError = (error) => ({
    type: UserActionTypes.GET_USER_DATA_ERROR,
    payload: error
});

export const saveUserDataStart = (data) => ({
    type: UserActionTypes.SAVE_USER_DATA_START,
    payload:data
});

export const saveUserDataSuccess = (userData) => ({
    type: UserActionTypes.SAVE_USER_DATA_SUCCESS,
    payload: userData
});

export const saveUserDataError = (error) => ({
    type: UserActionTypes.SAVE_USER_DATA_ERROR,
    payload: error
});

export const checkTokenAndLoggedInStart = () => ({
    type: UserActionTypes.CHECK_TOKEN_AND_LOGGED_IN_START,
});

export const checkTokenAndLoggedInSuccess = () => ({
    type: UserActionTypes.CHECK_TOKEN_AND_LOGGED_IN_SUCCESS,
});

export const checkTokenAndLoggedInError = () => ({
    type: UserActionTypes.CHECK_TOKEN_AND_LOGGED_IN_ERROR,
});

export const getCountriesStart = () => ({
    type: UserActionTypes.GET_COUNTRIES_START,
});

export const getCountriesSuccess = (countries) => ({
    type: UserActionTypes.GET_COUNTRIES_SUCCESS,
    payload: countries
});

export const getCountriesError = (error) => ({
    type: UserActionTypes.GET_COUNTRIES_ERROR,
    payload: error
});

export const getNotificationsStart = (timestamp,access_code) => ({
    type: UserActionTypes.GET_NOTIFICATIONS_START,
    payload: {timestamp, access_code}
});

export const getNotificationsSuccess = (timestamp,access_code, notifications) => ({
    type: UserActionTypes.GET_NOTIFICATIONS_SUCCESS,
    payload: {timestamp,access_code, notifications}
});

export const getNotificationsError = (error) => ({
    type: UserActionTypes.GET_NOTIFICATIONS_ERROR,
    payload: error
});

export const readNotificationStart = (access_code_id, notification_id) => ({
    type: UserActionTypes.READ_NOTIFICATION_START,
    payload:{access_code_id, notification_id}
});

export const readNotificationSuccess = (access_code_id, notification_id) => ({
    type: UserActionTypes.READ_NOTIFICATION_SUCCESS,
    payload:{access_code_id, notification_id}
});

export const readNotificationError = (error) => ({
    type: UserActionTypes.READ_NOTIFICATION_ERROR,
    payload: error
});

export const getHomepageConfigStart = () => ({
    type: UserActionTypes.GET_HOMEPAGE_CONFIG_START,
});

export const getHomepageConfigSuccess = (data) => ({
    type: UserActionTypes.GET_HOMEPAGE_CONFIG_SUCCESS,
    payload: data
});

export const getHomepageConfigError = (error) => ({
    type: UserActionTypes.GET_HOMEPAGE_CONFIG_ERROR,
    payload: error
});

export const getConfigStart = (loader) => ({
    type: UserActionTypes.GET_CONFIG_START,
    payload: loader
});

export const getConfigSuccess = (config) => ({
    type: UserActionTypes.GET_CONFIG_SUCCESS,
    payload: config
});

export const getConfigError = (error) => ({
    type: UserActionTypes.GET_CONFIG_ERROR,
    payload: error
});

export const saveValueStart = (access_code, value_ids) => ({
    type: UserActionTypes.SAVE_VALUE_START,
    payload: {access_code, value_ids}
});

export const saveValueSuccess = () => ({
    type: UserActionTypes.SAVE_VALUE_SUCCESS,
});

export const saveValueError = (error) => ({
    type: UserActionTypes.SAVE_VALUE_ERROR,
    payload: error
});

export const saveValueTrackStart = (access_code,track_values) => ({
    type: UserActionTypes.SAVE_VALUE_TRACK_START,
    payload: {access_code,track_values}
});

export const saveValueTrackSuccess = () => ({
    type: UserActionTypes.SAVE_VALUE_TRACK_SUCCESS,
});

export const saveValueTrackError = (error) => ({
    type: UserActionTypes.SAVE_VALUE_TRACK_ERROR,
    payload: error
});

export const saveCurrentAccessCodeStart = (access_code) => ({
    type: UserActionTypes.SAVE_CURRENT_ACCESS_CODE_START,
    payload: access_code
});

export const saveCurrentAccessCodeSuccess = () => ({
    type: UserActionTypes.SAVE_CURRENT_ACCESS_CODE_SUCCESS,
});

export const saveCurrentAccessCodeError = (error) => ({
    type: UserActionTypes.SAVE_CURRENT_ACCESS_CODE_ERROR,
    payload: error
});


export const addAccessCodeStart = (code) => ({
    type: UserActionTypes.ADD_ACCESS_CODE_START,
    payload: code
});

export const addAccessCodeSuccess = () => ({
    type: UserActionTypes.ADD_ACCESS_CODE_SUCCESS,
});

export const addAccessCodeError = (error) => ({
    type: UserActionTypes.ADD_ACCESS_CODE_ERROR,
    payload: error
});

export const deleteAccessCodeStart = (id) => ({
    type: UserActionTypes.DELETE_ACCESS_CODE_START,
    payload: id
});

export const deleteAccessCodeSuccess = () => ({
    type: UserActionTypes.DELETE_ACCESS_CODE_SUCCESS,
});

export const deleteAccessCodeError = (error) => ({
    type: UserActionTypes.DELETE_ACCESS_CODE_ERROR,
    payload: error
});

export const deleteMyAccountStart = () => ({
    type: UserActionTypes.DELETE_MY_ACCOUNT_START
});

export const deleteMyAccountSuccess = () => ({
    type: UserActionTypes.DELETE_MY_ACCOUNT_SUCCESS,
});

export const deleteMyAccountError = (error) => ({
    type: UserActionTypes.DELETE_MY_ACCOUNT_ERROR,
    payload: error
});

export const showHelpModal = (show) => ({
    type: UserActionTypes.SHOW_HELP_MODAL,
    payload: show
});
export const showCompleteModal = (show) => ({
    type: UserActionTypes.SHOW_COMPLETE_MODAL,
    payload: show
});
export const setCompleteModal = (data) => ({
    type: UserActionTypes.SET_COMPLETE_MODAL,
    payload: data
});
export const setPushRegistrationId = (registration_id) => ({
    type: UserActionTypes.SET_PUSH_REGISTRATION_ID,
    payload: registration_id
});
export const setRedirectUrl = (url) => ({
    type: UserActionTypes.SET_REDIRECT_URL,
    payload: url
});

export const setCurrentAccessCode = (id) => ({
    type: UserActionTypes.SET_CURRENT_ACCESS_CODE,
    payload: id
});

export const sendSessionMessageStart = (message,access_code,session) => ({
    type: UserActionTypes.SEND_SESSION_MESSAGE_START,
    payload: {message,access_code,session}
});

export const sendSessionMessageSuccess = (data) => ({
    type: UserActionTypes.SEND_SESSION_MESSAGE_SUCCESS,
    payload: data
});

export const sendSessionMessageError = (error) => ({
    type: UserActionTypes.SEND_SESSION_MESSAGE_ERROR,
    payload: error
});

export const sendForumMessageStart = (message,access_code,tag) => ({
    type: UserActionTypes.SEND_FORUM_MESSAGE_START,
    payload: {message,access_code,tag}
});

export const sendForumMessageSuccess = (data) => ({
    type: UserActionTypes.SEND_FORUM_MESSAGE_SUCCESS,
    payload: data
});

export const sendForumMessageError = (error) => ({
    type: UserActionTypes.SEND_FORUM_MESSAGE_ERROR,
    payload: error
});

export const saveCompletedItemStart = (related,access_code,item_id,history) => ({
    type: UserActionTypes.SAVE_COMPLETED_ITEM_START,
    payload: {related,access_code,item_id,history}
});

export const saveCompletedItemSuccess = (data) => ({
    type: UserActionTypes.SAVE_COMPLETED_ITEM_SUCCESS,
    payload: data
});

export const saveCompletedItemError = (error) => ({
    type: UserActionTypes.SAVE_COMPLETED_ITEM_ERROR,
    payload: error
});

export const saveQuestionAnswerStart = (assessment,access_code,question,session,answer) => ({
    type: UserActionTypes.SAVE_QUESTION_ANSWER_START,
    payload: {assessment,access_code,question,session,answer}
});

export const saveQuestionAnswerSuccess = (data) => ({
    type: UserActionTypes.SAVE_QUESTION_ANSWER_SUCCESS,
    payload: data
});

export const saveQuestionAnswerError = (error) => ({
    type: UserActionTypes.SAVE_QUESTION_ANSWER_ERROR,
    payload: error
});

export const saveSessionItemProgressStart = (access_code,session_item,session,percent) => ({
    type: UserActionTypes.SAVE_SESSION_ITEM_PROGRESS_START,
    payload: {access_code,session_item,session,percent}
});

export const saveSessionItemProgressSuccess = (data) => ({
    type: UserActionTypes.SAVE_SESSION_ITEM_PROGRESS_SUCCESS,
    payload: data
});

export const saveSessionItemProgressError = (error) => ({
    type: UserActionTypes.SAVE_SESSION_ITEM_PROGRESS_ERROR,
    payload: error
});

export const getAssessmentResultStart = (assessment,access_code,session) => ({
    type: UserActionTypes.GET_ASSESSMENT_RESULT_START,
    payload: {assessment,access_code,session}
});

export const getAssessmentResultSuccess = (data) => ({
    type: UserActionTypes.GET_ASSESSMENT_RESULT_SUCCESS,
    payload: data
});

export const getAssessmentResultError = (error) => ({
    type: UserActionTypes.GET_ASSESSMENT_RESULT_ERROR,
    payload: error
});

export const saveUserChoiceQuestionAnswerStart = (user_choice,access_code,question,session,answer) => ({
    type: UserActionTypes.SAVE_USER_CHOICE_QUESTION_ANSWER_START,
    payload: {user_choice,access_code,question,session,answer}
});

export const saveUserChoiceQuestionAnswerSuccess = (data) => ({
    type: UserActionTypes.SAVE_USER_CHOICE_QUESTION_ANSWER_SUCCESS,
    payload: data
});

export const saveUserChoiceQuestionAnswerError = (error) => ({
    type: UserActionTypes.SAVE_USER_CHOICE_QUESTION_ANSWER_ERROR,
    payload: error
});

export const saveUserNoteStart = (access_code,session,note_id, name, text) => ({
    type: UserActionTypes.USER_NOTE_SAVE_START,
    payload: {access_code,session,note_id, name, text}
});

export const saveUserNoteSuccess = (data) => ({
    type: UserActionTypes.USER_NOTE_SAVE_SUCCESS,
    payload: data
});

export const saveUserNoteError = (error) => ({
    type: UserActionTypes.USER_NOTE_SAVE_ERROR,
    payload: error
});

export const saveUserNoteFavoriteStart = (access_code,note_id) => ({
    type: UserActionTypes.USER_NOTE_FAVORITE_START,
    payload: {access_code,note_id}
});

export const saveUserNoteFavoriteSuccess = (data) => ({
    type: UserActionTypes.USER_NOTE_FAVORITE_SUCCESS,
    payload: data
});

export const saveUserNoteFavoriteError = (error) => ({
    type: UserActionTypes.USER_NOTE_FAVORITE_ERROR,
    payload: error
});

export const getSessionChatStart = (access_code,session) => ({
    type: UserActionTypes.GET_SESSION_CHAT_START,
    payload: {access_code,session}
});

export const getSessionChatSuccess = (data) => ({
    type: UserActionTypes.GET_SESSION_CHAT_SUCCESS,
    payload: data
});

export const getSessionChatError = (error) => ({
    type: UserActionTypes.GET_SESSION_CHAT_ERROR,
    payload: error
});

export const toggleSessionBookmarkStart = (access_code,session) => ({
    type: UserActionTypes.TOGGLE_SESSION_BOOKMARK_START,
    payload: {access_code,session}
});

export const toggleSessionBookmarkSuccess = (data) => ({
    type: UserActionTypes.TOGGLE_SESSION_BOOKMARK_SUCCESS,
    payload: data
});

export const toggleSessionBookmarkError = (error) => ({
    type: UserActionTypes.TOGGLE_SESSION_BOOKMARK_ERROR,
    payload: error
});

export const avatarUploadStart = (image) => ({
    type: UserActionTypes.AVATAR_UPLOAD_START,
    payload: image
});

export const avatarUploadSuccess = (data) => ({
    type: UserActionTypes.AVATAR_UPLOAD_SUCCESS,
    payload: data
});

export const avatarUploadError = (error) => ({
    type: UserActionTypes.AVATAR_UPLOAD_ERROR,
    payload: error
});

export const avatarDiscardStart = () => ({
    type: UserActionTypes.AVATAR_DISCARD_START,
});

export const avatarDiscardSuccess = (data) => ({
    type: UserActionTypes.AVATAR_DISCARD_SUCCESS,
    payload: data
});

export const avatarDiscardError = (error) => ({
    type: UserActionTypes.AVATAR_DISCARD_ERROR,
    payload: error
});


export const resetPasswordConfirmStart = (token,password) => ({
    type: UserActionTypes.RESET_PASSWORD_CONFIRM_START,
    payload: {token,password}
});

export const resetPasswordConfirmSuccess = (data) => ({
    type: UserActionTypes.RESET_PASSWORD_CONFIRM_SUCCESS,
    payload: data
});

export const resetPasswordConfirmError = (error) => ({
    type: UserActionTypes.RESET_PASSWORD_CONFIRM_ERROR,
    payload: error
});

export const resetPasswordRequestStart = (email) => ({
    type: UserActionTypes.RESET_PASSWORD_REQUEST_START,
    payload: email
});

export const resetPasswordRequestSuccess = (data) => ({
    type: UserActionTypes.RESET_PASSWORD_REQUEST_SUCCESS,
    payload: data
});

export const resetPasswordRequestError = (error) => ({
    type: UserActionTypes.RESET_PASSWORD_REQUEST_ERROR,
    payload: error
});

export const resetPasswordValidateStart = (token) => ({
    type: UserActionTypes.RESET_PASSWORD_VALIDATE_START,
    payload: token
});

export const resetPasswordValidateSuccess = (data) => ({
    type: UserActionTypes.RESET_PASSWORD_VALIDATE_SUCCESS,
    payload: data
});

export const resetPasswordValidateError = (error) => ({
    type: UserActionTypes.RESET_PASSWORD_VALIDATE_ERROR,
    payload: error
});

