import React from 'react';
import { Link } from 'react-router-dom';

import './collab-nav-buttons.styles.scss'


const CollabNavButtons = ({ buttons, ...otherProps }) => (
    <div className="collab-nav-buttons" {...otherProps}>
        <div className="collab-nav-buttons__options">
        {buttons.map((button,index)=>(
            <Link style={{'pointerEvents':!button.active?'all':'none'}} key={index} to={button.link} className={`collab-nav-buttons__link ${button.active?'active':''}`}>
            <div className={`collab-nav-buttons__option ${button.active?'active':''}`}>
                <div className={`collab-nav-buttons__button ${button.active?'active':''}`}>
                        {button.name}
                </div>
            </div>
            </Link>
        ))}
        </div>
    </div>
)

export default CollabNavButtons;