import { createSelector } from "reselect";
import {preapreTableOfAccessCodesList, getAccessCodeById} from '../../utils/access-codes.utils';

const selectAccessCodes = state => state.accessCodes;

export const selectFeatures = createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.features
);

export const selectResources = createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.resources
);

export const selectLinks = createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.links
);

export const selectAccessCodesProcessing = createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.processing
);

export const selectAnnualDiscount = createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.annual_discount
);

export const selectCoupon = createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.coupon
);

export const selectCouponDiscount = createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.coupon_discount
);

export const selectAccessCodeSuccess = createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.success
);

export const selectAccessCodesListTable = createSelector(
  [selectAccessCodes],
  accessCodes => preapreTableOfAccessCodesList(accessCodes.accessCodesList)
);

export const selectAccessCodesList = createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.accessCodesList
);

export const selectAccessCode = (id) => createSelector(
  [selectAccessCodes],
  accessCodes => accessCodes.accessCodesList?getAccessCodeById(accessCodes.accessCodesList,id):{}
);


