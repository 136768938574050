export const findNotificationById = (notifications,id) => {
    return notifications.find(
        item => (item.id === id)
      );
}

export const makeShowForNotificationId = (notifications, id,show) =>  {
    return notifications.map((item)=> (item.id===id?{...item,show:show}:item));
}
export const removeForNotificationId = (notifications, id) =>  {
    return notifications.filter((item)=>(item.id!==id));
}