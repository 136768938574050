import React from 'react';

import './team-feed.styles.scss';

import TeamFeedItem from '../team-feed-item/team-feed-item.component'
import moment from 'moment'

const TeamFeed = ({ data, handleItemClick }) => {
 const prepareHistory = (data)=>{
  //  console.log(data)
    const newItems = []
    let last_separator = false
    data.history.map((item,index)=>{
      if ((!last_separator)||(moment(last_separator).diff(moment(item.created_at), 'hours') > 24)){
        newItems.push({
          'id':999+index,
          'type':'separator',
          'date':item.created_at,
        })
        last_separator=item.created_at
      }
      // console.log(data)
      newItems.push({
        'id':index,
        'type':'stat',
        'item_type':'',
        'item_id':item.id,
        'parent_id':item.section_id,
        'avatar':data.avatar,
        'first_name':data.first_name,
        'last_name':data.last_name,
        'display':data.first_name+" "+data.last_name,
        'date':item.created_at,
        'action':item.action,
        'name':item.name,
        'section_name':item.section_name,
      })
      return false;
    })
    return newItems
 }

  return (data?
    <div className="team-feed">
      {prepareHistory(data).map((item,index) => (
        item.type==="separator"?
        <div key={item.id} className="team-feed__separator">
            {(moment(item.date).calendar().split(" at"))[0]}
        </div>
        :
        <TeamFeedItem
          key={item.id}
          {...item}
          item={item}
          handleClick={handleItemClick}
        />     
      ))}
    </div>
    :null
  );
}

export default TeamFeed;