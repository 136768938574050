import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { createStructuredSelector } from "reselect";

import FormInput from "../form-input/form-input.component";
import FormSelect from "../form-select/form-select.component";
import CustomButton from "../custom-button/custom-button.component";
import ModalPopup from "../modal-popup/modal-popup.component";
import { selectCountries } from "../../../redux/user/user.selectors.js";
import FormCheckbox from "../form-checkbox/form-checkbox.component"

import { userSignupStart } from "../../../redux/user/user.actions";
import { selectUserError } from "../../../redux/user/user.selectors";

import "./sign-up.styles.scss";

const SignUp = ({ userSignup, countries,agreementText }) => {
  const history = useHistory();
  const signUpStateMap = {
    firstName: "",
    lastName: "",
    code: "",
    year: "",
    country: 0,
    gender: 'm',
    organisation: "",
    email: "",
    password: "",
    confirmPassword: "",
    disableSignUpButton: true,
    showModalBlacklisted: false,
    blacklistedText: "",
    agreementChecked:false,
    showModalAgreement:false,
  };

  const [signUpState, setSignUpState] = useState(signUpStateMap);

  const {
    firstName,
    lastName,
    code,
    year,
    gender,
    email,
    password,
    confirmPassword,
    country,
    disableSignUpButton,
    showModalBlacklisted,
    blacklistedText,
    agreementChecked,
    showModalAgreement,
  } = signUpState;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      alert("password don't match");
      return;
    }
    try {
      userSignup(signUpState,history);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAgreementChange = () => {
    var disableSign = false
    if (signUpState.agreementChecked) 
      disableSign=true
    setSignUpState({
      ...signUpState,
      agreementChecked: signUpState.agreementChecked ^ 1,disableSignUpButton:disableSign
    });
  };

  const handleShowModalAgreement = (show) => {
    setSignUpState({
      ...signUpState,
      showModalAgreement: show
    });
  };

  const genders = [{ id: 'f', 'name': 'Female' },
                  { id: 'm', 'name': 'Male' },
                  { id: 'o', 'name': 'Other' }]

  const handleChange = (event) => {
    const { name, value } = event.target;
    let disableSignUpButtonValue = false;

    // if (name === "country")
    //   disableSignUpButtonValue = checkCountrySelect(value);

    if (Array.isArray(disableSignUpButtonValue))
      setSignUpState({
        ...signUpState,
        [name]: value,
        disableSignUpButton: disableSignUpButtonValue[0],
        showModalBlacklisted: disableSignUpButtonValue[0],
        blacklistedText: disableSignUpButtonValue[1],
      });
    else
      setSignUpState({
        ...signUpState,
        [name]: value,
      });
  };

  const checkCountrySelect = (id) => {
    id = parseInt(id, 10);
    const foundCountry = countries.find((item) => item.id === id);

    if (foundCountry && foundCountry.blacklisted) {
      return [true, foundCountry.blacklisted_text];
    } else {
      return [false, ""];
    }
  };

  const createMarkup = (text) => ({ __html: text });

  return (
    <div className="sign-up">
      <span className="title">Sign up with your email and account</span>
      <form className="sign-up-form" onSubmit={handleSubmit}>
        <FormInput
          type="text"
          name="firstName"
          value={firstName}
          onChange={handleChange}
          label="First Name"
          required
        />
        <FormInput
          type="text"
          name="lastName"
          value={lastName}
          onChange={handleChange}
          label="Last Name"
          required
        />
        <FormSelect
          items={countries}
          name="country"
          value={country}
          onChange={handleChange}
          label="Country"
          firstEmpty={true}
          required
        />

        <FormInput
          type="email"
          name="email"
          value={email}
          onChange={handleChange}
          label="Email"
          required
        />

        <FormInput
          type="password"
          name="password"
          value={password}
          onChange={handleChange}
          label="Password"
          required
        />

        <FormInput
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          onChange={handleChange}
          label="Confirm Password"
          required
        />
        
        <FormInput
          type="text"
          name="code"
          value={code}
          onChange={handleChange}
          label="Access Code"
          required
        />
         <FormCheckbox
          checked={agreementChecked}
          handleClick={handleAgreementChange}
        >
        I accept the <span class='link' onClick={()=>handleShowModalAgreement(true)} >terms and conditions</span> 
        </FormCheckbox>
        <div className="button-sign-up">
          <CustomButton disabled={disableSignUpButton} inverted type="submit">
            Sign Up
          </CustomButton>
        </div>
        <div className="footnote-signup">
          Lloyd Fickett & Associates, Inc. ⓒ 2022. All Rights Reserved. The Collaborative Way®
        </div>
      </form>
      <ModalPopup
        className="blacklisted-modal"
        show={showModalBlacklisted}
        handleOverlayClick={() =>
          setSignUpState({ ...signUpState, showModalBlacklisted: false })
        }
      >
        <div className="blacklisted-modal">
          <div className="blacklisted-modal__title">Oh, Sorry</div>
          <div
            className="blacklisted-modal__text"
            dangerouslySetInnerHTML={createMarkup(blacklistedText)}
          />
          <div className="blacklisted-modal__controls">
            <CustomButton
              onClick={() =>
                setSignUpState({ ...signUpState, showModalBlacklisted: false })
              }
              inverted
              type="submit"
            >
              Okay
            </CustomButton>
          </div>
        </div>
      </ModalPopup>
      <ModalPopup
        className="blacklisted-modal"
        show={showModalAgreement}
        handleOverlayClick={() =>
          handleShowModalAgreement(false)
        }
      >
        <div className="agreement-modal">
          <div className="agreement-modal__title">Terms and Conditions</div>
          <div
            className="agreement-modal__text"
            dangerouslySetInnerHTML={createMarkup(agreementText)}
          />
          <div className="agreement-modal__controls">
            <CustomButton
              onClick={() =>
                handleShowModalAgreement(false)
              }
              inverted
              type="submit"
            >
              Close
            </CustomButton>
          </div>
        </div>
      </ModalPopup>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  userSignup: (setSignUpState, history) => dispatch(userSignupStart(setSignUpState, history)),
});

const mapStateToProps = createStructuredSelector({
  countries: selectCountries,
});

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
