import { combineReducers } from 'redux';

import userReducer from './user/user.reducer';
import notificationsReducer from './notifications/notifications.reducer';
import accessCodesReducer from './access-codes/access-codes.reducer';



const rootReducer =  combineReducers({
    user: userReducer,
    notifications:notificationsReducer,
    accessCodes:accessCodesReducer
});

export default rootReducer