import React from 'react';

import moment from 'moment'

import './chat.styles.scss'



const ChatMessage = ({ messages, type, bot }) => {
    const convertDate = (date) => {
        return moment(date).calendar()
    }

    const returnMessages = (messages, type, bot) => {
        
        return messages.map((message, index) => {
            
            return (
                <li key={index} className={`spk-chat__li spk-chat__li--${ (messages.length === index + 1) ? 'bottom' : index === 0 ? 'top' : 'middle'}`}>
                    <div className={`spk-chat__message spk-chat__message--${type === 'me' ? 'me' : 'noone'} spk-chat__message--${bot ? 'bot' : 'user'} spk-chat__message-simple spk-chat__message-simple--${type === 'me' ? 'me' : 'noone'}`}>
                        <div data-testid="avatar" className="spk-chat__avatar spk-chat__avatar--circle" title="Broken waterfall">
                            <img data-testid="avatar-img" src={message.avatar} alt="B" className="spk-chat__avatar-image spk-chat__avatar-image--loaded" />
                        </div>
                        <div data-testid="message-inner" className="spk-chat__message-inner">
                            <div className="spk-chat__message-attachment-container">

                            </div>
                            <div className="spk-chat__message-text">
                                <div data-testid="message-text-inner-wrapper" className="spk-chat__message-text-inner spk-chat__message-simple-text-inner">{message.text}
                                </div>
                            </div>
                            <div className="spk-chat__message-data spk-chat__message-simple-data">
                                
                                <span className="spk-chat__message-simple-name">{message.coach?<span className='spk-chat__message-simple-name-coach'>[Coach]</span>:""} {message.name}</span>
                                <time className="spk-chat__message-simple-timestamp" title={convertDate(message.date)}>{convertDate(message.date)}</time>
                            </div>
                        </div>
                    </div>
                </li>
            )
        })
    }

    return (
        <React.Fragment>
       
            {type === "separator-date" ?
                <li>
                    <div className="spk-chat__date-separator">
                        <hr className="spk-chat__date-separator-line" />
                        <div className="spk-chat__date-separator-date">{convertDate(messages[0].date)}</div>
                    </div>
                </li>
                :
                returnMessages(messages, type, bot)
            }
        </React.Fragment>
    )
}

export default ChatMessage;