import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import {selectUserIsLogged} from '../../../redux/user/user.selectors';
import {createStructuredSelector} from 'reselect';

const PublicRoute = ({component: Component,isLogged, restricted, ...rest}) => {
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (
            isLogged && restricted ?
                <Redirect to="/dashboard" />
            : <Component {...props} />
        )} />
    );
};

const mapStateToProps = createStructuredSelector({
  isLogged:selectUserIsLogged
});
export default connect(mapStateToProps)(PublicRoute);