import React from 'react';

import './form-checkbox.styles.scss';



const FormCheckbox =({handleClick,checked, children, ...otherProps})=>(
    <div className='form-checkbox'>
        <div onClick={handleClick} className={`form-checkbox__checkbox ${checked?'checked':''}`}></div>
        <div className={`form-checkbox__label`}>
            {children}
        </div>
    </div>
);

export default FormCheckbox;