import React from "react";

import "./error-label.styles.scss";

const ErrorLabel = ({ error }) => {
  let errorPretty = "";
  try {
    const jsonObj = JSON.parse(error);
    errorPretty = Object.entries(jsonObj);
  } catch (e) {
    errorPretty = error;
  }

  if (!errorPretty) return null;

  if (Array.isArray(errorPretty))
    return (
      <span className="error-label">
        Error:
        {errorPretty.map(item => (
          <div className="error-label-small" key={item[0]}>
            {item[0]}: {item[1]}
          </div>
        ))}
      </span>
    );
  else return (<span className="error-label">Error: {errorPretty}</span>);
};

export default ErrorLabel;
