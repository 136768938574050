import React from 'react';

import '../form-input/form-input.styles.scss';
import './form-select.styles.scss';




const FormSelect =({handleChange,items,label,addNew,firstEmpty, ...otherProps})=>(
    <div className='group form-select'>
     <select className='form-input' onChange={handleChange} {...otherProps}>
        {firstEmpty?<option value=''></option>:null}
        {items.map(item=><option disabled={item.label} key={item.id} value={item.id}>{item.name}</option>)}
        {addNew?<option value='add'>Add New</option>:null}
     </select>
     { label ? 
     <label className={`${otherProps.value.length ? "shrink": ""} form-input-label`}>
     {label}
     </label>
     : null
     }
    </div>
);

export default FormSelect;