import React, { useEffect, useState, useRef } from 'react';

import SimpleVideoPlayer from "../simple-video-player/simple-video-player.component"
import CustomButton from "../forms/custom-button/custom-button.component"
import CheckmarkCheck from "../checkmark-check/checkmark-check.component"
import Assessment from "./assessment/assessment.component"
import ModalPopup from '../../components/forms/modal-popup/modal-popup.component'
import Quiz from "./quiz/quiz.component"
import UserChoice from "./quiz/user-choice/user-choice.component"
import { parse } from '../../utils/bb-code.utils'

import './session-item.styles.scss'

const createMarkup = (text) => ({ __html: text });



const SessionItem = ({ item, team, change, handleClick, commentClick, sessionItemProgress, userChoiceClick, bookmarkClick, saveNote, assessmentClick, quizEnded, markAsCompleteClick, history, prev, next, ...otherProps }) => {
    const localStateInit = {
        neededInteractions: 0,
        interactions: 0,
        countedInteractions: [],
        interactionsList: [],
        noteModalShow: false,
        noteModalExit: false,
        noteText: "",
    };
    const [localState, setLocalState] = useState(localStateInit);

    const noteTextRef = useRef(null);

    const {
        neededInteractions,
        interactions,
        interactionsList,
        noteModalShow,
        countedInteractions,
        noteModalExit,
        noteText


    } = localState;

    const showHideNoteModal = (show) => {
        if (show) {
            setLocalState({ ...localState, noteModalShow: show, noteModalExit: false });
            setTimeout(() => {
                const noteTextarea = noteTextRef.current
                let pos = noteTextarea.textContent.search(/\n\n/)
                if (pos===-1)
                    pos=noteTextarea.textContent.length
                noteTextarea.setSelectionRange(pos + 1, pos + 1);
                noteTextarea.focus();
            }, 300)
        }
        else {
            setLocalState({ ...localState, noteModalExit: true });
            setTimeout(() => {
                setLocalState({ ...localState, noteModalShow: show, noteModalExit: false });
            }, 1000)
        }
    }

    const noteTextChange = event => {
        const { value, name } = event.target;
        setLocalState({ ...localState, noteText: value });
    };

    const countInteractions = () => {
        const newInteractionList = []

        const _interactions = item.items.reduce(function (acc, item) {
            if ((item.type === "quiz") || (item.type === "assessment") || (item.type === "user_choice") || (item.type === "video")){
                let text = "";
                switch (item.type) {
                    case "quiz":
                        text = "Finish Quiz";
                        break;
                    case "assessment":
                        text = "Finish Assessment";
                        break;
                    case "user_choice":
                        text = "Finish Choice";
                        break;
                    case "video":
                        text = "Finish Video";
                        break;
                    default:
                        break;
                }
                newInteractionList.push({"id":item.id,'text':text,'completed':0})
                return acc + 1;
            }
            else
                return acc;
        }, 0);
        return [_interactions, newInteractionList]
    }

    const handleVideoPercentChange = (sid, percent) => {
        sessionItemProgress(sid, percent)
        
        if (percent >= 70 && !countedInteractions.includes(sid)) {
            const newinteractions = interactions + 1
            const newCountedInteractions = [...countedInteractions]
            newCountedInteractions.push(sid)
            setLocalState({ ...localState, interactions: newinteractions, countedInteractions: newCountedInteractions })
        }
    }

    const handleEndedQuiz = (item, sid) => {
        setTimeout(() => {
            setLocalState((prevState) => {
                let newinteractions = prevState.interactions
                const newCountedInteractions = [...prevState.countedInteractions]
                if (!prevState.countedInteractions.includes(sid)) {
                    newinteractions = newinteractions + 1
                    newCountedInteractions.push(sid)
                }
                return { ...prevState, interactions: newinteractions, countedInteractions: newCountedInteractions }
            })
        }, 1000)
    }

    useEffect(() => {

        if (item) {
            // console.log('quiz start call', interactions)
            const countInteractionsResult =countInteractions()
            const newNeededIteractions = countInteractionsResult[0]
            const newInteractionList = countInteractionsResult[1]
            const newNoteText = item.note ? item.note : item.default_note
            setLocalState({ ...localState, neededInteractions: newNeededIteractions, interactions: 0, noteText: newNoteText, interactionsList:newInteractionList })
        }
    }, [item]);

    const formatTextWithChatButton = (text) => {
        let parts = text.split(/\[(.*?)\|(.*?)\]/g)
        for (let i = 1; i < parts.length; i += 3) {
            if (parts[i] === 'chat')
                parts[i] = (
                    <span className='spk-chat__chat-button' onClick={commentClick} data-user={parts[i + 1]} key={i}>
                        {parts[i + 1]}
                    </span>
                )
            else
                parts[i] = (
                    <span className='spk-chat__text' key={i}>
                        {parts[i + 1]}
                    </span>
                )
            parts[i + 1] = null;
        }
        return <React.Fragment>{parts}</React.Fragment>
    }

    const tags = {
        'chat':
        {
            mono: false,
            tag: (params, childs) => (<div className="session-item__text-chat-button-container">
                <div className='session-item__text-chat-button-body' >
                    <div className='session-item__text-chat-button-icon' >
                        <i className="far fa-comment-dots"></i>
                    </div>
                    <span className='session-item__text-chat-button-text' >
                        {childs}
                    </span>
                </div>
                <span className='session-item__text-chat-button' onClick={commentClick} >
                    {params.name}
                </span>
            </div>)
        },
        'note':
        {
            mono: false,
            tag: (params, childs) => (
                <div className="session-item__text-chat-button-container session-item__text-note-button-container">
                    <div className='session-item__text-chat-button-body' >
                        <div className='session-item__text-chat-button-icon' >
                            <i className="fas fa-align-left"></i>
                        </div>
                        <span className='session-item__text-chat-button-text' >
                            {childs}
                        </span>
                    </div>
                    <span className='session-item__text-chat-button' onClick={() => showHideNoteModal(true)} >
                        {params.name}
                    </span>
                </div>)
        },
        'center': (params, childs) => (<div className='session-item__text-center' style={{ 'color': params.color }}>{childs}</div>),
        'left': (params, childs) => (<div className='session-item__text-left' style={{ 'color': params.color }}>{childs}</div>),
        'right': (params, childs) => (<div className='session-item__text-right' style={{ 'color': params.color }}>{childs}</div>),
        'url': (params, childs) => (<a className='session-item__text-link' rel="noopener noreferrer" target="_blank" href={params.url}>{childs}</a>),
        'img': (params, childs) => (<img className='session-item__text-image' alt='img' src={childs} />),
        'h1': (params, childs) => (<h1>{childs}</h1>),
        'h2': (params, childs) => (<h2>{childs}</h2>),
        'b': (params, childs) => (<b>{childs}</b>),
        'code': (params, childs) => (<code>{childs}</code>),
        'sup': (params, childs) => (<sup>{childs}</sup>),
        'sub': (params, childs) => (<sub>{childs}</sub>),
        's': (params, childs) => (<s>{childs}</s>),
        'i': (params, childs) => (<span style={{ 'fontStyle': 'italic' }}>{childs}</span>),
        'u': (params, childs) => (<u>{childs}</u>),
        'table': (params, childs) => (<table><tbody>{childs}</tbody></table>),
        'tr': (params, childs) => (<tr>{childs}</tr>),
        'td': (params, childs) => (<td>{childs}</td>),
        'th': (params, childs) => (<th>{childs}</th>),
        'list': (params, childs) => (params.list ? (<ol>{childs}</ol>) : (<ul>{childs}</ul>)),
        '*': (params, childs) => (<li>{childs}</li>),
        'size': (params, childs) => (<div className='session-item__text' style={{ 'fontSize': params.size + 'px' }}>{childs}</div>),
        'font': (params, childs) => (<div className='session-item__text' style={{ 'fontFamily': params.font }}>{childs}</div>),
        'box': (params, childs) => (<div className='session-item__text-box' style={{ 'backgroundColor': params.bgcolor, 'color': params.color }}>{childs}</div>),
        'color': (params, childs) => (<span className='session-item__text' style={{ 'color': params.color }}>{childs}</span>),
        'quote': (params, childs) => (<div className='session-item__text-quote' ><div>{childs}</div></div>),
    }



    return item ? (
        <div className={`session-item ${change ? 'change' : ''}`} {...otherProps}>
            <div className="session-item__content">
                {item.items.map(sitem => (
                    sitem.type === "video" ? <div key={sitem.id} className='session-item__video'><SimpleVideoPlayer startPercent={sitem.percent} toStartIfEnd={true} percentCallback={(percent) => handleVideoPercentChange(sitem.id, percent)} src={sitem.url} /></div>
                        : sitem.type === "image" ? <div key={sitem.id} className='session-item__image'><img alt="" src={process.env.REACT_APP_URL + "" + sitem.image}></img></div>
                            : sitem.type === "text" ? <div key={sitem.id} className='session-item__text'>{parse(sitem.text.replace(/<[^>]+>/g, ''), tags)}</div>
                                : (sitem.type === "assessment" || sitem.type === "assessment_result") ? <Assessment saveAnswerHandle={assessmentClick} endedHandle={(item) => sitem.type === "assessment"?handleEndedQuiz(item, sitem.id):null} key={sitem.id} item={sitem.assessment} team={team} result={sitem.type === "assessment_result" ? true : false} />
                                    : sitem.type === "quiz" ? <Quiz quizEndedHandle={(item) => handleEndedQuiz(item, sitem.id)} key={sitem.id} item={sitem.quiz} />
                                        : sitem.type === "user_choice" ? <UserChoice saveAnswerHandle={userChoiceClick} quizEndedHandle={(item) => handleEndedQuiz(item, sitem.id)} key={sitem.id} item={sitem.user_choice} /> : null
                ))}
            </div>
            <div className="session-item__controls">
                <div className='session-item__top-controls'>
                    <div className='session-item__bookmark' onClick={bookmarkClick}>
                        <i className={`far fa-bookmark ${item.bookmarked ? 'active' : ''}`}></i>
                        <div className="session-item__badge">{item.bookmarks}</div>
                        <span>{item.bookmarks} Bookmarks</span>
                    </div>
                    <div className={`session-item__note ${item.note ? "active" : ''}`} onClick={() => showHideNoteModal(true)}>
                        <i className="fas fa-align-left"></i>
                        <span>My Notes</span>

                    </div>
                    <div className='session-item__chat' onClick={commentClick}>
                        <span>{item.chat.length} Chat</span>
                        <div className="session-item__badge">{item.chat.length}</div>
                        <i className="far fa-comment"></i>
                    </div>
                </div>
                <div className='session-item__bottom-controls'>
                    {prev ?
                        <div className="session-item__button">
                            <CustomButton onClick={() => {history.push("/session/" + prev) }}>Previous</CustomButton>
                        </div>
                        : <div className="session-item__button">
                            {item.prev_section ? <CustomButton onClick={() => { document.querySelector(".app-main").scrollTo({ top: 0 }); history.push("/section/" + item.prev_section) }}>Prev. Section</CustomButton> : null}
                        </div>
                    }
                    {!item.completed ?
                        <div className={`session-item__button ${interactions < neededInteractions ? 'inactive' : ''}`} onClick={(interactions < neededInteractions) ? null : markAsCompleteClick}>
                            <CustomButton disabled={interactions < neededInteractions} >
                            {interactions < neededInteractions?
                                <div className="interactions__container">
                                <div className="interactions__interactions-icon">
                                    <i className="fas fa-question"></i>
                                </div>
                                <div className="interactions__interactions">
                                
                                
                                {interactionsList.map(item=>(
                                        <div className="interactions__item" key={item.id}>
                                            <div className="interactions__item-icon">
                                               {!countedInteractions.includes(item.id)?<i className="fas fa-times red"></i>:<i className="fas fa-check green"></i>}
                                            </div>
                                            <div className="interactions__item-name">{item.text}</div>
                                           
                                        </div>
                                        ))
                                    }
                                </div>
                            </div>
                            :null}
                            Mark as Complete
                            </CustomButton>
                        </div>
                        :
                        <div className="session-item__button">
                            <CheckmarkCheck active="true" />
                        </div>
                    }

                    {next ?
                        <div className="session-item__button">
                            <CustomButton disabled={!item.completed} onClick={() => { history.push("/session/" + next) }}>Next</CustomButton>
                        </div> :
                        <div className="session-item__button">
                            {item.next_section ? <CustomButton onClick={() => { document.querySelector(".app-main").scrollTo({ top: 0 }); history.push("/section/" + item.next_section) }}>Next Section</CustomButton> : item.completed ? <CustomButton onClick={() => { history.push("/dashboard") }}>To Dashboard</CustomButton> : null}
                        </div>}
                </div>
            </div>

            <ModalPopup show={noteModalShow} exit={noteModalExit} className='session-item__note-modal' handleOverlayClick={() => showHideNoteModal(false)}>
                <div className="session-item__note-modal-title">My Notes</div>
                <div className="session-item__note-modal-body">
                    <div className="session-item__note-modal-textarea-container">
                        <textarea className="session-item__note-modal-textarea" ref={noteTextRef} value={noteText} onChange={noteTextChange} />
                    </div>
                </div>
                <div className="session-item__note-modal-buttons-container">
                    <CustomButton onClick={() => { saveNote(noteText); showHideNoteModal(false); }}>Save</CustomButton>
                </div>
            </ModalPopup>
        </div>
    ) : null
}

export default SessionItem;