import React from 'react';

import './form-label.styles.scss';



const FormLabel =({handleChange,handleClick,label,children, ...otherProps})=>(
    <div className='group form-label'> 
     <label onClick={handleClick} className={`${otherProps.value ? "shrink": ""} form-label-label`}>
     {children}
     {label}
     </label>
    </div>
);

export default FormLabel;