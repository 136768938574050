import React from 'react';
import moment from 'moment'

import './team-feed-item.styles.scss'


const TeamFeedItem = ({ item, handleClick, ...otherProps }) => (
    <div onClick={() => handleClick ? handleClick(item) : null} className={`team-feed-item`} {...otherProps}>
        <div className='team-feed-item__name-container'>
            <div className='team-feed-item__avatar'>
                <img alt={item.display} src={process.env.REACT_APP_URL + "" + item.avatar}></img>
            </div>
            <div className='team-feed-item__name'>
                {/* {item.first_name} {item.last_name} */}
            </div>

        </div>
        <div className='team-feed-item__text-container'>
            <div className='team-feed-item__text'>
                    {item.action==='bookmarked'?'Bookmarked':item.action==="completed"?'Completed':item.action==="commented"?"Chatted in":""} {item.name}
            </div>
            <div className='team-feed-item__bottom'>
                <div className='team-feed-item__date'>
                        {item.section_name}
                </div>
                <div className='team-feed-item__date'>
                       
                </div>
            </div>
        </div>

    </div>
)

export default TeamFeedItem;