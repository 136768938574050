import React from 'react';

import './quiz-answers-group.styles.scss';

import QuizAnswer from '../quiz-answer/quiz-answer.component'

const QuizAnswersGroup = ({items,selected,handleItemClick})=>{
    const standard = !items.some(item=>item.correct)
    return (
        <div className="quiz-answers-group">
        {items.map(item => (
          <QuizAnswer
            key={item.id}
            {...item}
            standard = {standard}
            selected={ selected.includes(item.id)}
            handleClick={handleItemClick}
          />
        ))}
      </div>
    );
}

export default QuizAnswersGroup;