import React from 'react';
export const NEWLINE_RE = /\r?\n/g;
export const LINE_BREAK = '<br />';

export const SPACE_RE = /^\s*$/;
export const TOKEN_RE = /(\[\/?.+?\])/;
export const START_NEWLINE_RE = /^\r?\n/;

export const ESCAPE_RE = /[&<>"]/g;
export const ESCAPE_DICT = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
};

export const URL_RE = /\b((?:([\w-]+):(\/{1,3})|www[.])(?:(?:(?:[^\s&()]|&amp;|&quot;)*(?:[^!"#$%&'()*+,.:;<=>?@\[\]^`{|}~\s]))|(?:\((?:[^\s&()]|&amp;|&quot;)*\)))+)/g;

export const COSMETIC_DICT = {
  '--': '&ndash;',
  '---': '&mdash;',
  '...': '&#8230;',
  '(c)': '&copy;',
  '(reg)': '&reg;',
  '(tm)': '&trade;',
};

export const COSMETIC_RE = /--|---|\.\.\.|\(c\)|\(reg\)|\(tm\)/;

class Tag {

  constructor(settings = {}) {
    this.CLOSED_BY = [];
    this.SELF_CLOSE = settings.mono || false;
    // this.SELF_CLOSE = false;
    this.STRIP_INNER = false;
    this.STRIP_OUTER = false;
    this.DISCARD_TEXT = false;

    this.name = settings.name || null;
    this.tag = settings.tag || null;
    this.parent = settings.parent || null;
    this.text = settings.text || '';
    this.params = {};
    this.children = [];

    if (this.parent) {
      this.parent.children.push(this);
    }

    settings.params = settings.params || [];

    settings.params.forEach(item => {
      if (item.length > 1 && item[1]) {
        this.params[item[0]] = item[1];
      }
    });
  }

  getComponents() {
    const components = [];
    if (this.text && this.text.length) {
      // todo linkify and emotion
      components.push(this.text);
    }
    
    this.children.forEach(child => {
      if (!(this.DISCARD_TEXT && child.name === null)) {
        const childComponents = child.toReact();
        components.push(childComponents);
      }
    });

    return React.Children.toArray(components);
  }

  getContent(raw = false) {
    const pieces = [];
    let text;
    let content;

    if (this.text && this.text.length) {
      text = this.renderer.escape(this.text);

      if (!raw) {
        if (this.renderer.options.linkify) {
          text = this.renderer.linkify(text);
        }
        text = this.renderer.cosmeticReplace(text.replace(NEWLINE_RE, LINE_BREAK));
      }

      pieces.push(text);
    }

    this.children.forEach(child => {
      if (raw) {
        pieces.push(child.toText());
      } else {
        if (!(this.DISCARD_TEXT && child.name === null)) {
          const childPieces = child.toHTML();
          if (typeof childPieces === 'string') {
            pieces.push(childPieces);
          } else {
            pieces.push(...childPieces);
          }
        }
      }
    });

    content = pieces.join('');

    if (!raw && this.STRIP_INNER) {
      content = this.renderer.strip(content);

      while (content.slice(0, LINE_BREAK.length) === LINE_BREAK) {
        content = content.slice(LINE_BREAK.length);
      }
      while (content.slice(-LINE_BREAK.length) === LINE_BREAK) {
        content = content.slice(0, -LINE_BREAK.length);
      }
      content = this.renderer.strip(content);
    }
    return content;
  }

  toText(contentAsHTML = false) {
    const pieces = [];

    if (this.name !== null) {
      if (this.params.length) {
        const params = Object.keys(this.params).map(k => `${k}=${this.params[k]}`).join(' ');

        if (this.params[this.name]) {
          pieces.push(`[${params}]`);
        } else {
          pieces.push(`[${this.name} ${params}]`);
        }
      } else {
        pieces.push(`[${this.name}]`);
      }
    }

    pieces.push(this.getContent(!contentAsHTML));

    if (this.name !== null && this.CLOSED_BY.indexOf(this.name) === -1) {
      pieces.push(`[/${this.name}]`);
    }

    return pieces.join('');
  }

  toHTML() {
    const pieces = this.toText(true);
    return (typeof pieces === 'string') ? pieces : pieces.join('');
  }

  toReact() {
      // console.log('Pirrraaas',this.tag,this.parent,this.root);
    if (this.tag&&typeof this.tag === 'function'){
        return this.tag(this.params,this.getComponents())
    }else{
      if (this.parent){
        if (!(React.Children.toArray(this.getComponents()).length===1&&React.Children.toArray(this.getComponents())[0].replace(/^\s+|\s+$/g, '') === ''))
          return <div className='bb-code-text-block'>{React.Children.toArray(this.getComponents())}</div>;
      }
      else
        return React.Children.toArray(this.getComponents())
    }
    
  }

}




const parseParams = (token)=>{
    const params = [];

    function addParam(name, value) {
      if (name) {
        const n = name.trim();
        // ignore on* events attribute
        if (n.length && n.toLowerCase().indexOf('on') !== 0) {
          params.push([n, value]);
        }
      }
    }

    if (token) {
      let key = [];
      let target = key;
      let value = [];
      let terminate = ' ';
      let skipNext = false;

      Array.from(token).forEach(c => {
        if (skipNext) {
          skipNext = false;
        } else if (target === key && c === '=') {
          target = value;
        } else if (target === key && c === ':') {
          target = value;
        } else if (!value.length && c === '"') {
          terminate = c;
        } else if (c !== terminate) {
          target.push(c);
        } else {
          addParam(key.join(''), value.join(''));

          if (!SPACE_RE.test(terminate)) {
            skipNext = true;
          }

          target = key = [];
          value = [];
          terminate = ' ';
        }
      });

      addParam(key.join(''), value.join(''));
    }

    return params;
  }

  const createTextNode = (parent, text) => {
    const ref = parent.children.slice(-1)[0];
    //console.log('ref', ref, text)
    if (ref != null && ref.STRIP_OUTER) {
      text = text.replace(START_NEWLINE_RE, '');
    }

    return new Tag({ text, parent });
  }

  export const parse = (input, tags) =>{
    const root = new Tag();
    const tokens = input.split(TOKEN_RE);
    let current = root;
    let token = null;
    while (tokens.length) {
      token = tokens.shift();
      if (!token.length) {
        continue;
      }

      if (token.match(TOKEN_RE)) {
        let params = parseParams(token.slice(1, -1));
        // console.log('PARSE params',params)
        let tagName = params[0][0].toLowerCase();

        if (current.CLOSED_BY.indexOf(tagName) > -1) {
          tokens.unshift(token);
          tagName = `/${current.name}`;
          params = [];
        }

        if (tagName[0] === '/') {
          tagName = tagName.slice(1);
          if (!tags[tagName]) {
            createTextNode(current, token);
            continue;
          }

          if (current.name === tagName) {
            current = current.parent;
          }
        } else {
            // console.log('ARRRRRR',tagName,tags,tags[tagName])
          const cls = tags[tagName];
          if (!cls) {
            createTextNode(current, token);
            continue;
          }
          let tag = null;
          if (Object.keys(cls).length){
            tag = new Tag({ 
                name: tagName,
                parent: current,
                tag:cls.tag,
                mono:cls.mono,
                params });
          }else{
            tag = new Tag({ 
                name: tagName,
                parent: current,
                tag:cls,
                params });
          }
          

          if (!tag.SELF_CLOSE && (tag.CLOSED_BY.indexOf(tagName) < 0 || current.name !== tagName)) {
            current = tag;
          }
        }
      } else {
        createTextNode(current, token);
      }
    }
    // console.log('!!!!!PARSE!!!!!!',root)
    return root.toReact();
  }