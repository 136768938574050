import {
  METHOD_POST,
  METHOD_DELETE,
  METHOD_PUT,
  METHOD_GET,
  METHOD_OPTIONS
} from "./methods";

export const endpoints = {
  signup: {
    url: "/signup",
    method: METHOD_POST,
    jwt: false
  },
  login: {
    url: "/signin",
    method: METHOD_POST,
    jwt: false
  },
  resetPasswordRequest: {
    url: "/reset_password/request",
    method: METHOD_POST,
    jwt: false
  },
  resetPasswordConfirm: {
    url: "/reset_password/confirm",
    method: METHOD_POST,
    jwt: false
  },
  resetPasswordValidate: {
    url: "/reset_password/validate",
    method: METHOD_POST,
    jwt: false
  },
  getUser: {
    url: "/me",
    method: METHOD_GET,
    jwt: true
  },
  saveUser: {
    url: "/me",
    method: METHOD_PUT,
    jwt: true
  },
  uploadAvatar: {
    url: "/avatar",
    method: METHOD_POST,
    jwt: true
  },
  discardAvatar: {
    url: "/avatar_discard",
    method: METHOD_GET,
    jwt: true
  },
  getCountries: {
    url: "/countries",
    method: METHOD_GET,
    jwt: false
  },
  getConfig: {
    url: "/config",
    method: METHOD_GET,
    jwt: true
  },
  getHomepageConfig: {
    url: "/home_page_config",
    method: METHOD_GET,
    jwt: false
  },
  checkCoupon: {
    url: "/check_coupon",
    method: METHOD_POST,
    jwt: true
  },
  sendSessionMessage: {
    url: "/send_session_message",
    method: METHOD_POST,
    jwt: true
  },
  sendForumMessage: {
    url: "/send_forum_message",
    method: METHOD_POST,
    jwt: true
  },
  getSessionChat: {
    url: "/get_session_chat",
    method: METHOD_POST,
    jwt: true
  },
  saveCompletedItem: {
    url: "/save_completed_item",
    method: METHOD_POST,
    jwt: true
  },
  saveQuestionAnswer: {
    url: "/save_question_answer",
    method: METHOD_POST,
    jwt: true
  },
  getAssessmentResult: {
    url: "/get_assessment_result",
    method: METHOD_POST,
    jwt: true
  },
  saveUserChoiceQuestionAnswer: {
    url: "/save_user_choice_question_answer",
    method: METHOD_POST,
    jwt: true
  },
  saveUserNote: {
    url: "/save_user_note",
    method: METHOD_POST,
    jwt: true
  },
  saveSessionItemProgress: {
    url: "/save_session_item_progress",
    method: METHOD_POST,
    jwt: true
  },
  getNotifications: {
    url: "/notifications",
    method: METHOD_POST,
    jwt: true
  },
  setNotificationRead: {
    url: "/read_notification",
    method: METHOD_PUT,
    jwt: true
  },
  saveUserNoteFavorite: {
    url: "/favorite_user_note",
    method: METHOD_POST,
    jwt: true
  },
  toggleSessionBookmark: {
    url: "/toggle_session_bookmark",
    method: METHOD_POST,
    jwt: true
  },
  saveCurrentAccessCode: {
    url: "/save_current_access_code",
    method: METHOD_PUT,
    jwt: true
  },
  saveRegistrationId: {
    url: "/save_registration_id",
    method: METHOD_POST,
    jwt: true
  },
  addAccessCode: {
    url: "/add_access_code",
    method: METHOD_POST,
    jwt: true
  },
  deleteAccessCode: {
    url: "/delete_access_code",
    method: METHOD_POST,
    jwt: true
  },
  deleteMyAccount: {
    url: "/delete_my_account",
    method: METHOD_POST,
    jwt: true
  },
  getUserId: user_id => ({
    url: `/me/${user_id}`,
    method: METHOD_GET,
    jwt: true
  })

};
