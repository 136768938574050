import React from "react";


import "./modal-popup.styles.scss";

const ModalPopup = ({ show,className,exit, children, handleOverlayClick }) => {

  if (!show) return null;
  return (
    <div className={`modal-popup ${className?className:''} ${exit?'exiting':''}`}>
      <div
        className="modal-popup__overlay"
        onClick={handleOverlayClick}
      ></div>
      <div className="modal-popup__container">
        <div className="modal-popup__body">
          <div className="modal-popup__close" onClick={handleOverlayClick}></div>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalPopup;
