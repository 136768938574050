import React, { useState, useEffect, useRef } from 'react';


import './collab-canvas-bg-shader.styles.scss'

class GLSLBackground{
    loadShaderSource(gl, source, shaderType) {
        //let source = document.getElementById(id).textContent;
    
        var shader = gl.createShader(shaderType);
        gl.shaderSource(shader, source);
        gl.compileShader(shader);
    
        if ( !gl.getShaderParameter( shader, gl.COMPILE_STATUS) ) {
            var info = gl.getShaderInfoLog(shader);
            console.error('Could not compile WebGL shader.\n\n', info);
        }
    
        return shader;
    }

    onWindowResize( event ) {
        
        this.canvas.width = this.canvas.parentNode.clientWidth-1;
        this.canvas.height = this.canvas.parentNode.clientHeight-1;
    
        this.gl.viewport( 0, 0, this.canvas.width, this.canvas.height );
    }

    loop(){
        var gl = this.gl;

        var iResolution = gl.getUniformLocation(this.shaderProgram, "iResolution");
        gl.uniform2f(iResolution, this.canvas.width, this.canvas.height);
        
        // wire up the shader program to the vertex texcoord data
        var timeLocation = gl.getUniformLocation(this.shaderProgram, "iTime"); 
        gl.uniform1f(timeLocation, (Date.now() - this.startTime)/1000.0);
    
        // tell the GPU to draw
        gl.drawArrays(gl.TRIANGLES, 0, 6);
    }

    GetWebGLContext( canvas ){
        // Standard
        return canvas.getContext("webgl") ||               
               // Alternative; Safari, others
               canvas.getContext("experimental-webgl") ||   
               // Firefox; mozilla
               canvas.getContext("moz-webgl") ||            
               // Last resort; Safari, and maybe others
               canvas.getContext("webkit-3d");              
    }
        
    initGL(parentElement){
        if ((!!window.WebGLRenderingContext) === false){
            console.warn('no WebGLRenderingContext');
            return;
        }

        this.canvas = document.createElement("canvas");
        this.canvas.width  = parentElement.clientWidth-1;
        this.canvas.height = parentElement.clientHeight-1;

        let gl = this.GetWebGLContext(this.canvas);
        if (!gl){
            console.warn('no WebGL canvas Context');
            return;
        }

        this.gl = gl;

        // build the vertex shader
        var vertexShader = this.loadShaderSource(gl, `
            attribute vec4 position;
            attribute vec2 texcoord;
            varying highp vec2 uv;
            
            void main() {
                gl_Position = position;
                uv = texcoord;
            }
        `, gl.VERTEX_SHADER);
        
        // build the fragment shader
        var fragmentShader = this.loadShaderSource(gl, `
            #ifdef GL_ES
            precision mediump float;
            #endif

            uniform float iTime;
            uniform vec2 iResolution;
            uniform sampler2D lastTexture;
            varying highp vec2 uv;

            #define rot(x) mat2(cos(x), sin(x), -sin(x), cos(x))
            #define PI 3.1415
            
            float spiral(vec2 uv, float i, float thr) { 
                uv += .03 * sin( vec2(10, 30) * uv.yx ) * vec2(sin(iTime*.3 + PI/8.), sin(iTime*.1 + PI/3.));
            
                // make a tube
                float f = .25 / length(uv);

                // add the angle
                f += atan(uv.x, uv.y) / acos(0.);

                f = smoothstep(thr, 1., sin((f) * PI * 2.));

                // add the darkness to the end of the tunnel
                f *= sin(length(uv) - .15);

                return smoothstep(0., 1., f);
            }
            
            void main() {
                float col = 0.;
                
                for(float i=.1; i<=1.; i+=.2) {
                    float z = fract(i - 0.03*iTime*.75);
                    float fade = smoothstep(1., .8, z)*0.5;
                    vec2 UV = uv*2.0-1.0;
                    col += 0.1 * spiral(UV*z, i, .8+z*0.2)/z*fade;
                    col += 0.1 * spiral(UV*z, i, .9+z*0.1)/z*fade;
                    col += 0.1 * spiral(UV*z, i, .98+z*0.02)/z*fade;
                }
                
                col = sqrt(col);
            
                vec4 bgcolor = mix(vec4(52.,132.,223.,1.), vec4(57.,183.,240.,1.), uv.x)/255.;

                gl_FragColor = vec4(bgcolor.rgb, 1.0)+ vec4(vec3(col*0.2), 1.);
            }
        `, gl.FRAGMENT_SHADER);
        
        // build a shader program from the vertex and fragment shader
        this.shaderProgram = gl.createProgram();
        gl.attachShader(this.shaderProgram, vertexShader);
        gl.attachShader(this.shaderProgram, fragmentShader);
        gl.linkProgram(this.shaderProgram);

        if ( !gl.getProgramParameter( this.shaderProgram, gl.LINK_STATUS) ) {
            var info = gl.getProgramInfoLog(this.shaderProgram);
            console.error('Could not link WebGL program.\n\n', info);
            return;
        }

        gl.useProgram(this.shaderProgram);

        parentElement.appendChild(this.canvas);

        // define vertex positions
        var vertexPositions = new Float32Array([
            1.0,  1.0, 0,  // a
            -1.0,  1.0, 0,  // b    b----a
            1.0, -1.0, 0,  // c    |    |
            -1.0,  1.0, 0,  // b    |    |
            -1.0, -1.0, 0,  // d    d----c
            1.0, -1.0, 0   // c
        ]);
        
        // send the vertex positions to the GPU
        var vertexBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, vertexPositions, gl.STATIC_DRAW);
            
        // define vertex texcoords
        var vertexTexcoords = new Float32Array([
            1.0, 0.0,   // a
            0.0, 0.0,   // b
            1.0, 1.0,   // c
            0.0, 0.0,   // b
            0.0, 1.0,   // d
            1.0, 1.0    // c
        ]);
        
        setInterval( this.loop.bind(this), 1000 / 30 );

        // send the vertex texcoords to the GPU
        var texcoordBuffer = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, vertexTexcoords, gl.STATIC_DRAW);
        
        // wire up the shader program to the vertex position data
        var positionAttribute = gl.getAttribLocation(this.shaderProgram, "position");
        gl.bindBuffer(gl.ARRAY_BUFFER, vertexBuffer);
        gl.enableVertexAttribArray(positionAttribute);
        gl.vertexAttribPointer(positionAttribute, 3, gl.FLOAT, false, 0, 0);
        
        // wire up the shader program to the vertex texcoord data
        var texcoordAttribute = gl.getAttribLocation(this.shaderProgram, "texcoord");
        gl.bindBuffer(gl.ARRAY_BUFFER, texcoordBuffer);
        gl.enableVertexAttribArray(texcoordAttribute);
        gl.vertexAttribPointer(texcoordAttribute, 2, gl.FLOAT, false, 0, 0);

        //
        
        
        this.startTime = Date.now();

        window.addEventListener('resize', this.onWindowResize.bind(this));
        setInterval( this.onWindowResize.bind(this), 1000 / 30 );
    }

    constructor(parentElement){
        this.initGL(parentElement);
    }
}



const CollabCanvasBgShader = () => {
    const canvasRef = useRef(null);
    useEffect(() => {
        new GLSLBackground(canvasRef.current)
        // function handleResize() {
        //     resizeCanvas();
        // }

        // window.addEventListener('resize', handleResize);
        // return () => window.removeEventListener('resize', handleResize);
    }, []);
    return (
        <div className='collab-canvas-bg-shader' id='canvas' ref={canvasRef} width={window.innerWidth}
            height={window.innerHeight}
            >

        </div>
    )
}

export default CollabCanvasBgShader;