import React from 'react';
import {connect} from 'react-redux';
import {createStructuredSelector} from 'reselect';
import {selectNotifications} from '../../redux/notifications/notifications.selectors';


import NotificationsItem from './notifications-item.component';
import './notifications.styles.scss';



const Notifications = ({notifications}) => (
    <div className='notifications'>
        {
            notifications.map((item)=>(
                <NotificationsItem key={item.id} {...item} />
            ))
        }

        
    </div>
);

const mapStateToProps = createStructuredSelector({
  notifications:selectNotifications
});


export default connect(mapStateToProps)(Notifications);