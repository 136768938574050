export const calculateDiffPaymentAmount = (amount, newAmount, paymentType,paidUntil) => {
  let daysForDivide = 30;
  if (paymentType === "annual") daysForDivide = 365;
  const diffAmount = newAmount - amount;
  const amountPerDay = diffAmount / daysForDivide;

  const date1 = new Date();
  const date2 = new Date(paidUntil);
// To calculate the no. of days between two dates 
  let DifferenceInDays = Math.ceil((date2.getTime() - date1.getTime()) / (1000 * 3600 * 24));
  
  if (DifferenceInDays<=0)
    DifferenceInDays = 1;

  let finishAmount = parseFloat((amountPerDay*DifferenceInDays).toFixed(2), 10);

  if (finishAmount<0)
    finishAmount=0;
  return finishAmount;
};

export const calculatePaymentAmount = (
  features,
  selectedFeatures,
  paymentType,
  userCount,
  discount,
  couponDiscount,
  tax
) => {
  userCount = userCount === 0 ? 1 : userCount;

  const selectedIds = Object.keys(selectedFeatures).map(item =>
    selectedFeatures[item] === 1 ? parseInt(item, 10) : null
  );
  const selectedFeaturesArr = features.filter(feature =>
    selectedIds.includes(feature.id)
  );
  const amountsByUsers = selectedFeaturesArr.map(feature =>
    calculatePaymentAmountForEveryRange(feature.ranges, userCount)
  );

  const sumAnnual = amountsByUsers.reduce((acc, item) => acc + item, 0);
  const sumMonthly = (sumAnnual + (sumAnnual * discount) / 100) / 12;

  let sumAnnualFinal = sumAnnual * userCount;
  let sumMonthlyFinal = sumMonthly * userCount;
  if (couponDiscount) {
    sumAnnualFinal = sumAnnualFinal - sumAnnualFinal * (couponDiscount / 100);
    sumMonthlyFinal =
      sumMonthlyFinal - sumMonthlyFinal * (couponDiscount / 100);
  }

  if (tax){
    sumAnnualFinal = sumAnnualFinal + sumAnnualFinal * (tax / 100);
    sumMonthlyFinal =
      sumMonthlyFinal + sumMonthlyFinal * (tax / 100);
  }

  sumAnnualFinal = parseFloat(sumAnnualFinal.toFixed(2), 10);
  sumMonthlyFinal = parseFloat(sumMonthlyFinal.toFixed(2), 10);
  if (paymentType === "monthly") return sumMonthlyFinal;

  return sumAnnualFinal;
};

export const calculatePaymentAmountForEveryRange = (ranges, userCount) => {
  let minimalAmount = 0;
  let foundAmount = false;

  for (let range of ranges) {
    if (userCount >= range.range_start) {
      minimalAmount = range.price;
      if (userCount <= range.range_stop) foundAmount = range.price;
    }
  }
  return foundAmount === false
    ? parseFloat(minimalAmount, 10)
    : parseFloat(foundAmount, 10);
};

export const preapreTableOfAccessCodesList = access_codes => {
  const formattedTable = access_codes.map(item => {
    const fieldsMapping = [];
    fieldsMapping.push([
      "route",
      `/access_code_dashboard/${item.id}`,
      item.name
    ]);
    fieldsMapping.push(item.current_users);
    fieldsMapping.push(item.max_users);
    fieldsMapping.push(item.date_created.split(" ")[0]);
    fieldsMapping.push(item.paid_until.split(" ")[0]);
    fieldsMapping.push(item.renew ? "Yes" : "No");
    fieldsMapping.push(item.paid ? "Yes" : "No");
    fieldsMapping.push([["link", item.invite_link, "Link"],["call",'getPDF', "PDF", item.id,item.name+"_invite.pdf"]]);

    return fieldsMapping;
  });
  return formattedTable;
};

export const accTableHeaders = [
  "Access Code Name",
  "Active Users",
  "Paid Users",
  "Start Date",
  "Expiry",
  "Renews",
  "Paid",
  "Invitations"
];


export const getAccessCodeById = (access_codes, id) => {
  const findAccessCode = access_codes.find(
    item => item.id === parseInt(id, 10)
  );
  return findAccessCode;
};
