import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Confetti from 'react-dom-confetti';
import { connect } from 'react-redux';
import { createStructuredSelector } from "reselect";
import {
  selectUserIsLogged,
  selectUserData,
  selectUserShowHelpModal,
  selectHelpVideo,
  selectCompleteModal,
  selectCurrentAccessCodeConfig,
  selectUserCurrentAccessCode,
  selectConfig,
} from "../../redux/user/user.selectors";

import { userSignOutStart,getConfigStart,showCompleteModal, showHelpModal, saveCurrentAccessCodeStart, addAccessCodeStart } from '../../redux/user/user.actions';

import Logo from "../../assets/logo.png";
import LogoSm from "../../assets/logo_sm.png";
import SvgLogo from "../../assets/app_svg_logo.svg";
import CustomButton from "../forms/custom-button/custom-button.component";
import ModalPopup from "../forms/modal-popup/modal-popup.component";
import SimpleVideoPlayer from "../simple-video-player/simple-video-player.component";
import FormSelect from '../../components/forms/form-select/form-select.component'
import FormInput from '../../components/forms/form-input/form-input.component'
import Navigation from '../navigation/navigation.component'
import { Link } from "react-router-dom";

import "./header.styles.scss";

const Header = ({ config,currentConfig, accessCodeId,completeModal,getConfig,completeModalShow, helpVideo, showHelpModalFunc, showHelpModalVar, isLogged, userData, userSignOut, addAccessCode, selectCurrentAccessCode }) => {
  const history = useHistory();
  const navItems = [

    { 'name': 'My Course', 'onClick': () => history.push('/dashboard') },
    { 'name': 'Bookmarks', 'onClick': () => history.push('/bookmarks') },
    { 'name': 'My Notes', 'onClick': () => history.push('/notes') },
    { 'name': 'Team Progress', 'onClick': () => history.push('/team') }, 
    currentConfig?.forum_access?{ 'name': 'Practice Forum', 'onClick': () => history.push('/forum') }:false, 
    { 'name': 'Resources', 'onClick': () => history.push('/collaborativeway') },
    { 'name': 'My Account', 'onClick': () => history.push('/account') },
    { 'name': 'Logout', 'onClick': () => { userSignOut(); history.push('/') } },
  ]

  const localStateInit = {
    newAccessModalShow: false,
    accessCode: "",
  };

  const [localState, setLocalState] = useState(localStateInit);

  const {
    newAccessModalShow,
    accessCode
  } = localState;


  const handleSelectChange = (event) => {
    const { name, value } = event.target;
    if (value === "add") {
      setLocalState({ ...localState, newAccessModalShow: true });
    } else
      selectCurrentAccessCode(value)
  }

  const handleAccessCodeChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ ...localState, accessCode: value });
  }

  const handleNewAccessOverlayClick = () => {
    setLocalState({ ...localState, newAccessModalShow: false });
  }
  const handleNewAccessCodeClick = () => {
    addAccessCode(accessCode)
    setLocalState({ ...localState, newAccessModalShow: false });
  }

  const openCompleteUrl = (url) => {
    completeModalShow(false);
    if (url.includes("http")){
      window.open(url, "_blank")
      getConfig()
      history.push('/dashboard')
    }
    else{
      getConfig()
      history.push(url)
    }
  }

  const configConfetti = {
    angle: "79",
    spread: 360,
    startVelocity: 40,
    elementCount: "151",
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "15px",
    height: "15px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  return (
    <div className={`header`}>
     <div className="header__confetti">
      <Confetti active={ completeModal?.show } config={configConfetti}/>
     </div>
      <div className="header__small-screen-flex">
        <div className="logo-container">
        {currentConfig?
          <Link to="/">
            <img className="header__logo" src={currentConfig.logo?process.env.REACT_APP_URL+'/'+currentConfig.logo:SvgLogo} alt="collaboration-app-homepage" />
            <img className="header__logo--sm" src={currentConfig.logo?process.env.REACT_APP_URL+'/'+currentConfig.logo:SvgLogo} alt="collaboration-app-homepage" />
          </Link>
          :null
        }
        </div>
        <div className="options">
          <div className="option">
            <div className='option-multiple-container'>
              <div className='option-multiple-container__data'>
              </div>
              {isLogged && config.length ? (
                <div className="option-multiple-container__access_code_selector">
                  <FormSelect items={config} addNew={false} value={accessCodeId} handleChange={handleSelectChange} />
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <ModalPopup
        className="blacklisted-modal"
        show={showHelpModalVar}
        handleOverlayClick={() => showHelpModalFunc(false)}
      >
        <div className="help-modal">
          <div className="blacklisted-modal__title">User Guide</div>
          <div
            className="blacklisted-modal__text"
          >
            <SimpleVideoPlayer src={helpVideo?helpVideo:currentConfig?currentConfig.course?.video_url:''} />
          </div>
          <div className="blacklisted-modal__controls">
            <CustomButton
              onClick={() =>
                showHelpModalFunc(false)
              }
              inverted
              type="submit"
            >
              Close
        </CustomButton>
          </div>
        </div>
      </ModalPopup>

      <ModalPopup
        className="blacklisted-modal access-code"
        show={newAccessModalShow}
        handleOverlayClick={handleNewAccessOverlayClick}
      >
        <div className="help-modal">
          <div className="blacklisted-modal__title">New Access Code</div>
          <div
            className="blacklisted-modal__access_code_input"
          >
            <FormInput value={accessCode} handleChange={handleAccessCodeChange} />
          </div>
          <div className="blacklisted-modal__controls">
            <CustomButton
              onClick={handleNewAccessCodeClick}
              type="submit"
            >
              Add
          </CustomButton>
            <CustomButton
              onClick={handleNewAccessOverlayClick}
              type="submit"
            >
              Close
        </CustomButton>
          </div>
        </div>
      </ModalPopup>
      <ModalPopup
        className="blacklisted-modal complete-item"
        show={completeModal?.show}
        handleOverlayClick={() => {}}
      >
        <div className="help-modal">
          <div className="blacklisted-modal__title">{completeModal?.title}</div>
          <div
            className="blacklisted-modal__text"
          >
            <SimpleVideoPlayer autoplay={true} src={completeModal?.video} />
          </div>
          <div className="blacklisted-modal__controls">
            <CustomButton
              onClick={() =>
                openCompleteUrl(completeModal?.button_url)
              }
              inverted
              type="submit"
            >
              {completeModal?.button_text}
        </CustomButton>
          </div>
        </div>
      </ModalPopup>
      <Navigation items={navItems} />
    </div>
  )
};

const mapStateToProps = createStructuredSelector({
  isLogged: selectUserIsLogged,
  userData: selectUserData,
  showHelpModalVar: selectUserShowHelpModal,
  helpVideo: selectHelpVideo,
  completeModal: selectCompleteModal,
  currentConfig: selectCurrentAccessCodeConfig,
  config: selectConfig,
  accessCodeId: selectUserCurrentAccessCode
});

const mapDispatchToProps = dispatch => ({
  userSignOut: () => dispatch(userSignOutStart()),
  getConfig: () => dispatch(getConfigStart()),
  showHelpModalFunc: (show) => dispatch(showHelpModal(show)),
  completeModalShow: (show) => dispatch(showCompleteModal(show)),
  selectCurrentAccessCode: (id) => dispatch(saveCurrentAccessCodeStart(id)),
  addAccessCode: (code) => dispatch(addAccessCodeStart(code)),
})


export default connect(mapStateToProps, mapDispatchToProps)(Header);
