import React from 'react';

import PointerPng from "../../../../assets/pointer.png";
import './assessment-input-range.styles.scss'
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css'


const AssessmentInputRange = ({ name, percent, handleChange, showHelper, ...otherProps }) => (
    <div className='assessment-input-range' {...otherProps}>
        <div className="assessment-input-range__chevron-container">
            {showHelper ?
                <div className='assessment-input-range__helper'>
                    <img src={PointerPng} alt="pointer_helper" />
                </div> : null
            }

            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z" fill="currentColor" /></svg>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.34317 7.75732L4.92896 9.17154L12 16.2426L19.0711 9.17157L17.6569 7.75735L12 13.4142L6.34317 7.75732Z" fill="currentColor" /></svg>
        </div>
        <InputRange
            draggableTrack={false}
            minValue={10}
            maxValue={100}
            step={5}
            formatLabel={value => ``}
            value={percent!==-1?Math.ceil(percent / 10) * 10:55}
            onChange={(percent) =>
                handleChange(otherProps.id, percent)
            }
        />
        <div className='assessment-input-range__label-container'>
            {Array(10).fill(1).map((x, y) => x + y).map(index => (
                <div key={index} onClick={() => handleChange(otherProps.id, index * 10)} className='assessment-input-range__label'>
                    {index}
                </div>
            ))}

        </div>
    </div>
)

export default AssessmentInputRange;