import {createSelector} from 'reselect';
import {findNotificationById} from './notifications.utils'
const selectNotificationsState = state => state.notifications;

export const selectNotifications = createSelector(
    [selectNotificationsState],
    notifications => notifications.notifications?notifications.notifications:[]
    );

export const selectNotification = (id) => createSelector(
    [selectNotifications],
    notifications => findNotificationById(notifications,id)
    );
