import React from 'react';

import './select-bubbles-sortable-group.styles.scss';

import {SortableContainer} from 'react-sortable-hoc';
import SelectBubbleSortableItem from '../select-bubble-sortable-item/select-bubble-sortable-item.component'

const SelectBubblesSortableGroup = SortableContainer(({items})=>{
    return (
        <div className="select-bubbles-sortable-group">
        {items.map((item,index) => (
          <SelectBubbleSortableItem
            key={item.id}
            index={index}
            {...item}
          />
        ))}
      </div>
    )
});

export default SelectBubblesSortableGroup;